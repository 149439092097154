import React, { useState, useEffect, useRef } from 'react';
import LandingPage from '../LandingPage';
import { Box, Grid } from '@mui/material';
import FooterContent from '../Footer/FooterContent';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { CardContent, Typography } from '@mui/material';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import TradingChart from './TradingChrat';
import SelectButton from '../Buttons/SelectButton';
import Overview from './Overview';
import Events from './Events';
import Annual from './Annual';
import Daily from './Daily';
import Weekly from './Weekly';
import Monthly from './Monthly';
import StockViewer from '../Test';
import Currency from '../Currency/Currency';
import Commodity from '../Commodity/Commodity';
import { useSelector } from 'react-redux';
import { useCompanyNewsMutation } from '../redux/companyNews';
import { useNavigate } from 'react-router';
import TechnicalAnalysis from '../TechnicalAnalysis';
import {
  useBalanceSheetMutation,
  useCashFlowMutation,
  useProfitLossMutation,
  useRatiosMutation,
  useShareHolderMutation,
} from '../redux/p&l';

const CompanyPages = () => {
  const [col, setCol] = useState(false);
  const [second, setSecond] = useState(false);
  const [third, setThird] = useState(false);
  const [alignment, setAlignment] = React.useState('Stocks');
  const [componet, setComponet] = useState('Overview');
  const [timeRange, setTimeRange] = useState('Daily');
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const companyName = useSelector((state) => state.token.companyName);
  const [companyNews, { data, isLoading }] = useCompanyNewsMutation();
  const [profitloss] = useProfitLossMutation();
  const [err, setErr] = useState('');
  const [news, setNews] = useState([]);
  const [profitLossData, setProfitLossData] = useState([]);
  const [BalanceSheet] = useBalanceSheetMutation();
  const [balanceSheet, setBalanceSheet] = useState([]);
  const [CashFlow] = useCashFlowMutation();
  const [cashFlow, setCashFlow] = useState([]);
  const [Ratios] = useRatiosMutation();
  const [ratio, setRatio] = useState([]);
  const [ShareHolding] = useShareHolderMutation();
  const [shareholding, setShareholding] = useState([]);
  useEffect(() => {
    const fetchNewsAndProfitLoss = async () => {
      try {
        if (companyName) {
          // Fetch company news
          const newsResponse = await companyNews({
            exchange: 'NSE',
            segment: 'CM',
            tickerCode: companyName,
            fundamentalReport: 'news',
          });

          if (newsResponse.error) {
            setErr(newsResponse.error);
          } else {
            console.log('News Response:', newsResponse);
            setNews(newsResponse.data.data || []);
          }

          // Fetch profit and loss data
          const profitLossResponse = await profitloss({
            exchange: 'NSE',
            segment: 'CM',
            tickerCode: companyName,
            fundamentalReport: 'profit&Loss',
          });

          if (profitLossResponse.error) {
            setErr(profitLossResponse.error);
          } else {
            console.log('Profit and Loss Response:', profitLossResponse);
            setProfitLossData(profitLossResponse.data || []);
          }
          //BalanceSheet
          const Balance = await BalanceSheet({
            exchange: 'NSE',
            segment: 'CM',
            tickerCode: companyName,
            fundamentalReport: 'balanceSheet',
          });

          if (Balance.error) {
            setErr(Balance.error);
          } else {
            console.log('Profit and Loss Response:', Balance);
            setBalanceSheet(Balance.data || []);
            console.log("finding balancesheet data ---- ", Balance.data.data)
          }
          //CashFlow
          const cashflow = await CashFlow({
            exchange: 'NSE',
            segment: 'CM',
            tickerCode: companyName,
            fundamentalReport: 'cashFlows',
          });
          if (cashflow.err) {
            setErr(cashflow.err);
          } else {
            setCashFlow(cashflow.data || []);
          }
          //Ratio
          const ratiodata = await Ratios({
            exchange: 'NSE',
            segment: 'CM',
            tickerCode: companyName,
            fundamentalReport: 'ratios',
          });
          if (ratiodata.err) {
            setErr(ratiodata.err);
          } else {
            setRatio(ratiodata.data || []);
          }
          //ShareHolding
          const sharehold = await ShareHolding({
            exchange: 'NSE',
            segment: 'CM',
            tickerCode: companyName,
            fundamentalReport: 'shareholdingPattern',
          });
          if (sharehold.err) {
            setErr(sharehold.err);
          } else {
            setShareholding(sharehold.data || []);
          }
        }
      } catch (error) {
        console.log('Error Occurred While fetching data', error.message);
      }
    };

    fetchNewsAndProfitLoss();
  }, [
    companyName,
    companyNews,
    profitloss,
    BalanceSheet,
    CashFlow,
    Ratios,
    ShareHolding,
  ]);

  const navigate = useNavigate();

  const handleCol = () => {
    setCol(!col);
  };
  const handleSec = () => {
    setSecond(!second);
  };
  const handleThird = () => {
    setThird(!third);
  };
  const handleComponet = () => {
    switch (componet) {
      case 'Overview':
        return <Overview />;
      case 'events':
        return <Events />;
      case 'annual':
        return <Annual />;
      default:
        return <Overview />;
    }
  };

  const rendercomponent = () => {
    switch (timeRange) {
      case 'Daily':
        return <Daily />;
      case 'Weekly':
        return <Weekly />;
      case 'Monthly':
        return <Monthly />;
      default:
        return <Daily />;
    }
  };

  return (
    <div>
      <LandingPage />
      <Box
        sx={{
          position: 'relative',
          padding: '0px',
          margin: '0px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          transition: 'all 0.3s ease-out 0s',
          top: '103px',
        }}
      >
        <Box
          sx={{
            width: { xs: '90%', md: '80%' },
            top: '100px',
            position: 'relative',
            mx: 'auto',
            transition: 'all 0.3s ease-out 0s',
          }}
        >
          <div
      style={{
        position: 'absolute',
        display: 'flex',
        marginTop: 2,
        marginBottom: 4,
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {/* <div
        style={{
          border: userData ? '1px solid #34a853' : '1px solid #ff3d00',
          width: '85%',
          backgroundColor: userData ? '#d1ffdd' : '#fbe9e7',
          padding: '0.5rem',
          borderRadius: '0.25rem',
          position: 'relative',
        }}
      >
        {userData ? (
          <>
          <p style={{ color: '#34a853', fontSize: '14px', paddingLeft: '10px' }}>
          "Dive into the world of investing and trading with our pocket web-app. 💰🚀

            Discover new opportunities, track your portfolio, and make informed decisions.

            Visit now and start your journey to financial success!"
          </p>
          <button
              onClick={handlePocketNavigation}
              style={{
                cursor: 'pointer',
                borderRadius: '0.25rem',
                color: '#fff',
                backgroundColor: '#34a853',
                borderColor: '#dc3545',
                display: 'inline-block',
                textAlign: 'center',
                verticalAlign: 'middle',
                fontWeight: 400,
                marginTop: '8px',
                marginLeft: '8px',
                marginRight: '8px',
                border: '1px solid',
                paddingTop: '7px',
                paddingBottom: '7px',
                paddingLeft: '12px',
                paddingRight: '12px',
              }}
            >
              Get To Pocket-Web
            </button>
          </>
        ) : (
          <>
            <p style={{ color: '#e64141', fontSize: '14px', paddingLeft: '10px' }}>
              Please Login with your Mobile Number
            </p>
            <p style={{ color: '#374948', fontSize: '12px', paddingLeft: '10px' }}>
              Login to access our stock recommendation and many more offerings.
            </p>
            <button
              onClick={handelNavigation}
              style={{
                cursor: 'pointer',
                borderRadius: '0.25rem',
                color: '#fff',
                backgroundColor: '#dc3545',
                borderColor: '#dc3545',
                display: 'inline-block',
                textAlign: 'center',
                verticalAlign: 'middle',
                fontWeight: 400,
                marginTop: '8px',
                marginLeft: '8px',
                marginRight: '8px',
                border: '1px solid',
                paddingTop: '7px',
                paddingBottom: '7px',
                paddingLeft: '12px',
                paddingRight: '12px',
              }}
            >
              Login Now
            </button>
          </>
        )}
      </div> */}
    </div>
          <Grid
            sx={{
              border: '1px solid #ededed',
              boxShadow: '0 .125rem .25rem rgba(0, 0, 0, .075) !important',
              marginTop: '40px',
            }}
            container
            xs={12}
          >
            <Grid item xs={12}>
              <div
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  alignItems: 'center',
                  height: '55px',
                  borderBottom: '1px solid #ededed',
                }}
              >
                <SelectButton
                  onClick={() => setComponet('Overview')}
                  selected={componet === 'Overview'}
                >
                  Overview
                </SelectButton>
                <SelectButton
                  onClick={() => setComponet('events')}
                  selected={componet === 'events'}
                >
                  Events
                </SelectButton>
                <SelectButton
                  onClick={() => setComponet('annual')}
                  selected={componet === 'annual'}
                >
                  Annual Reports
                </SelectButton>
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              {' '}
              {handleComponet()}
            </Grid>
            <Grid item xs={12} md={6}>
  <h4
    style={{
      fontSize: '20px',
      color: '#131722',
      fontWeight: '400',
      textTransform: 'capitalize',
      marginBottom: '16px', // Add space below the title
    }}
  >
    News
  </h4>
  <div style={{ overflowY: 'auto', maxHeight: '265px' }}>
    {news.length > 0 ? (
      news.map((newsItem, index) => (
        <div
          className="srl"
          key={index}
          style={{
            display: 'flex',
            justifyContent: 'space-between', // Aligns the items properly with space between
            alignItems: 'center',
            padding: '12px 24px', // Adjusted padding for consistent spacing
            marginBottom: '10px', // Ensure uniform spacing between items
            borderBottom: '1px solid #ddd', // Optional: adds separation between news items
          }}
          onClick={() => window.open(newsItem.link, '_blank')}
        >
          <div style={{ flex: 1 }}>
            <p
              style={{
                fontSize: '10px',
                color: 'blue',
                marginBottom: '4px', // Adjust margin for spacing
              }}
            >
              {newsItem.datetime}
            </p>
            <p
              style={{
                fontSize: '14px',
                color: '#0e0e0e',
                cursor: 'pointer',
                margin: 0,
              }}
              onMouseEnter={(e) =>
                (e.currentTarget.style.textDecoration = 'underline')
              }
              onMouseLeave={(e) =>
                (e.currentTarget.style.textDecoration = 'none')
              }
            >
              {newsItem.title}
            </p>
          </div>
          <div style={{ flexShrink: 0 }}>
            {/* Uncomment and style this image if needed */}
            {/* <img
              className="img2"
              src={newsItem.img}
              alt="News"
              style={{ maxHeight: '80px', width: 'auto' }} // Adjust image size
            /> */}
          </div>
        </div>
      ))
    ) : (
      <p>No News found</p>
    )}
  </div>
</Grid>

          </Grid>
          <Grid
            sx={{
              border: '1px solid #ededed',
              boxShadow: '0 .125rem .25rem rgba(0, 0, 0, .075) !important',
              marginTop: '40px',
              padding: '25px',
            }}
            container
            xs={12}
          >
            <Grid item xs={12}>
              {' '}
              <h4 className="hh4">Technicals</h4>{' '}
            </Grid>
            <Grid item xs={12} md={8}>
              <div
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  alignItems: 'center',
                  marginTop: '10px',
                  paddingBottom: '10px',
                  borderBottom: '1px solid #ededed',
                }}
              >
                <SelectButton
                  onClick={() => setTimeRange('Daily')}
                  selected={timeRange === 'Daily'}
                >
                  Daily
                </SelectButton>
                <SelectButton
                  onClick={() => setTimeRange('Weekly')}
                  selected={timeRange === 'Weekly'}
                >
                  Weekly
                </SelectButton>
                <SelectButton
                  onClick={() => setTimeRange('Monthly')}
                  selected={timeRange === 'Monthly'}
                >
                  Monthly
                </SelectButton>
              </div>
              {rendercomponent()}
            </Grid>
            <Grid
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              item
              xs={12}
              md={4}
            >
              <Box sx={{ maxWidth: '360px' }}>
                <TechnicalAnalysis />
              </Box>
            </Grid>
          </Grid>
          <Grid
            sx={{
              border: '1px solid #ededed',
              boxShadow: '0 .125rem .25rem rgba(0, 0, 0, .075) !important',
              marginTop: '40px',
              padding: '25px',
            }}
            container
            xs={12}
          >
            <div style={{ overflowX: 'auto', width: '100%' }}>
              <div>
                <h4 className="hh4">Profit & Loss</h4>
                <p>Consolidated Figures in Rs. Crores</p>
              </div>
              <div>
                <table className="smalltable">
                  <thead>
                    <tr>
                      {/* Check if profitLossData is defined and has a data property that is an array */}
                      {profitLossData &&
                        Array.isArray(profitLossData.data) &&
                        profitLossData.data.length > 0 &&
                        profitLossData.data[0] &&
                        Object.values(profitLossData.data[0]).map(
                          (header, index) => (
                            <th
                              key={index}
                              style={{ paddingLeft: '10px' }}
                              scope="col"
                            >
                              {header !== null ? header : ''}
                            </th>
                          )
                        )}
                    </tr>
                  </thead>
                  <tbody>
                    {/* Check if profitLossData is defined and has more than one row in its data */}
                    {profitLossData &&
                      Array.isArray(profitLossData.data) &&
                      profitLossData.data.length > 1 &&
                      profitLossData.data.slice(1).map((row, rowIndex) => (
                        <tr key={rowIndex}>
                          {Object.values(row).map((cell, cellIndex) => (
                            <td key={cellIndex}>{cell !== null ? cell : ''}</td>
                          ))}
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </Grid>
          <Grid
            sx={{
              border: '1px solid #ededed',
              boxShadow: '0 .125rem .25rem rgba(0, 0, 0, .075) !important',
              marginTop: '40px',
              padding: '25px',
            }}
            container
            xs={12}
          >
            <div style={{ overflowX: 'auto', width: '100%' }}>
              <div>
                {' '}
                <h4 className="hh4">Balance Sheet</h4>{' '}
                <p>Consolidated Figures in Rs. Crores</p>
              </div>
              <table className="smalltable">
                <thead>
                  <tr>
                    {/* Check if profitLossData is defined and has a data property that is an array */}
                    {balanceSheet &&
                      Array.isArray(balanceSheet.data) &&
                      balanceSheet.data.length > 0 &&
                      balanceSheet.data[0] &&
                      Object.values(balanceSheet.data[0]).map(
                        (header, index) => (
                          <th
                            key={index}
                            style={{ paddingLeft: '10px' }}
                            scope="col"
                          >
                            {header !== null ? header : ''}
                          </th>
                        )
                      )}
                  </tr>
                </thead>
                <tbody>
                  {/* Check if profitLossData is defined and has more than one row in its data */}
                  {balanceSheet &&
                    Array.isArray(balanceSheet.data) &&
                    balanceSheet.data.length > 1 &&
                    balanceSheet.data.slice(1).map((row, rowIndex) => (
                      <tr key={rowIndex}>
                        {Object.values(row).map((cell, cellIndex) => (
                          <td key={cellIndex}>{cell !== null ? cell : ''}</td>
                        ))}
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </Grid>
          <Grid
            sx={{
              border: '1px solid #ededed',
              boxShadow: '0 .125rem .25rem rgba(0, 0, 0, .075) !important',
              marginTop: '40px',
              padding: '25px',
            }}
            container
            xs={12}
          >
            <div style={{ overflowX: 'auto', width: '100%' }}>
              <div>
                {' '}
                <h4 className="hh4">Cash Flows </h4>{' '}
                <p>Consolidated Figures in Rs. Crores</p>
              </div>
              <div>
                <table className="smalltable">
                  <thead>
                    <tr>
                      {/* Check if profitLossData is defined and has a data property that is an array */}
                      {cashFlow &&
                        Array.isArray(cashFlow.data) &&
                        cashFlow.data.length > 0 &&
                        cashFlow.data[0] &&
                        Object.values(cashFlow.data[0]).map((header, index) => (
                          <th
                            key={index}
                            style={{ paddingLeft: '10px' }}
                            scope="col"
                          >
                            {header !== null ? header : ''}
                          </th>
                        ))}
                    </tr>
                  </thead>
                  <tbody>
                    {/* Check if profitLossData is defined and has more than one row in its data */}
                    {cashFlow &&
                      Array.isArray(cashFlow.data) &&
                      cashFlow.data.length > 1 &&
                      cashFlow.data.slice(1).map((row, rowIndex) => (
                        <tr key={rowIndex}>
                          {Object.values(row).map((cell, cellIndex) => (
                            <td key={cellIndex}>{cell !== null ? cell : ''}</td>
                          ))}
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </Grid>
          <Grid
            sx={{
              border: '1px solid #ededed',
              boxShadow: '0 .125rem .25rem rgba(0, 0, 0, .075) !important',
              marginTop: '40px',
              padding: '25px',
            }}
            container
            xs={12}
          >
            <div style={{ overflowX: 'auto', width: '100%' }}>
              <div>
                {' '}
                <h4 className="hh4">Ratios </h4>{' '}
                <p>Consolidated Figures in Rs. Crores</p>
              </div>
              <div>
                <table className="smalltable">
                  <thead>
                    <tr>
                      {/* Check if profitLossData is defined and has a data property that is an array */}
                      {ratio &&
                        Array.isArray(ratio.data) &&
                        ratio.data.length > 0 &&
                        ratio.data[0] &&
                        Object.values(ratio.data[0]).map((header, index) => (
                          <th
                            key={index}
                            style={{ paddingLeft: '10px' }}
                            scope="col"
                          >
                            {header !== null ? header : ''}
                          </th>
                        ))}
                    </tr>
                  </thead>
                  <tbody>
                    {/* Check if profitLossData is defined and has more than one row in its data */}
                    {ratio &&
                      Array.isArray(ratio.data) &&
                      ratio.data.length > 1 &&
                      ratio.data.slice(1).map((row, rowIndex) => (
                        <tr key={rowIndex}>
                          {Object.values(row).map((cell, cellIndex) => (
                            <td key={cellIndex}>{cell !== null ? cell : ''}</td>
                          ))}
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </Grid>
          <Grid
            sx={{
              border: '1px solid #ededed',
              boxShadow: '0 .125rem .25rem rgba(0, 0, 0, .075) !important',
              marginTop: '40px',
              padding: '25px',
            }}
            container
            xs={12}
          >
            <div style={{ overflowX: 'auto', width: '100%' }}>
              <div>
                {' '}
                <h4 className="hh4">ShareHolding Pattern</h4>{' '}
                <p>Consolidated Figures in Rs. Crores</p>
              </div>
              <div>
                <table className="smalltable">
                  <thead>
                    <tr>
                      {/* Check if profitLossData is defined and has a data property that is an array */}
                      {shareholding &&
                        Array.isArray(shareholding.data) &&
                        shareholding.data.length > 0 &&
                        shareholding.data[0] &&
                        Object.values(shareholding.data[0]).map(
                          (header, index) => (
                            <th
                              key={index}
                              style={{ paddingLeft: '10px' }}
                              scope="col"
                            >
                              {header !== null ? header : ''}
                            </th>
                          )
                        )}
                    </tr>
                  </thead>
                  <tbody>
                    {/* Check if profitLossData is defined and has more than one row in its data */}
                    {shareholding &&
                      Array.isArray(shareholding.data) &&
                      shareholding.data.length > 1 &&
                      shareholding.data.slice(1).map((row, rowIndex) => (
                        <tr key={rowIndex}>
                          {Object.values(row).map((cell, cellIndex) => (
                            <td key={cellIndex}>{cell !== null ? cell : ''}</td>
                          ))}
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </Grid>
        </Box>
        <Grid item sx={{ top: '70px', position: 'relative' }} xs={12} md={6}>
          <FooterContent />
        </Grid>
      </Box>
    </div>
  );
};

export default CompanyPages;
