import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Solution from './Solution';
import Other from './Other';
import Hybrid from './Hybrid';
import Equity from './Equity';
import Debt from './Debt';
import { Box } from '@mui/material';

const Mutualfunds = () => {
  const [selectedComponent, setSelectedComponent] = useState('Component1');

  const renderComponent = () => {
    switch (selectedComponent) {
      case 'Component1':
        return <Debt />;
      case 'Component2':
        return <Equity />;
      case 'Component3':
        return <Hybrid />;
      case 'Component4':
        return <Solution />;
      case 'Component5':
        return <Other />;
      default:
        return <Debt />;
    }
  };

  const getButtonStyles = (component) => ({
    color: selectedComponent === component ? '#ffffff' : '#000000', // White text when selected, black when unselected
    backgroundColor: selectedComponent === component ? '#34a853' : 'transparent', // Green background when selected, transparent when unselected
    borderRadius: '50px', // Rounded button
    border: '1px solid #34a853', // Green border for both selected and unselected
    padding: '4px 12px', // Larger padding for better spacing
    fontSize: selectedComponent === component ? '13px' : '11px', // Bigger font when selected
    fontWeight: 600, // Bold text
    marginBottom: '10px',
    marginLeft: '10px',
    textTransform: 'none',
    cursor: 'pointer',
    transition: 'transform 0.2s ease, background-color 0.3s ease, color 0.3s ease, font-size 0.3s ease', // Smooth transitions
    transform: selectedComponent === component ? 'scale(1.08)' : 'scale(1)', // Slightly bigger button when selected
    '&:hover': {
      backgroundColor: selectedComponent === component ? '#34a853' : '#d1ffdd', // Hover background color for unselected buttons
      transform: 'scale(1.1)', // Slightly larger on hover
    },
    '&:active': {
      transform: 'scale(0.95)', // Shrink on click
    },
  });

  return (
    <Box sx={{ width: '100%', padding: '20px', boxSizing: 'border-box', border: '1px solid #d3d3d3', borderRadius: '8px', }}>
      <h5
        style={{
          fontSize: '20px',
          fontWeight: 400,
          letterSpacing: '.3px',
          color: '#131722',
          lineHeight: '1.5',
          textAlign: 'center',
          margin: 0,
        }}
      >
        Mutual Funds
      </h5>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',

          alignItems: 'center',
          marginTop: '20px',
        }}
      >
        <Button
          onClick={() => setSelectedComponent('Component1')}
          sx={getButtonStyles('Component1')}
        >
          Debt
        </Button>
        <Button
          onClick={() => setSelectedComponent('Component2')}
          sx={getButtonStyles('Component2')}
        >
          Equity
        </Button>
        <Button
          onClick={() => setSelectedComponent('Component3')}
          sx={getButtonStyles('Component3')}
        >
          Hybrid
        </Button>
        <Button
          onClick={() => setSelectedComponent('Component4')}
          sx={getButtonStyles('Component4')}
        >
          Solution Oriented
        </Button>
        <Button
          onClick={() => setSelectedComponent('Component5')}
          sx={getButtonStyles('Component5')}
        >
          Other
        </Button>
      </Box>
      <hr />
      <Box sx={{ width: '100%', boxSizing: 'border-box' }}>
        {renderComponent()}
      </Box>
    </Box>
  );
};

export default Mutualfunds;
