import React from "react";
import { Box, Typography } from "@mui/material";

const ClientRegistration = () => {
  return (
    <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
      {/* Text Section */}
      <div className="lg:col-span-2">
        <h2 className="text-base font-bold mb-4">
          Client Registration Policy of ATS
        </h2>
        <ul className="list-disc list-outside pl-5 space-y-4">
          {/* First two list items aligned with the image */}
          <li className="text-justify">
            You have to fill and submit the KYC form along with Supporting
            Documents and Proofs to ATS. Trading is only permitted after
            successful review of all relevant documentation, and upload of the
            unique Client Code with the Exchange and KRA.
          </li>
          <li className="text-justify">
            Once the Account is opened successfully, you will be sent a welcome
            Mail mentioning your Trading Code, Demat Account Number, Registered
            Bank to which Payout amount will be transferred, your registered
            mobile number to which trading alerts will be sent and your
            registered mail id, to which Contract note, account statements, etc.
            will be sent.
          </li>
        </ul>
      </div>

      {/* Image Section */}
      <div className="lg:col-span-1 flex justify-center items-start mt-12">
        <img
          src="https://adityatrading.in/static/images/terms-and-conditions.svg"
          alt="Illustration of people checking off a list on a large document"
          className="w-full lg:w-auto"
        />
      </div>

      {/* Remaining list items */}

      <div className="lg:col-span-3">
        <ul className="list-disc list-outside pl-5 space-y-4">
          <li className="text-justify">
            You can trade with us via online, mobile, or through contacting any
            of our branches. These multiple platforms make your trading
            experience highly convenient and hassle-free.
          </li>
          <li className="text-justify">
            Contact details of our Customer Support, Compliance Officer and the
            Login Details to access our 24*7 online Back office is also
            mentioned in the Welcome Letter.
          </li>
          <li className="text-justify">
            Additional documents will be asked to submit for Registration of Non
            individual Clients and Specially Category Clients like NRIs, HNIs,
            Trusts, Politically Exposed Persons, etc.
          </li>
          <li className="text-justify">
            ATS reserves the right to hold the registration of those clients,
            where documents submitted are incomplete or mismatch of information
            is found between KYC Form and Proofs submitted, or if the Client
            Name matches with Debarred List of SEBI or other regulatory bodies,
            and where In Person Verification could not be successfully
            completed.
          </li>
          <li className="text-justify">
            ATS reserves the right to suspend or close the Trading or Demat
            Account opened with it without any prior notice, if client is found
            to be involved in violation of any Exchange rules, or when client
            fails to fulfill its payment obligations due to ATS or if found
            client has misrepresented the facts at the time of account opening
            or on receiving such instructions from any court of law or other
            competent authority.
          </li>
          <li className="text-justify">
            ATS collects Rs.100 plus service tax as Account Opening and KRA
            Registration charges from new clients. Account Opening fees will not
            be refunded even if in case, the account is opened but no
            transactions are done.
          </li>
        </ul>

        <h2 className="text-base font-bold mb-4 mt-8">
          Client Registration Policy of ATS
        </h2>
        <ul className="list-disc list-outside pl-5 space-y-4">
          <li className="text-justify">
            Clients should always transfer funds for trading margin only from
            the Registered Bank Accounts with ATS. Any fund received from
            unregistered bank accounts or third party Bank Accounts will not be
            considered for margin limit and the same will be paid back to the
            source account.
          </li>
          <li className="text-justify">
            Funds will be credited to the client trading Account, only after
            verifying the source Bank Account from where the funds were
            transferred to ensure it has come from registered account only. On
            deposit of margin Money, clients should provide necessary documents
            to help ATS to verify the source of funds for smooth and easy credit
            of margin limit.
          </li>
          <li className="text-justify">
            Margin Money can be deposited through Cheques, Online Fund
            Transfers, and through Payment Gateway option in our trading
            platform.
          </li>
          <li className="text-justify">
            DD or P.O will be acceptable only if the same are accompanied by the
            name of the bank account holder and account number of the bank
            account debited for the purpose, duly certified by the Bank and
            certificate on banker’s letter head.
          </li>
          <li className="text-justify">
            ATS do not accept margin money in cash. Cash deposited to ATS client
            bank account is refunded to client on receiving the necessary
            supporting documents. ATS will levy Rs.500/- as processing fees
            while refunding such cash deposited to its back account.
          </li>
        </ul>

        <h2 className="text-base font-bold mb-4 mt-8">Payout of Funds</h2>
        <ul className="list-disc list-outside pl-5 space-y-4">
          <li className="text-justify">
            Payout requests are processed and funds transferred to client bank
            accounts in 1 business day of raising the payout request, if
            sufficient cleared funds are available at the time of processing the
            request.
          </li>
          <li className="text-justify">
            Online Requests can be raised by the client from their back office
            or trading login. All requests made before 8.30 am will be processed
            the same day and requests placed after 8.30 am will be processed on
            the next working day. Clients can also mail or call to our customer
            support to raise a payout request in case of emergencies.
          </li>
          <li className="text-justify">
            Payout amount will be transferred online to Client’s Registered
            Primary Bank Account only
          </li>
        </ul>
      </div>
    </div>
  );
};

export default ClientRegistration;
