// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.items {
  display: flex;
  justify-content: space-between;
  left: 10px;
  position: relative;
  width: 250px;
  min-width: 150px;
  justify-content: left;
  align-items: center;
  padding: 3px 10px;
  line-height: normal;
  height: 30px;
}
.items p:hover {
  color: #34a853;
  cursor: pointer;
}
.items p {
  font-size: 14px;
  font-weight: 400;
}
.items svg {
  height: 18px;
  width: 31px;
  line-height: 31px;
  color: #777d74;
}
`, "",{"version":3,"sources":["webpack://./src/LoginModel/Login.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;EAC9B,UAAU;EACV,kBAAkB;EAClB,YAAY;EACZ,gBAAgB;EAChB,qBAAqB;EACrB,mBAAmB;EACnB,iBAAiB;EACjB,mBAAmB;EACnB,YAAY;AACd;AACA;EACE,cAAc;EACd,eAAe;AACjB;AACA;EACE,eAAe;EACf,gBAAgB;AAClB;AACA;EACE,YAAY;EACZ,WAAW;EACX,iBAAiB;EACjB,cAAc;AAChB","sourcesContent":[".items {\r\n  display: flex;\r\n  justify-content: space-between;\r\n  left: 10px;\r\n  position: relative;\r\n  width: 250px;\r\n  min-width: 150px;\r\n  justify-content: left;\r\n  align-items: center;\r\n  padding: 3px 10px;\r\n  line-height: normal;\r\n  height: 30px;\r\n}\r\n.items p:hover {\r\n  color: #34a853;\r\n  cursor: pointer;\r\n}\r\n.items p {\r\n  font-size: 14px;\r\n  font-weight: 400;\r\n}\r\n.items svg {\r\n  height: 18px;\r\n  width: 31px;\r\n  line-height: 31px;\r\n  color: #777d74;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
