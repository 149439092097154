import React from 'react';
import classNames from 'classnames';
import './Politicalnews.css';
import { Link } from '@mui/material';
import '../App.css';
import { usePoliticalNewsQuery } from '../redux/FinnancialNews';

const PoliticalNews = () => {
  const { data: politicalNews } = usePoliticalNewsQuery();
  const political = politicalNews?.news || [];
  return (
    <div
      className={classNames('politicalnewscontainer', 'scrollable-container')}
    >
      {political.length > 0 ? (
        political.map((poltic, index) => (
          <Link
            key={index}
            href={poltic.link || '#'}
            target="_blank"
            rel="noopener noreferrer"
            style={{
              textDecoration: 'none',
              color: 'inherit',
              display: 'block',
              cursor: 'pointer',
            }}
          >
            <div className="shadowbox">
              <div>
                <p className="companyName">
                  {poltic.datetime || 'Date not available'}
                </p>
                <p className="newsHeading">
                  {poltic.desc || 'Description not available'}
                </p>
              </div>
            </div>
          </Link>
        ))
      ) : (
        <p>No political news available.</p>
      )}
    </div>
  );
};

export default PoliticalNews;
