import React from 'react';
import { useNavigate } from 'react-router';

const Update = () => {
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate('/update-user');
  };
  return (
    <div
      style={{
        position: 'absolute',
        display: 'flex',
        marginTop: 2,
        marginBottom: 4,
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          border: '1px solid #ffab00',
          width: '85%',
          backgroundColor: '#fffde7',
          padding: '0.5rem',
          borderRadius: '0.25rem',

          position: 'relative',
        }}
      >
        <p
          style={{
            color: '#ffab00!important',
            fontSize: '16px',
            fontWeight: '400',
            paddingLeft: '10px',
          }}
        >
          Update your Profile Now !
        </p>
        <p style={{ color: '#374948', fontSize: '12px', paddingLeft: '10px' }}>
          {' '}
          Start updating your profile to get access to our lot of Perks..
        </p>
        <button
          onClick={handleNavigate}
          style={{
            cursor: 'pointer',
            borderRadius: '0.25rem',
            color: '#fff',
            backgroundColor: '#ff9800',
            borderColor: '#ff9800   ',
            display: 'inline-block',
            textAlign: 'center',
            verticalAlign: 'middle',
            fontWeight: 400,
            marginTop: '8px',
            marginLeft: '8px',
            marginRight: '8px',
            border: '1px solid',
            paddingTop: '7px',
            paddingBottom: '7px',
            paddingLeft: '12px',
            paddingRight: '12px',
          }}
        >
          Update Now
        </button>
      </div>
    </div>
  );
};

export default Update;
