import React, { useState } from 'react';
import FooterContent from '../Footer/FooterContent';
import './Signin.css';
import {
  Button,
  Grid,
  Typography,
  useMediaQuery,
  Container,
} from '@mui/material';
import LandingPage from '../LandingPage';
import { useTheme } from '@mui/material/styles';
import { useGenerateOtpMutation } from '../redux/otp';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router';

// Define Yup validation schema
const schema = yup.object().shape({
  mobileNumber: yup
    .string()
    .required('Mobile number is required')
    .matches(/^[0-9]{10}$/, 'Mobile number must be exactly 10 digits'),
});

const Signin = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  // Initialize React Hook Form
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  // Generate OTP mutation hook
  const [generateOtp, { data, error }] = useGenerateOtpMutation();

  // Handle form submission
  const onSubmit = async (data) => {
    const { mobileNumber } = data;

    localStorage.setItem('sessionPhoneKEY', mobileNumber);
    await generateOtp(mobileNumber);
    navigate('/verify-otp');
  };

  return (
    <div>
      <LandingPage />
      <div className="content-page">
        <div className="container">
          <div className="box">
            <div className="center">
              <div className="container-fluid">
                <Container>
                  <Grid
                    container
                    className="box"
                    justifyContent="center"
                    alignItems="center"
                    spacing={3}
                  >
                    <Grid item xs={12} lg={6} sm={8} md={6}>
                      <img
                        src="https://adityatrading.in/static/images/banners/trade-desktop.png"
                        alt="Banner Image"
                        style={{ marginTop: '20px', width: '100%' }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      lg={6}
                      sm={8}
                      md={6}
                      style={{ padding: '16px', marginTop: '16px' }}
                    >
                      <Typography
                        variant="h4"
                        style={{
                          fontSize: isSmallScreen ? '20px' : '28px',
                          marginBottom: '5px',
                          margin: '0px',
                          color: '#131722',
                          fontWeight: '400',
                        }}
                      >
                        Join 1 Lakh+ investors & traders!
                      </Typography>
                      <Typography
                        variant="body1"
                        style={{
                          marginBottom: '16px',
                          fontSize: isSmallScreen ? '20px' : '28px',
                        }}
                      >
                        Open your free trading & Demat account online today!
                      </Typography>
                      {/* Form Input */}
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="phoneInput">
                          <Controller
                            name="mobileNumber"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                              <input
                                {...field}
                                type="tel"  // Changed to 'tel' to better handle mobile inputs
                                placeholder="Enter Your Phone Number"
                                maxLength={10}  // Limit the input to 10 digits
                                onInput={(e) => {
                                  e.target.value = e.target.value.replace(/\D/g, '');  // Remove non-numeric characters
                                }}
                                style={{ outline: 'none', border: 'none' }}
                              />
                            )}
                          />
                          {/* Display validation errors */}
                          {errors.mobileNumber && (
                            <Typography variant="body2" color="error">
                              {errors.mobileNumber.message}
                            </Typography>
                          )}
                        </div>
                        <div style={{ width: '100%', marginTop: '20px' }}>
                          <Button
                            type="submit"
                            sx={{
                              color: '#e5f2ff!important',
                              border: '1px solid #e5f2ff!important',
                              paddingTop: '7px',
                              paddingLeft: '8px',
                              paddingRight: '8px',
                              paddingBottom: '7px',
                              fontSize: '14px',
                              fontWeight: 500,
                              backgroundColor: '#34a853 ',
                              width: '100%',
                              left: '-1px !important',
                              textTransform: 'none',
                              '&:hover': {
                                backgroundColor: '#34a853',
                              },
                            }}
                          >
                            Send OTP
                          </Button>
                        </div>
                      </form>
                    </Grid>
                  </Grid>
                </Container>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterContent />
    </div>
  );
};

export default Signin;
