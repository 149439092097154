import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { CHAT_API, Chat_USERS_API, Index_Stocks_API } from '.././Api';
import { BASE_URL, SERVER_URL } from './baseurl';

export const stockAPI = createApi({
  reducerPath: 'stocksApi',
  baseQuery: fetchBaseQuery({
    baseUrl: SERVER_URL,
  }),
  tagTypes: ['Stocks'],
  endpoints: (builder) => ({
    getStocks: builder.query({
      query: () => ({
        method: 'GET',
        url: Index_Stocks_API,
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
        },
      }),
      providesTags: ['Stock'],
    }),
  }),
});

export const { useGetStocksQuery } = stockAPI;

export default stockAPI;
