import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { FormControl, useFormControlContext } from '@mui/base/FormControl';
import { Input, inputClasses } from '@mui/base/Input';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/system';
import clsx from 'clsx';
import {
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  makeStyles,
} from '@mui/material';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'auto',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  maxWidth: '700px',
  p: 4,
  height: 'auto',
  maxHeight: '569px',
  overflowX: 'auto',
};

export default function JobInput({ open, close }) {
  const handleClose = () => {
    close();
  };

  // Step 1: Initialize state
  const [formData, setFormData] = React.useState({
    firstName: '',
    lastName: '',
    phoneNumber: '',
    emailAddress: '',
    applyingFor: '',
    jobType: '',
    experience: '',
    currentCompany: '',
    resume: null,
    currentLocation: '',
  });

  // Step 2: Handle input changes
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (event) => {
    const { name, files } = event.target;
    if (files.length > 0) {
      const file = files[0];
      setFormData({
        ...formData,
        [name]: file, // Store the file object
      });
    }
  };

  // Step 3: Handle form submission
  const handleSubmit = () => {
    console.log(formData); // Logs the form data to the console
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              justifyContent: 'space-between',
              alignItems: 'center',
              display: 'flex',
            }}
          >
            <Typography
              sx={{
                fontWeight: '700',
                fontSize: '24px',
                textAlign: 'center',
                color: '#2a2525!important',
                display: 'inline-block',
              }}
              id="modal-modal-title"
              variant="h6"
              component="h2"
            >
              Apply Now
            </Typography>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <Label>First Name</Label>
              <StyledInput
                name="firstName"
                value={formData.firstName}
                onChange={handleInputChange}
                placeholder="Write your name here"
              />
              <HelperText />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <Label>Last Name</Label>
              <StyledInput
                name="lastName"
                value={formData.lastName}
                onChange={handleInputChange}
                placeholder="Write your name here"
              />
              <HelperText />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <Label>Phone Number</Label>
              <StyledInput
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleInputChange}
                placeholder="Write your phone number here"
              />
              <HelperText />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <Label>Email Address</Label>
              <StyledInput
                name="emailAddress"
                value={formData.emailAddress}
                onChange={handleInputChange}
                placeholder="Write your email here"
              />
              <HelperText />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <InputLabel>Applying for</InputLabel>
              <Select
                sx={{
                  '&:hover': {
                    '&& fieldset': {
                      border: '1px solid #42a5f5',
                    },
                  },
                }}
                name="applyingFor"
                value={formData.applyingFor}
                onChange={handleInputChange}
                fullWidth
                displayEmpty
              >
                <MenuItem value="">Select Position</MenuItem>
                <MenuItem value="Frontend Developer">
                  Frontend Developer
                </MenuItem>
                <MenuItem value="Backend Developer">Backend Developer</MenuItem>
                <MenuItem value="Full Stack Developer">
                  Full Stack Developer
                </MenuItem>
                {/* Add more options as needed */}
              </Select>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={6}>
              <InputLabel>Job Type</InputLabel>
              <Select
                sx={{
                  '&:hover': {
                    '&& fieldset': {
                      border: '1px solid #42a5f5',
                    },
                  },
                }}
                labelId="select-label"
                id="select"
                name="jobType"
                value={formData.jobType}
                onChange={handleInputChange}
                fullWidth
                displayEmpty
              >
                <MenuItem value="">Select Job Type</MenuItem>
                <MenuItem value="Full Time">Full Time</MenuItem>
                <MenuItem value="Part Time">Part Time</MenuItem>
                <MenuItem value="Contract">Contract</MenuItem>
                <MenuItem value="Internship">Internship</MenuItem>
                {/* Add more options as needed */}
              </Select>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <Label>Total Year Of Experience</Label>
              <StyledInput
                name="experience"
                value={formData.experience}
                onChange={handleInputChange}
                placeholder="Write your experience in years"
              />
              <HelperText />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <Label>Current Company</Label>
              <StyledInput
                name="currentCompany"
                value={formData.currentCompany}
                onChange={handleInputChange}
                placeholder="Write your current company name here"
              />
              <HelperText />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <Label>Upload Resume</Label>
              <StyledInput
                type="file" // Set type to file
                name="resume"
                onChange={handleFileChange} // Use file handler
                inputProps={{ accept: '.pdf,.doc,.docx' }} // Optional: Restrict file types
              />
              {formData.resumeName && (
                <Typography variant="body2" sx={{ marginTop: '8px' }}>
                  {formData.resumeName}
                </Typography>
              )}
              <HelperText />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <Label>Current Location</Label>
              <StyledInput
                name="currentLocation"
                value={formData.currentLocation}
                onChange={handleInputChange}
                placeholder="Write your current location here"
              />
              <HelperText />
            </Grid>
          </Grid>
          <Button
            variant="contained"
            sx={{
              border: '1px solid #36B864',
              color: '#FFFFFF',
              width: '129px',
              fontWeight: '500',
              height: '31px',
              padding: '0px',
              textTransform: 'none',
              textAlign: 'right !important',
              backgroundColor: '#3DAC5A',
              '&:hover': {
                backgroundColor: '#3DAC5A',
              },
            }}
            onClick={handleSubmit}
          >
            Apply Now
          </Button>
        </Box>
      </Modal>
    </div>
  );
}
const StyledInput = styled(Input)(
  ({ theme }) => `
  
    .${inputClasses.input} {
      width: 300px;
      font-family: 'IBM Plex Sans', sans-serif;
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.5;
      padding: 8px 12px;
      margin-right:'10px';
      border-radius: 8px;
      color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
      background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
      border: 1px solid ${
        theme.palette.mode === 'dark' ? grey[700] : grey[200]
      };
      box-shadow: 0px 2px 2px ${
        theme.palette.mode === 'dark' ? grey[900] : grey[50]
      };
  
      &:hover {
        border-color: ${blue[400]};
      }
  
      &:focus {
        outline: 0;
        border-color: ${blue[400]};
        box-shadow: 0 0 0 3px ${
          theme.palette.mode === 'dark' ? blue[600] : blue[200]
        };
      }
    }
  `
);

const Label = styled(({ children, className }) => {
  const formControlContext = useFormControlContext();
  const [dirty, setDirty] = React.useState(false);

  React.useEffect(() => {
    if (formControlContext?.filled) {
      setDirty(true);
    }
  }, [formControlContext]);

  if (formControlContext === undefined) {
    return <p>{children}</p>;
  }

  const { error, required, filled } = formControlContext;
  const showRequiredError = dirty && required && !filled;

  return (
    <p className={clsx(className, error || showRequiredError ? 'invalid' : '')}>
      {children}
      {required ? ' *' : ''}
    </p>
  );
})`
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 0.875rem;
  margin-bottom: 4px;

  &.invalid {
    color: red;
  }
`;

const HelperText = styled((props) => {
  const formControlContext = useFormControlContext();
  const [dirty, setDirty] = React.useState(false);

  React.useEffect(() => {
    if (formControlContext?.filled) {
      setDirty(true);
    }
  }, [formControlContext]);

  if (formControlContext === undefined) {
    return null;
  }

  const { required, filled } = formControlContext;
  const showRequiredError = dirty && required && !filled;

  return showRequiredError ? <p {...props}>This field is required.</p> : null;
})`
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 0.875rem;
`;

const blue = {
  100: '#DAECFF',
  200: '#b6daff',
  400: '#3399FF',
  500: '#007FFF',
  600: '#0072E5',
  900: '#003A75',
};

const grey = {
  50: '#F3F6F9',
  100: '#E5EAF2',
  200: '#DAE2ED',
  300: '#C7D0DD',
  400: '#B0B8C4',
  500: '#9DA8B7',
  600: '#6B7A90',
  700: '#434D5B',
  800: '#303740',
  900: '#1C2025',
};
