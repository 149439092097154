import React, { useEffect } from "react";
import LandingPage from "../../LandingPage";

import FooterContent from "../../Footer/FooterContent";
import { useLocation } from "react-router-dom";

import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import "./Reports.css";
import { ArrowDownward } from "@mui/icons-material";
import Footer from "../../Footer/Footer";
import { useResearchReportQuery } from "../../redux/sharemarketnews";
const MorningReportsDetails = () => {
  const location = useLocation();
  const page = location.state?.reportIndex;
  const { data: reportData, isLoading, error } = useResearchReportQuery();

  console.log("Item no I'm on -->", page);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  console.log("report", reportData ? reportData.posts[page] : []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error fetching report data</div>;
  }

  if (!reportData || !reportData.posts || !reportData.posts[page]) {
    return <div>Report data not available</div>;
  }

  return (
    <div>
      <LandingPage />
      <Box
        style={{
          position: "relative",
          padding: "0px",
          margin: "0px",
          justifyContent: "center",
          alignItems: "center",
          transition: "all 0.3s ease-out 0s",
        }}
      >
        <Box
          sx={{
            width: "80%",
            top: "100px",
            position: "relative",
            marginLeft: "auto",
            marginRight: "auto",
            transition: "all 0.3s ease-out 0s",
          }}
        >
          <Grid container direction="column" spacing={2}>
            <Grid item xs={12}>
              <h1
                style={{ display: "flex", color: "black", fontWeight: "400" }}
              >
                Daily Research Report ||&nbsp;
                <span
                  dangerouslySetInnerHTML={{
                    __html:
                      reportData.posts[page].morningNote.date ??
                      "<p>No date</p>",
                  }}
                />
              </h1>
              <p>
                <small>
                  Research reports are prepared by our research analysts who
                  have deep industry knowledge and experience.
                </small>
              </p>
              <hr />
            </Grid>

            <Grid container>
              <Grid
                sx={{
                  border: "1px solid #c6c5ca",
                  borderRadius: "5px",
                  padding: "5px 10px",
                  marginTop: "15px",
                  float: "left",
                  marginRight: "35px",
                }}
                item
                xs={12}
                md={3.7}
              >
                <h6
                  style={{
                    fontSize: "16px",
                    fontFamily: "poppins sans-serif !important",
                    lineHeight: "1.5",
                    margin: "0px",
                    fontWeight: "600",
                    textAlign: "center",
                  }}
                >
                  Outlook
                  <hr />
                </h6>
                <p>
                  <span
                    style={{
                      fontSize: "10pt",
                      textAlign: "justify",
                      color: "black",
                    }}
                  >
                    <div
                      style={{
                        fontSize: "10pt",
                        textAlign: "justify",
                        color: "black",
                      }}
                      dangerouslySetInnerHTML={{
                        __html:
                          reportData.posts[page].morningNote.outlook ??
                          "<p>No outlook available.</p>",
                      }}
                    />
                  </span>
                </p>
                <p style={{ textAlign: "justify" }}></p>
              </Grid>
              <Grid
                sx={{
                  border: "1px solid #c6c5ca",
                  borderRadius: "5px",
                  padding: "5px 10px",
                  marginTop: "15px",
                  float: "left",
                }}
                item
                xs={12}
                md={3.7}
              >
                <h6
                  style={{
                    fontSize: "16px",
                    fontFamily: "poppins sans-serif !important",
                    lineHeight: "1.5",
                    margin: "0px",
                    fontWeight: "600",
                    textAlign: "center",
                  }}
                >
                  Currencies
                  <hr />
                </h6>
                <p>
                  <span
                    style={{
                      fontSize: "10pt",
                      textAlign: "justify",
                      color: "black",
                    }}
                  >
                    <div
                      style={{
                        fontSize: "10pt",
                        textAlign: "justify",
                        color: "black",
                      }}
                      dangerouslySetInnerHTML={{
                        __html:
                          reportData.posts[page].morningNote.currencies ??
                          "<p>No outlook available.</p>",
                      }}
                    />
                  </span>
                </p>
              </Grid>
              <Grid
                sx={{
                  border: "1px solid #c6c5ca",
                  borderRadius: "5px",
                  padding: "5px 10px",
                  marginTop: "15px",
                  float: "left",
                  marginLeft: "27px",
                }}
                item
                xs={12}
                md={3.7}
              >
                <h6
                  style={{
                    fontSize: "16px",
                    fontFamily: "poppins sans-serif !important",
                    lineHeight: "1.5",
                    margin: "0px",
                    fontWeight: "600",
                    textAlign: "center",
                  }}
                >
                  Commodities
                  <hr />
                </h6>
                <p>
                  <span
                    style={{
                      fontSize: "10pt",
                      textAlign: "justify",
                      color: "black",
                    }}
                  >
                    <div
                      style={{
                        fontSize: "10pt",
                        textAlign: "justify",
                        color: "black",
                      }}
                      dangerouslySetInnerHTML={{
                        __html:
                          reportData.posts[page].morningNote.commodities ??
                          "<p>No outlook available.</p>",
                      }}
                    />
                  </span>
                </p>
                <p style={{ textAlign: "justify" }}></p>
              </Grid>
            </Grid>
            <Grid
              sx={{
                border: "1px solid #ededed",
                marginTop: "50px",
                paddingRight: "16px",
              }}
              item
              xs={12}
            >
              <h6
                style={{
                  fontSize: "13px",

                  paddingTop: "15px",
                  paddingBottom: "15px",
                  borderRadius: "5px",
                }}
              >
                Consolidated Call Tracker{" "}
              </h6>
              <hr />
              <table className="table-stack">
                <thead>
                  <tr>
                    <th className="start">Name</th>
                    <th className="start">Trade</th>
                    <th className="center">Entry</th>
                    <th className="end">Stop Loss</th>
                    <th className="end">Target</th>
                  </tr>
                </thead>
                <tbody>
                  {reportData.posts[page].ConsolidatedCallTrackerData.map(
                    (item, index) => (
                      <tr key={index}>
                        <td className="start">
                          <span className="responsive-label">Name: </span>
                          {item.stock}
                        </td>
                        <td className="start">
                          <span className="responsive-label">Trade: </span>
                          {item.trade}
                        </td>
                        <td className="center">
                          <span className="responsive-label">Trade: </span>
                          <span
                            style={{
                              color: item.trade === "BUY" ? "green" : "red",
                            }}
                          >
                            {item.trade}
                          </span>
                        </td>

                        <td className="end">
                          <span className="responsive-label">Stop Loss: </span>
                          {item.sl}
                        </td>
                        <td className="end">
                          <span className="responsive-label">Target: </span>
                          {item.target}
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            </Grid>
            <Grid
              sx={{
                border: "1px solid #ededed",
                marginTop: "50px",
                paddingRight: "16px",
              }}
              item
              xs={12}
            >
              <h6
                style={{
                  fontSize: "13px",

                  paddingTop: "15px",
                  paddingBottom: "15px",
                  borderRadius: "5px",
                }}
              >
                Commodity Pivot Table
              </h6>
              <hr />
              <table className="table-stack">
                <thead>
                  <tr>
                    <th className="start">Scrip</th>
                    <th className="start">Expiry</th>
                    <th className="start">S3</th>
                    <th className="end">S2</th>
                    <th className="end">S1</th>
                    <th className="end">Pivot Point</th>
                    <th className="end">R1</th>
                    <th className="end">R2</th>
                    <th className="end">R3</th>
                  </tr>
                </thead>
                <tbody>''</tbody>
              </table>
            </Grid>
            <Grid container>
              <Grid item xs={12} lg={8} md={8}>
                <h6>Economy News</h6>
                <div style={{ width: "100%" }}>
                  <div
                    style={{
                      fontSize: "15pt",
                      fontWeight: "500",
                    }}
                    dangerouslySetInnerHTML={{
                      __html:
                        reportData.posts[page].morningNote.economy_news ??
                        "<p>No economy news available.</p>",
                    }}
                  />
                  {/* reportData.posts[page].ConsolidatedCallTrackerData   */}
                  <ul style={{ marginBottom: "50px", paddingLeft: "0" }}>
                    <li
                      style={{
                        marginLeft: "8px",
                        textAlign: "justify",
                        fontSize: "11pt",
                        listStyle: "none",
                      }}
                    >
                      {/* Additional content can be placed here */}
                    </li>
                  </ul>
                </div>

                <div>
                  <h6> Corporate News</h6>
                  <div style={{ width: "100%" }}>
                    <div
                      style={{
                        fontSize: "15pt",
                        fontWeight: "500",
                      }}
                      dangerouslySetInnerHTML={{
                        __html:
                          reportData.posts[page].morningNote.corporate_news ??
                          "<p>No economy news available.</p>",
                      }}
                    />
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} md={4}>
                <div
                  style={{
                    border: "1px solid #ededed",
                    borderRadius: "5px",
                    paddingTop: "20px",
                    paddingBottom: "20px",
                    display: "flex",
                    flexDirection: "column",
                    marginTop: "30px",
                    paddingLeft: "8px",
                    paddingRight: "8px",
                  }}
                >
                  <div>
                    <h6 style={{ fonntSize: "14px" }}>Securities In Ban</h6>
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th
                          style={{
                            textAlign: "start",
                            fontSize: "small",
                            fontWeight: "400",
                          }}
                        >
                          Symbol
                        </th>
                        <th
                          style={{
                            textAlign: "end",
                            fontSize: "small",
                            fontWeight: "400",
                          }}
                        >
                          MWPL%
                        </th>
                      </tr>
                    </thead>
                  </table>
                </div>
                <div
                  style={{
                    border: "1px solid #ededed",
                    borderRadius: "5px",
                    paddingTop: "20px",
                    paddingBottom: "20px",
                    display: "flex",
                    flexDirection: "column",
                    marginTop: "30px",
                    paddingLeft: "8px",
                    paddingRight: "8px",
                  }}
                >
                  <table style={{ borderCollapse: "collapse" }}>
                    <thead
                      style={{
                        borderCollapse: "collapse",
                        borderBottom: "2px solid #ededed",
                      }}
                    >
                      <tr style={{ borderBottom: "2px solid #ededed" }}>
                        <th
                          style={{
                            textAlign: "start",
                            fontSize: "small",
                            fontWeight: "400",
                          }}
                        >
                          Volatility Index
                        </th>
                        <th
                          style={{
                            textAlign: "center",
                            fontSize: "small",
                            fontWeight: "400",
                          }}
                        >
                          Nifty
                        </th>
                        <th
                          style={{
                            textAlign: "end",
                            fontSize: "small",
                            fontWeight: "400",
                          }}
                        >
                          % Chg
                        </th>
                      </tr>
                    </thead>
                    <tbody
                      style={{
                        borderCollapse: "collapse",
                        borderBottom: "2px solid #ededed",
                      }}
                    >
                      <tr style={{ borderBottom: "2px solid #ededed" }}>
                        <td
                          style={{
                            textAlign: "start",
                            fontSize: "small",
                            fontWeight: "100",
                          }}
                        >
                          <span
                            dangerouslySetInnerHTML={{
                              __html:
                                reportData.posts[page].volatilityIndexes[0]
                                  .volatility_indices ?? "<p>INDEX</p>",
                            }}
                          />
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            fontSize: "small",
                            fontWeight: "200",
                          }}
                        >
                          <span
                            dangerouslySetInnerHTML={{
                              __html:
                                reportData.posts[page].volatilityIndexes[0]
                                  .nifty ?? "<p>INDIA VIX</p>",
                            }}
                          />
                        </td>

                        <td
                          style={{
                            textAlign: "end",
                            fontSize: "small",
                            fontWeight: "400",
                            display: "flex",
                            justifyContent: "end",
                          }}
                        >
                          <p
                            style={{
                              padding: "0 12px",
                              backgroundColor: "#d1ffdd",
                              color: "#34a853",
                              fontSize: "10px",
                              cursor: "pointer",
                              borderRadius: "4px",
                              display: "flex",
                              alignItems: "center",
                              width: "70px",
                              height: "auto",
                              paddingTop: "4px",
                              paddingBottom: "4px",
                              marginTop: "0px",
                              justifyContent: "center",
                            }}
                          >
                            <ArrowDownward
                              sx={{ height: "12px", position: "relative" }}
                            />{" "}
                            <span
                              dangerouslySetInnerHTML={{
                                __html:
                                  reportData.posts[page].volatilityIndexes[0]
                                    .per_change ?? "<p>INDIA VIX</p>",
                              }}
                            />
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div
                  style={{
                    border: "1px solid #ededed",
                    borderRadius: "5px",
                    paddingTop: "20px",
                    paddingBottom: "20px",
                    display: "flex",
                    flexDirection: "column",
                    marginTop: "30px",
                    paddingLeft: "8px",
                    paddingRight: "8px",
                  }}
                >
                  <table style={{ borderCollapse: "collapse" }}>
                    <thead
                      style={{
                        borderCollapse: "collapse",
                        borderBottom: "2px solid #ededed",
                      }}
                    >
                      <tr style={{ borderBottom: "2px solid #ededed" }}>
                        <th
                          style={{
                            textAlign: "start",
                            fontSize: "small",
                            fontWeight: "400",
                          }}
                        >
                          Net Flows (Cr)
                        </th>
                        <th
                          style={{
                            textAlign: "center",
                            fontSize: "small",
                            fontWeight: "400",
                          }}
                        >
                          Net FII
                        </th>
                        <th
                          style={{
                            textAlign: "end",
                            fontSize: "small",
                            fontWeight: "400",
                          }}
                        >
                          Net DII
                        </th>
                      </tr>
                    </thead>
                    <tbody
                      style={{
                        borderCollapse: "collapse",
                        borderBottom: "2px solid #ededed",
                      }}
                    >
                      <tr style={{ borderBottom: "2px solid #ededed" }}>
                        <td
                          style={{
                            textAlign: "start",
                            fontSize: "small",
                            fontWeight: "400",
                          }}
                        >
                          <span
                            dangerouslySetInnerHTML={{
                              __html:
                                reportData.posts[page].NetFlowsData[0]
                                  .net_flow ?? "<p>INDIA VIX</p>",
                            }}
                          />
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            color: "#dc3545 ",
                            fontSize: "small",
                            fontWeight: "400",
                          }}
                        >
                          <span
                            dangerouslySetInnerHTML={{
                              __html:
                                reportData.posts[page].NetFlowsData[0]
                                  .net_fll ?? "<p>INDIA VIX</p>",
                            }}
                          />
                        </td>
                        <td
                          style={{
                            textAlign: "end",
                            fontSize: "small",
                            fontWeight: "400",

                            color: "#34a853",
                          }}
                        >
                          <span
                            dangerouslySetInnerHTML={{
                              __html:
                                reportData.posts[page].NetFlowsData[0]
                                  .net_dll ?? "<p>INDIA VIX</p>",
                            }}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div
                  style={{
                    border: "1px solid #ededed",
                    borderRadius: "5px",
                    paddingTop: "20px",
                    paddingBottom: "20px",
                    display: "flex",
                    flexDirection: "column",
                    marginTop: "30px",
                    paddingLeft: "8px",
                    paddingRight: "8px",
                  }}
                >
                  <table style={{ borderCollapse: "collapse" }}>
                    <thead
                      style={{
                        borderCollapse: "collapse",
                        borderBottom: "2px solid #ededed",
                      }}
                    >
                      <tr style={{ borderBottom: "2px solid #ededed" }}>
                        <th
                          style={{
                            textAlign: "start",
                            fontSize: "small",
                            fontWeight: "400",
                          }}
                        >
                          Interest Rates
                        </th>
                        <th
                          style={{
                            textAlign: "center",
                            fontSize: "small",
                            fontWeight: "400",
                          }}
                        >
                          Value
                        </th>
                        <th
                          style={{
                            textAlign: "end",
                            fontSize: "small",
                            fontWeight: "400",
                          }}
                        >
                          % Chg
                        </th>
                      </tr>
                    </thead>
                    <tbody
                      style={{
                        borderCollapse: "collapse",
                        borderBottom: "2px solid #ededed",
                      }}
                    >
                      <tr style={{ borderBottom: "2px solid #ededed" }}>
                        <td
                          style={{
                            textAlign: "start",
                            fontSize: "small",
                            fontWeight: "400",
                          }}
                        >
                          <span
                            dangerouslySetInnerHTML={{
                              __html:
                                reportData.posts[page].InterestRatesData[0]
                                  .interest_rate ?? "<p>No date</p>",
                            }}
                          />
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            color: "#dc3545 !important",
                            fontSize: "small",
                            fontWeight: "400",
                          }}
                        >
                          <span
                            dangerouslySetInnerHTML={{
                              __html:
                                reportData.posts[page].InterestRatesData[0]
                                  .value ?? "<p>No date</p>",
                            }}
                          />
                        </td>
                        <td
                          style={{
                            textAlign: "end",
                            fontSize: "small",
                            fontWeight: "400",

                            display: "flex",
                            justifyContent: "end",
                          }}
                        >
                          <p
                            style={{
                              padding: "0 12px",
                              backgroundColor: "#d1ffdd",
                              color: "#34a853",
                              fontSize: "10px",
                              cursor: "pointer",
                              borderRadius: "4px",
                              display: "flex",
                              alignItems: "center",
                              width: "70px",
                              height: "auto",
                              paddingTop: "4px",
                              paddingBottom: "4px",
                              marginTop: "0px",
                              justifyContent: "center",
                            }}
                          >
                            <ArrowDownward
                              sx={{ height: "12px", position: "relative" }}
                            />{" "}
                            <span
                              dangerouslySetInnerHTML={{
                                __html:
                                  reportData.posts[page].InterestRatesData[0]
                                    .per_change ?? "<p>No date</p>",
                              }}
                            />
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <h6>US and European Markets</h6>
              <div
                style={{
                  fontSize: "10pt",
                  textAlign: "justify",
                  color: "black",
                }}
                dangerouslySetInnerHTML={{
                  __html:
                    reportData.posts[page].morningNote.commodities ??
                    "<p>No outlook available.</p>",
                }}
              />

              <h6>Asian Markets </h6>
              <div
                style={{
                  fontSize: "10pt",
                  textAlign: "justify",
                  color: "black",
                }}
                dangerouslySetInnerHTML={{
                  __html:
                    reportData.posts[page].morningNote.commodities ??
                    "<p>No outlook available.</p>",
                }}
              />
            </Grid>
            <Grid item>
              <h5 style={{ textAlign: "center" }}>DISCLAIMER</h5>
              <p>
                This report is for the exclusive information of our customers.
                Any recommendations, opinions, or suggestions provided are with
                the understanding that readers acting on this information do so
                at their own risk.<br /><br /> The information contained herein is not to be
                construed as an offer to buy or sell securities of any nature.<br /><br />
                ATS and/or its group companies disclaim any responsibility or
                liability arising from the use of such information."
              </p>
            </Grid>
          </Grid>
        </Box>
        <Grid sx={{ top: "10px", position: "relative" }} item>
          <Footer />
        </Grid>
        <Grid item sx={{ top: "10px", position: "relative" }} xs={12} md={6}>
          <FooterContent />
        </Grid>
      </Box>
    </div>
  );
};

export default MorningReportsDetails;
