import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useState } from 'react';
import AlertSearch from './searchstock';
import * as React from 'react';

const style = {
    position: 'relative',
    overflowY: 'auto',
    top: '60%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    height: '500px',
    borderRadius: '15px',
    boxShadow: 14,
    p: 6,
};

export default function AlertModal({ open, onClose, onStockSelect }) {
    // const handleModel = () => {
    //     handleOpen(false);
    // };

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <AlertSearch onStockSelect={onStockSelect} />
            </Box>
        </Modal>
    );
}
