// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.red-text {
  color: #dc3545;
}

.green-text {
  color: #34a853;
}
.redbg-text {
  color: #dc3545 !important;
  background-color: #ffdfe2;
}

.greenbg-text {
  color: #34a853 !important;
  background-color: #d1ffdd;
}
`, "",{"version":3,"sources":["webpack://./src/MarketTrends/Markettrend.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;AAChB;AACA;EACE,yBAAyB;EACzB,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;EACzB,yBAAyB;AAC3B","sourcesContent":[".red-text {\r\n  color: #dc3545;\r\n}\r\n\r\n.green-text {\r\n  color: #34a853;\r\n}\r\n.redbg-text {\r\n  color: #dc3545 !important;\r\n  background-color: #ffdfe2;\r\n}\r\n\r\n.greenbg-text {\r\n  color: #34a853 !important;\r\n  background-color: #d1ffdd;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
