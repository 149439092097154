import React from 'react';
import '../App.css';
import '../Todays Financial News/Politicalnews.css';
import classNames from 'classnames';
import { CalendarViewMonth } from '@mui/icons-material';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { useEarningQuery } from '../redux/Calender';
import { IconButton } from '@mui/material';

const EarningsCalender = () => {
  const { data: EarningCalender } = useEarningQuery();

  const calender = EarningCalender?.data || [];
  // console.log(calender);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        border: '1px solid #cfc2c2',
        borderRadius: '10px',
        //marginTop: '20px',
        textAlign: 'center',
        maxHeight: '85vh',
        overflowY: 'auto',
      }}
    >
      <h5
        style={{
          fontSize: '20px',
          fontWeight: 600,
          letterSpacing: '.3px',
          color: '#131722',
          lineHeight: '1.5px',
          textAlign: 'center',
          //display: 'flex',
          width: '100%',
          //paddingLeft: '20px',
          marginTop: '25px',
        }}
      >
        Earnings Calendar
      </h5>

      <div
        style={{
          width: '100%',
          //paddingLeft: '20px',
          paddingTop: '20px',
        }}
      >
        <p className="paragrey">Based on Popular stocks</p>
        <hr />
      </div>

      <div style={{ width: '100%', height: '', marginTop: '10px' }}>
        <div
        // className={classNames(
        //   'politicalnewscontainer',
        //   'scrollable-container'
        // )}
        >
          {calender.map((cal, index) => {
            const date = new Date(cal.date);
            const formattedDate =
              date.toISOString().replace(/[-:]/g, '').split('.')[0] + 'Z';
            const options = {
              month: 'short',
              day: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
              hour12: true,
            };
            const formattedDisplayDate = date
              .toLocaleDateString('en-US', options)
              .replace(',', '');

            const handleClick = () => {
              const calendarUrl = `https://calendar.google.com/calendar/u/0/r/eventedit?dates=${formattedDate}&text=${encodeURIComponent(
                cal.name
              )} meeting Pinned by ATS Share Brokers`;
              window.open(calendarUrl, '_blank');
            };

            return (
              <div
                style={{ marginTop: '0px' }}
                key={index}
                className="shadowbox"
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                  }}
                >
                  <div style={{ display: 'flex' }}>
                    <div className="calender-box">
                      <p style={{ fontSize: '12px', marginTop: '0px' }}>
                        {date.getDate()}
                      </p>
                      <p style={{ fontSize: '12px', marginTop: '0px' }}>
                        {date.toLocaleString('en-US', { month: 'short' })}
                      </p>
                    </div>
                    <div style={{ marginLeft: '8px' }}>
                      <p className="paradark">{cal.name}</p>
                      <p
                        style={{
                          fontSize: '12px',
                          marginBottom: '0px',
                          paddingTop: '0px',
                          textAlign: 'left',
                        }}
                        className="paragrey"
                      >
                        {formattedDisplayDate}
                      </p>
                    </div>
                  </div>
                  <div>
                    <IconButton
                      sx={{ position: 'relative' }}
                      onClick={handleClick}
                    >
                      <CalendarMonthIcon />
                    </IconButton>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default EarningsCalender;
