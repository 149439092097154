import {
  Box,
  Grid,
  ToggleButton,
  ToggleButtonGroup,
  useMediaQuery,
} from '@mui/material';
import React, { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import FooterContent from '../Footer/FooterContent';
import StockViewer from '../Test';
import Commodity from '../Commodity/Commodity';
import Currency from '../Currency/Currency';
import LandingPage from '../LandingPage';
import { useTheme } from '@mui/material/styles';
import ChartComponent from '../Tes3';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import CompanyPages from './CompanyPages';


const formatChange = (value) => Number(value).toFixed(2);
const CompanyData = () => {
  const [stocks, setStocks] = useState([]);
  const [wsData, setWsData] = useState([]);
  const [socket, setSocket] = useState(null);
  const [alignment, setAlignment] = React.useState('Stocks');
  const tokenID = useSelector((state) => state.token.tokenID);
  const companyName = useSelector((state) => state.token.companyName);
  const theme = useTheme();
  const [companyDatas, setCompanyDatas] = useState('');
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    let socketUrl = '';

    if (window.location.pathname.startsWith('/companyPage/')) {
      console.log('This is the company page');
      socketUrl = 'wss://neuronsoft.in/ws/watchlist/abc/';
    } else {
      socketUrl = 'wss://neuronsoft.in/ws/watchlist/companyInfo/';
    }

    const ws = new WebSocket(socketUrl);

    ws.onopen = () => {
      console.log('WebSocket connection opened.');
      if (tokenID) {
        const message = JSON.stringify({
          event: 'addTicker',
          token: tokenID,
        });
        ws.send(message);
        console.log(`---------------------->>Sent tokenID ${tokenID} to WebSocket.<<--------------------------------`);
      }
    };

    ws.onmessage = (event) => {
      const data = JSON.parse(event.data);
      setCompanyDatas(data);
      setWsData((prevData) => {
        const updatedData = prevData.filter(
          (item) => item.tokenID !== data.tokenID
        );
        updatedData.push(data);
        return updatedData;
      });
    };

    ws.onclose = () => console.log('WebSocket connection closed.');
    ws.onerror = (error) => console.log('WebSocket error:', error);

    setSocket(ws);

    return () => {
      ws.close();
    };
  }, [tokenID]);

  const handleChange = (event, newAlignment) => {
    if (newAlignment !== null) {
      setAlignment(newAlignment);
    }
  };
  // console.log(companyDatas);
  const formattedChange = formatChange(wsData.Change);
  return (
    <div>
      <LandingPage />
      <Box>
        <Box
          sx={{
            width: { xs: '90%', md: '80%' },
            top: '100px',
            position: 'relative',
            mx: 'auto',
            transition: 'all 0.3s ease-out 0s',
          }}
        >
          <ToggleButtonGroup
            value={alignment}
            exclusive
            onChange={handleChange}
            aria-label="Platform"
            sx={{ mt: isSmallScreen ? '10px' : 0, marginBottom: '30px' }}
          >
            <ToggleButton
              sx={{
                borderRadius: '20px',
                paddingTop: '2px',
                paddingLeft: '16px',
                paddingRight: '16px',
                paddingBottom: '2px',
                fontSize: '11px',
                fontWeight: 'normal',
                '&.MuiButtonBase-root': {
                  padding: '8px',
                },
                border: '1px solid rgba(0, 0, 0, 0.12)!important',
                '&.Mui-selected': {
                  color: '#34a853',
                  backgroundColor: '#d1ffdd', // Background color when selected
                  '&:hover': {
                    backgroundColor: '#d1ffdd', // Maintain selected background color on hover
                  },
                },
                '&:not(.Mui-selected):hover': {
                  backgroundColor: '#ffffff', // Hover color when not selected
                  color: '#34a853',
                },
              }}
              value="Stocks"
            >
              Stocks
            </ToggleButton>
{/* 
            <ToggleButton
              sx={{
                borderRadius: '8px',
                paddingTop: '2px',
                paddingLeft: '16px',
                paddingRight: '16px',
                paddingBottom: '2px',
                fontSize: '11px',
                fontWeight: 'normal',
                border: '1px solid rgba(0, 0, 0, 0.12)!important',
                '&.MuiButtonBase-root': {
                  padding: '8px',
                },
                '&.Mui-selected': {
                  color: '#34a853',
                  backgroundColor: '#d1ffdd', // Background color when selected
                  '&:hover': {
                    backgroundColor: '#d1ffdd', // Maintain selected background color on hover
                  },
                },
                '&:not(.Mui-selected):hover': {
                  backgroundColor: '#ffffff', // Hover color when not selected
                  color: '#34a853',
                },
              }}
              value="Commodity"
            >
              Commodity
            </ToggleButton> */}
            <ToggleButton
              sx={{
                borderRadius: '20px',
                paddingTop: '2px',
                paddingLeft: '16px',
                paddingRight: '16px',
                paddingBottom: '2px',
                fontSize: '11px',
                fontWeight: 'normal',
                border: '1px solid rgba(0, 0, 0, 0.12)!important',
                '&.Mui-selected': {
                  color: '#34a853',
                  backgroundColor: '#d1ffdd', // Background color when selected
                  '&:hover': {
                    backgroundColor: '#d1ffdd', // Maintain selected background color on hover
                  },
                },
                '&:not(.Mui-selected):hover': {
                  backgroundColor: '#ffffff', // Hover color when not selected
                  color: '#34a853',
                },
              }}
              value="Currency"
            >
              Currency
            </ToggleButton>
          </ToggleButtonGroup>
          {alignment === 'Stocks' ? (
            <StockViewer />
          )
            // : alignment === 'Commodity' ? (
            //   <Commodity />
            // ) 
            : (
              <Currency />
            )}
          <hr style={{ marginBottom: '20px', marginTop: '30px' }} />
          <p style={{ display: 'flex', fontSize: '12px', color: '#90a4ae' }}>
            <a href="/" style={{ color: '#90a4ae' }}>
              Home
            </a>
            <span>
              {' '}
              <KeyboardArrowRightIcon
                sx={{ fontSize: '17px', color: '#90a4ae' }}
              />
            </span>
            <a href="/" style={{ color: '#90a4ae' }}>
              {' '}
              Stocks
            </a>
            <span>
              {' '}
              <KeyboardArrowRightIcon
                sx={{ fontSize: '17px', color: '#90a4ae' }}
              />{' '}
            </span>
            <a style={{ color: '#90a4ae' }}>{companyName}</a>
          </p>
          <p>{companyName}</p>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              position: 'relative',
            }}
          >
            <div style={{ display: 'flex' }}>
              <p style={{ fontWeight: '700' }}>
                {formatChange(companyDatas.LTP)}
              </p>
              <span
                style={{
                  color: companyDatas.Change >= 0 ? '#34a853' : '#dc3545',
                }}
              >
                {companyDatas.Change >= 0
                  ? `+${formatChange(companyDatas.Change)}`
                  : `${formatChange(companyDatas.Change)}`}
              </span>
              <span
                style={{
                  color:
                    companyDatas.percentageChange >= 0 ? '#34a853' : '#dc3545',
                }}
              >
                {companyDatas.percentageChange >= 0
                  ? `(${companyDatas.percentageChange}%)`
                  : `(${companyDatas.percentageChange}%)`}
              </span>
            </div>
          </div>

          <ChartComponent />
        </Box>
        <CompanyPages />

        <Grid container>
          <Grid item>
            <FooterContent />
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default CompanyData;
