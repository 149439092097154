import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

const TechnicalAnalysis = () => {
  const companyName = useSelector((state) => state.token.companyName);
  useEffect(() => {
    // Check if the script is already added to avoid duplicates
    if (!document.getElementById('tradingview-script')) {
      const script = document.createElement('script');
      script.id = 'tradingview-script'; // Set an ID for the script element
      script.src =
        'https://s3.tradingview.com/external-embedding/embed-widget-technical-analysis.js';
      script.async = true;
      script.innerHTML = JSON.stringify({
        interval: '1m',

        isTransparent: false,
        height: 450,
        symbol: companyName,
        showIntervalTabs: true,
        displayMode: 'single',
        locale: 'en',
        colorTheme: 'light',
      });

      document.getElementById('tradingview-widget').appendChild(script);
    }
  }, [companyName]); // Run only once when the component mounts

  return (
    <div>
      <div
        style={{ width: '350px', height: '500px' }}
        className="tradingview-widget-container"
        id="tradingview-widget"
      >
        <div className="tradingview-widget-container__widget"></div>
        <div className="tradingview-widget-copyright">
          <a
            href="https://www.tradingview.com/"
            rel="noopener nofollow"
            target="_blank"
          >
            <span className="blue-text">
              {companyName}stock analysis by Ats
            </span>
          </a>
        </div>
      </div>
    </div>
  );
};

export default TechnicalAnalysis;
