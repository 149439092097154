// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styled-table tbody tr td {
  font-weight: 500;
  color: #3c4045;
  font-size: 15px;
}
.styled-table tbody tr td p {
  color: black !important ;
}
.styled-table {
  overflow-x: auto;
}
.table-responsive {
  display: block;
  min-width: 200px;
  overflow-x: auto;
  height: auto;
  overflow-y: hidden;
  margin-bottom: 30px;
  border-bottom: 1px solid #b7b1b1;
}
.tables {
  width: 100%;
  color: #212529;
  height: -moz-fit-content;
  height: fit-content;
}
#blank {
  border: 1px solid #b7b1b1;
  line-height: 1.2;
  padding: 5px;
  font-size: 12px;
  text-align: center;
}
.bankTable {
  border: 1px solid #b7b1b1;
  line-height: 1.2;
  width: 100%;
  color: #fff;
  padding: 5px;
  font-size: 12px;
  text-align: center;
}
.bankTable td {
  border: 1px solid #b7b1b1;
  padding: 5px;
  font-size: 12px;
  text-align: center;
}
.bankTable tbody td {
  color: #3c4043;
  font-weight: 500;
}
@media (max-width: 584px) {
  .styled-table {
    width: 90%;
    /* Adjust width as needed */
  }
  .table-responsive {
    border-bottom: none;
  }
}
`, "",{"version":3,"sources":["webpack://./src/UiPages/OtherReports/PostDetails.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,cAAc;EACd,eAAe;AACjB;AACA;EACE,wBAAwB;AAC1B;AACA;EACE,gBAAgB;AAClB;AACA;EACE,cAAc;EACd,gBAAgB;EAChB,gBAAgB;EAChB,YAAY;EACZ,kBAAkB;EAClB,mBAAmB;EACnB,gCAAgC;AAClC;AACA;EACE,WAAW;EACX,cAAc;EACd,wBAAmB;EAAnB,mBAAmB;AACrB;AACA;EACE,yBAAyB;EACzB,gBAAgB;EAChB,YAAY;EACZ,eAAe;EACf,kBAAkB;AACpB;AACA;EACE,yBAAyB;EACzB,gBAAgB;EAChB,WAAW;EACX,WAAW;EACX,YAAY;EACZ,eAAe;EACf,kBAAkB;AACpB;AACA;EACE,yBAAyB;EACzB,YAAY;EACZ,eAAe;EACf,kBAAkB;AACpB;AACA;EACE,cAAc;EACd,gBAAgB;AAClB;AACA;EACE;IACE,UAAU;IACV,2BAA2B;EAC7B;EACA;IACE,mBAAmB;EACrB;AACF","sourcesContent":[".styled-table tbody tr td {\r\n  font-weight: 500;\r\n  color: #3c4045;\r\n  font-size: 15px;\r\n}\r\n.styled-table tbody tr td p {\r\n  color: black !important ;\r\n}\r\n.styled-table {\r\n  overflow-x: auto;\r\n}\r\n.table-responsive {\r\n  display: block;\r\n  min-width: 200px;\r\n  overflow-x: auto;\r\n  height: auto;\r\n  overflow-y: hidden;\r\n  margin-bottom: 30px;\r\n  border-bottom: 1px solid #b7b1b1;\r\n}\r\n.tables {\r\n  width: 100%;\r\n  color: #212529;\r\n  height: fit-content;\r\n}\r\n#blank {\r\n  border: 1px solid #b7b1b1;\r\n  line-height: 1.2;\r\n  padding: 5px;\r\n  font-size: 12px;\r\n  text-align: center;\r\n}\r\n.bankTable {\r\n  border: 1px solid #b7b1b1;\r\n  line-height: 1.2;\r\n  width: 100%;\r\n  color: #fff;\r\n  padding: 5px;\r\n  font-size: 12px;\r\n  text-align: center;\r\n}\r\n.bankTable td {\r\n  border: 1px solid #b7b1b1;\r\n  padding: 5px;\r\n  font-size: 12px;\r\n  text-align: center;\r\n}\r\n.bankTable tbody td {\r\n  color: #3c4043;\r\n  font-weight: 500;\r\n}\r\n@media (max-width: 584px) {\r\n  .styled-table {\r\n    width: 90%;\r\n    /* Adjust width as needed */\r\n  }\r\n  .table-responsive {\r\n    border-bottom: none;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
