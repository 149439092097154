import React, { useEffect } from 'react';

const POA = ({ mobileNumber }) => {
  const Number = mobileNumber || null;

  useEffect(() => {
    const activatePOA = async () => {
      if (Number) {
        try {
          const response = await fetch(`https://kyc.adityatrading.in/activate-poa?token=${Number}`);
          const data = await response.json();

          // Check if the response status is success
          if (data.status === 'success' && data.documentID) {
            // Redirect to the documentID URL
            window.location.href = data.documentID;
          } else {
            console.error('Failed to activate POA/DDPI:', data);
          }
        } catch (error) {
          console.error('Error fetching POA activation link:', error);
        }
      }
    };

    activatePOA();
  }, [Number]);

  return (
    <div>
      {Number ? (
        <p>Redirecting to POA/DDPI activation...</p>
      ) : (
        <p>No mobile number found for the client.</p>
      )}
    </div>
  );
};

export default POA;
