import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { GRIEVANCE_API } from '.././Api';
import { SERVER_URL } from './baseurl';

export const grievanceApi = createApi({
    reducerPath:'grievanceApi',
    baseQuery: fetchBaseQuery({
        baseUrl:SERVER_URL,
    }),
    tagTypes:['Grievance'],
    endpoints:(builder) => ({
        grievance: builder.mutation({
            query:()=>({
                method:'POST',
                url: GRIEVANCE_API,
                headers: {
                    'Content-Type': 'application/json; charset=utf-8',
                  },
            }),
            providesTags: ['Grievance'],
        }),
    }),
});

export const { useGrievanceApiMutation } = grievanceApi;
export default grievanceApi;

