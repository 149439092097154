import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { KYC_API } from "./baseurl";

export const closureApi = createApi({
    reducerPath: 'closureApi',
    baseQuery: fetchBaseQuery({
        baseUrl: KYC_API,//'https://kyc.adityatrading.in/api/', //'http://127.0.0.1:8000/api/',
    }),
    tagTypes: ['accountClosure'],
    endpoints: (builder) => ({
        accountClosure: builder.mutation({
            query: ({ remarks, mobileNumber }) => ({
                method: 'POST',
                url: 'accountClosure', //'account-closure',
                // headers:{
                //     'Authorization': 'Bearer{}' 
                // },
                body: {
                    remarks,
                    mobileNumber,
                }
            })
        }),
    })
});

export const { useAccountClosureMutation } = closureApi;
export default closureApi;
