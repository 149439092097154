import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { MUTUAL_FUNDS_API } from '../Api';
import { SERVER_URL } from './baseurl';

export const mutualfundsApi = createApi({
  reducerPath: 'mutualfundsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: SERVER_URL,
  }),
  tagTypes: ['mutual'],
  endpoints: (builder) => ({
    getMutualfunds: builder.query({
      query: () => ({
        method: 'POST',
        url: MUTUAL_FUNDS_API,
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
        },
      }),
      providesTags: ['mutual'],
    }),
  }),
});

export const { useGetMutualfundsQuery } = mutualfundsApi;

export default mutualfundsApi;
