import React, { useEffect, useState } from 'react';
import { useFreezeStatusQuery } from '../../redux/FreezeUnfreezeStatus';
import { useFreezeUnfreezeMutation } from '../../redux/FreezeUnfreeze';

const Freeze = ({ clientCode }) => {
  const [showModal, setShowModal] = useState(false);
  const [isFrozen, setIsFrozen] = useState(false);
  const [freezeUnfreeze] = useFreezeUnfreezeMutation();
  const { data: statusData, isLoading, error } = useFreezeStatusQuery(clientCode);

  useEffect(() => {
    if (statusData?.status === "Freeze") {
      setIsFrozen(true);
    } else if (statusData?.status === "Unfreeze") {
      setIsFrozen(false);
    }
    console.log("Initial status data -- ", statusData);
  }, [statusData]);

  const confirmFreezeUnfreeze = async () => {
    console.log("isFrozen? --- ", isFrozen);

    const event = isFrozen ? "UNFREEZEACCOUNT" : "FREEZEACCOUNT";
    const choice = isFrozen ? "Unfreeze" : "Freeze";

    console.log("Sending event & choice - ", { event, clientCode, choice });

    try {
      const response = await freezeUnfreeze({
        event,
        clientCode,
        choice,
      }).unwrap();

      console.log("API Response: ", response);

      if (response.status === 'success') {
        setIsFrozen(!isFrozen);
        setShowModal(false);
      } else {
        console.log("API Error Response: ", response);
        alert(`${response.message}`);
        setShowModal(false);
      }
    } catch (error) {
      console.error("Error in catch block:", error);
      alert(`Error: ${error}`);
      setShowModal(false);
    }
  };

  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (error) {
    console.log(error);
    return <p>Error:</p>;
  }

  const handleToggle = () => {
    setShowModal(true);
  };

  const goBack = () => {
    setShowModal(false);
  };

  return (
    <div style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: '70vh',
      backgroundColor: 'white',
    }}>
      <div style={{
        padding: '1.5rem',
        backgroundColor: 'white',
        borderRadius: '0.5rem',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        textAlign: 'center',
        maxWidth: '400px',
      }}>
        <h1 style={{
          fontSize: '1.25rem',
          fontWeight: '600',
          color: '#2d3748',
          marginBottom: '1rem',
        }}>Account Freeze/Unfreeze</h1>
        <p style={{
          color: '#718096',
          marginBottom: '1.5rem',
        }}>If you choose to freeze your account, you will no longer be able to access your trading account. It will be blocked, and no new orders can be placed. All pending orders will be canceled within the next 15 minutes.</p>
        <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '1rem',
          backgroundColor: '#f7fafc',
          borderRadius: '0.5rem',
          boxShadow: 'inset 0 4px 6px rgba(0, 0, 0, 0.1)',
        }}>
          <span style={{ color: '#718096', marginRight: '1rem' }}>Freeze/Unfreeze</span>
          <label style={{
            position: 'relative',
            display: 'inline-flex',
            alignItems: 'center',
            cursor: 'pointer',
          }}>
            <input
              type="checkbox"
              checked={isFrozen}
              onChange={handleToggle}
              style={{ display: 'none' }}
            />
            <div style={{
              width: '2.75rem',
              height: '1.5rem',
              backgroundColor: isFrozen ? '#f56565' : '#e2e8f0',
              borderRadius: '9999px',
              position: 'relative',
              transition: 'background-color 0.2s',
            }}>
              <div style={{
                position: 'absolute',
                top: '2px',
                left: isFrozen ? 'calc(100% - 1.5rem)' : '2px',
                width: '1.25rem',
                height: '1.25rem',
                backgroundColor: 'white',
                borderRadius: '50%',
                border: '1px solid #cbd5e0',
                transition: 'all 0.3s',
              }} />
            </div>
          </label>
        </div>
      </div>

      {/* Confirmation Modal */}
      {showModal && (
        <div style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
          <div style={{
            backgroundColor: 'white',
            padding: '2rem',
            borderRadius: '0.5rem',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            textAlign: 'center',
          }}>
            <h2 style={{ marginBottom: '1rem' }}>You want to freeze your account!</h2>
            <button onClick={confirmFreezeUnfreeze} style={{
              marginRight: '1rem',
              padding: '0.5rem 1rem',
              backgroundColor: '#f56565',
              color: 'white',
              border: 'none',
              borderRadius: '0.25rem',
              cursor: 'pointer',
            }}>
              Sure
            </button>
            <button onClick={goBack} style={{
              padding: '0.5rem 1rem',
              backgroundColor: '#e2e8f0',
              border: 'none',
              borderRadius: '0.25rem',
              cursor: 'pointer',
            }}>
              Go Back
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Freeze;
