// redux/getpost.js
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BASE_URL } from './baseurl'; // Ensure BASE_URL is defined correctly

// Function to get CSRF token
function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
      const cookies = document.cookie.split(';');
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.substring(0, name.length + 1) === (name + '=')) {
          cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
          break;
        }
      }
    }
    return cookieValue;
  }

  const csrfToken = getCookie('csrftoken');
export const postsApi = createApi({
    reducerPath: 'postsApi',
    baseQuery: fetchBaseQuery({
        baseUrl: BASE_URL,//'https://adityatrading.in',
        prepareHeaders: (headers) => {
            headers.set('X-CSRFToken', csrfToken);  // Set the CSRF token
            headers.set('Content-Type', 'application/json');
            return headers;
          },
    }),
    tagTypes: ['Posts'],
    endpoints: (builder) => ({
        fetchPosts: builder.query({
            query: (profileToken) => ({
                url: '/posts/',
                method: 'GET',
            })//'posts/',
        }),
    }),
});

export const { useFetchPostsQuery } = postsApi;

