import React from 'react';
const TabButtons = ({ activeTab, setActiveTab }) => {
    const tabs = [
        { name: 'Client Registration' },
        { name: 'Other Policy' },
        { name: 'Risk Management Policy' },
    ];

    const getSubButtonStyles = (component) => ({
        color: activeTab === component ? '#ffffff' : '#34a853', // White when selected, green when unselected
        backgroundColor: activeTab === component ? '#34a853' : 'transparent', // Green when selected, transparent when unselected
        borderRadius: '50px', // Rounded button
        border: activeTab === component ? '1px solid #34a853' : '1px solid #e5f2ff', // Green border when selected, lighter border when unselected
        padding: '4px 10px', // Smaller padding for sub-button
        fontSize: '10px', // Smaller font for sub-button
        fontWeight: '600',
        marginBottom: '8px', // Slightly smaller margin
        marginLeft: '10px', // Adjusted left margin
        textTransform: 'none',
        cursor: 'pointer',
        transition: 'transform 0.2s ease, background-color 0.3s ease, color 0.3s ease, font-size 0.3s ease', // Smooth transitions
        transform: activeTab === component ? 'scale(1.05)' : 'scale(1)', // Slight scale effect for selected button
    });

    return (
        <div className="flex flex-wrap space-x-2 mb-6">
            {tabs.map((tab) => (
                <button
                    key={tab.name}
                    onClick={() => setActiveTab(tab.name)}
                    style={getSubButtonStyles(tab.name)}
                    className="transition-transform duration-300 ease-in-out hover:scale-105 active:scale-95"
                >
                    {tab.name}
                </button>
            ))}
        </div>
    );
};

export default TabButtons;

