import { CardContent, Grid, Typography } from '@mui/material';
import React, { useEffect, useState, useCallback } from 'react';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import LinearIndeterminate from '../Assets/Load';
import { useSelector } from 'react-redux';
import CompanyPages from '../Company/CompanyPages';

// Component to display individual stock data

const St = () => {
  const [wsData, setWsData] = useState('');
  const tokenId = useSelector((state) => state.token.tokenID);
  console.log(wsData);

  // Initialize WebSocket connection
  useEffect(() => {
    if (!tokenId) return; // Early return if tokenId is not set

    const ws = new WebSocket('wss://neuronsoft.in/ws/watchlist/abc/');

    ws.onopen = () => {
      console.log('WebSocket connection opened.');
      const message = JSON.stringify({
        event: 'addMarketDepth',
        token: tokenId,
      });
      ws.send(message);
      console.log(`Sent tokenID ${tokenId} to WebSocket.`);
    };

    ws.onmessage = (event) => {
      const data = JSON.parse(event.data);
      console.log(data);
      setWsData(data);
    };

    ws.onclose = () => console.log('WebSocket connection closed.');
    ws.onerror = (error) => console.error('WebSocket error:', error);

    return () => {
      ws.close();
    };
  }, [tokenId]); // Depend on tokenId for WebSocket initialization

  // Fetch data on component mount

  const formatChange = (value) => {
    return Number(value).toFixed(2);
  };

  return (
    <div>
      <CompanyPages />
    </div>
  );
};

export default St;
