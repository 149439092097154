import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Market_Trends_Stocks_API } from '../Api';
import { SERVER_URL } from './baseurl';

export const markettrendsApi = createApi({
  reducerPath: 'trendsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: SERVER_URL,
  }),
  tagTypes: ['Trends'],
  endpoints: (builder) => ({
    getTrends: builder.query({
      query: () => ({
        method: 'POST',
        url: Market_Trends_Stocks_API,
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
        },
      }),
      providesTags: ['Trends'],
    }),
  }),
});

export const { useGetTrendsQuery } = markettrendsApi;

export default markettrendsApi;
