import { Button, Modal, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react';
import { useOldotpVerifyMutation } from '../../redux/mobileUpdate';
import UpdateMobileNumber from './UpdateMobileNumber';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: '300px', // Minimum width for smaller devices
  maxWidth: '90%', // Maximum width to ensure responsiveness
  padding: '20px', // Add padding for better spacing inside the modal
  bgcolor: '#fff', // White background color
  borderRadius: '8px', // Rounded corners for a smoother look
  boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.2)', // Subtle shadow effect
  outline: 'none', // Remove the default outline on focus
};

const OtpModel = ({ open, setOtpopen, sentOtp }) => {
  const [otp, setOtp] = useState();
  const [updateMobileNum, setUpdateMobileNum] = useState(false);
  const [verify] = useOldotpVerifyMutation();
  const handleClose = () => {
    setOtpopen(false);
  };
  const verifyOtp = async () => {
    try {
      const response = await verify({
        otp,
        sentOtp,
        event: 'VERIFY_OTP_MOBILE_OLD',
      });
      if (response.data.status === 'success') {
        alert(response.data.message);
        setOtp(false);
        setOtpopen(false);
        setUpdateMobileNum(true);
      } else {
        alert(response.data.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          Validate Otp
          <hr />
          <Box sx={{ padding: '10px' }}>
            <input
              type="text"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              placeholder="Enter the OTP"
            />
          </Box>
          <Button variant="contained" color="primary" onClick={verifyOtp}>
            Verify OTP
          </Button>
        </Box>
      </Modal>
      <UpdateMobileNumber
        open={updateMobileNum}
        setUpdateMobileNum={setUpdateMobileNum}
      />
    </>
  );
};

export default OtpModel;
