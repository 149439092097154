import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { CREATE_ALERT, UPCOMING_API } from '.././Api';
import { LOCAL_URL, SERVER_URL } from './baseurl';

export const CreateAlertConnect = createApi({
  reducerPath: 'alertCreationApi',
  baseQuery: fetchBaseQuery({
    baseUrl: SERVER_URL,
  }),
  tagTypes: ['Create Alert'],
  endpoints: (builder) => ({
    createAlert: builder.mutation({
      query: (
        {
          exchange,
          frequency,
          segment,
          ticker_code,
          tokenID,
          triggerName,
          type,
          value,
        }
      ) => ({
        url: '/api/create-alert/',
        method: 'POST',
        body: {
          exchange,
          frequency,
          segment,
          ticker_code,
          tokenID,
          triggerName,
          type,
          value,
        },
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
        },
      }),
      //invalidatesTags: ['create_alert'],
    }),
  }),
});

export const { useCreateAlertMutation } = CreateAlertConnect;

export default CreateAlertConnect;
