import React, { useEffect, useRef, useState } from 'react';
import { createChart } from 'lightweight-charts';
import { useCreateChartMutation } from './redux/chart';
import { useSelector } from 'react-redux';
import ChartButton from './Buttons/ChartButton';

const ChartComponent = () => {
  const chartContainerRef = useRef(null);
  const [chart, setChart] = useState(null);
  const [areaSeries, setAreaSeries] = useState(null);
  const companyName = useSelector((state) => state.token.companyName);
  const [fetchChart] = useCreateChartMutation();
  const [chartData, setChartData] = useState([]);

  const parseDate = (dateString) => {
    console.log('Parsing date string:', dateString);

    const formats = [
      {
        regex: /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2} [AP]M$/,
        parse: (str) => new Date(str).getTime() / 1000,
      },
      {
        regex: /^\d{2}-\d{2}-\d{4}, \d{2}:\d{2}:\d{2} [AP]M$/,
        parse: (str) => {
          const [day, month, yearTimePart] = str.split('-');
          const [year, timePart] = yearTimePart.split(', ');
          const formattedDateString = `${year}-${month}-${day} ${timePart}`;
          return new Date(formattedDateString).getTime() / 1000;
        },
      },
      {
        regex: /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z$/,
        parse: (str) => new Date(str).getTime() / 1000,
      },
    ];

    for (const { regex, parse } of formats) {
      if (regex.test(dateString)) {
        return parse(dateString);
      }
    }

    console.error('Unsupported date format:', dateString);
    return NaN;
  };
  const formatData = (data) => {
    if (!Array.isArray(data)) {
      console.error('Data is not an array:', data);
      return [];
    }

    const mappedData = data.map((item) => {
      const time = parseDate(item.Date);
      const value = item.Close;
      return {
        time,
        value,
      };
    });

    console.log('Mapped data:', mappedData);

    const filteredData = mappedData.filter(
      (item) => !isNaN(item.time) && item.value !== null
    );
    console.log('Filtered data:', filteredData);

    // Remove duplicates by merging values with the same timestamp
    const uniqueData = [];
    const dataMap = new Map();

    for (const item of filteredData) {
      if (dataMap.has(item.time)) {
        // Optionally, handle merging here if needed
        console.warn('Duplicate time found, merging values:', item.time);
      } else {
        dataMap.set(item.time, item);
      }
    }

    uniqueData.push(...dataMap.values());

    console.log('Unique data:', uniqueData);

    // Ensure data is in ascending order
    const sortedData = uniqueData.sort((a, b) => a.time - b.time);
    console.log('Sorted data:', sortedData);

    // Log if data is not in ascending order
    for (let i = 1; i < sortedData.length; i++) {
      if (sortedData[i].time <= sortedData[i - 1].time) {
        console.error(
          `Data is not in ascending order at index ${i}: ${
            sortedData[i].time
          } <= ${sortedData[i - 1].time}`
        );
      }
    }

    return sortedData;
  };

  useEffect(() => {
    if (!chartContainerRef.current) return;

    const chartOptions = {
      layout: {
        textColor: 'black',
        background: { type: 'solid', color: 'white' },
      },
    };

    const newChart = createChart(chartContainerRef.current, chartOptions);
    const newAreaSeries = newChart.addAreaSeries({
      lineColor: '#34a853',
      topColor: '#d1ffdd',
      bottomColor: '#d1ffdd',
    });

    setChart(newChart);
    setAreaSeries(newAreaSeries);

    return () => {
      newChart.remove();
    };
  }, []);

  useEffect(() => {
    if (areaSeries) {
      const formattedData = formatData(chartData);
      console.log('Data before setting on chart:', formattedData);
      if (formattedData.length > 0) {
        areaSeries.setData(formattedData);
        chart.timeScale().fitContent();
      } else {
        areaSeries.setData([]);
        console.log('Clearing chart data as no data was fetched.');
      }
    }
  }, [areaSeries, chartData, chart]);

  const updateData = async (timeRange) => {
    try {
      console.log('Fetching data for:', timeRange);
      const { data: fetched } = await fetchChart({
        ticker: companyName,
        period: timeRange,
      });

      console.log('Raw fetched data:', fetched);

      if (fetched?.tickerSearch) {
        console.log('Fetched data:', fetched.tickerSearch);
        setChartData(fetched.tickerSearch);
      } else {
        console.warn('No data fetched for the selected range');
        setChartData([]);
      }
    } catch (error) {
      console.error('Failed to update data:', error);
    }
  };

  useEffect(() => {
    updateData('1d');
  }, []);

  return (
    <div
      style={{
        width: '100%',
        height: 'auto',
        overflow: 'hidden',
        position: 'relative',
      }}
    >
      <div ref={chartContainerRef} style={{ height: '300px' }} />
      <div>
        <ChartButton onClick={() => updateData('1d')}>1d</ChartButton>
        <ChartButton onClick={() => updateData('5d')}>5d</ChartButton>
        <ChartButton onClick={() => updateData('1mo')}>1mo</ChartButton>
        <ChartButton onClick={() => updateData('6mo')}>6mo</ChartButton>
        <ChartButton onClick={() => updateData('ytd')}>Ytd</ChartButton>
        <ChartButton onClick={() => updateData('1y')}>1y</ChartButton>
        <ChartButton onClick={() => updateData('5y')}>5y</ChartButton>
        <ChartButton onClick={() => updateData('max')}>Max</ChartButton>
      </div>
    </div>
  );
};

export default ChartComponent;
