import { Grid, IconButton } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { useUserprofileQuery } from '../../redux/Userprofile';
import ModeEditIcon from '@mui/icons-material/ModeEdit';

const FATCA = () => {
 
  const [open, setOpen] = useState(false);
  const [emailOpen, setEmailOpen] = useState(false);
  const [localProfile, setLocalProfile] = useState(null);
  const handleOpen = () => setOpen(true);
  const handleEmailOpen = () => setEmailOpen(true);
  const handleEmailCLose = () => setEmailOpen(false);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    const storedUserProfile = JSON.parse(localStorage.getItem('usersProfileData'));
    if (storedUserProfile) {
      console.log("Loaded profile from localStorage:", storedUserProfile);
      setLocalProfile(storedUserProfile);
    }
  }, []);

  const Profile = localProfile?.fatca || null;
  console.log('hi profile-', localProfile?.fatca);

  return (
    <div style={{ position: 'relative', padding:'15px' }}>
      <Grid container>
        {Profile ? (
          <>
            <Grid item xs={12} md={3}>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <h6>Annual Income</h6>
                <p style={{ fontSize: '15px' }}>{Profile.annual_income || 'None'}</p>
              </Box>
            </Grid>
            <Grid item xs={12} md={3}>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <h6>Nature of Buisness</h6>
                <p style={{ fontSize: '15px' }}>{Profile.nature_of_business || 'None'} </p>
              </Box>
            </Grid>
            <Grid item xs={12} md={3}>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <h6>Client Politically Exposed</h6>
                <p style={{ fontSize: '15px' }}>{Profile.client_politically_exposed || 'None'}</p>
              </Box>
            </Grid>
            <Grid item xs={12} md={3}>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <h6>Experience in Trading</h6>
                <p style={{ fontSize: '15px' }}>{Profile.experience_in_trading || 'NO'}</p>
              </Box>
            </Grid>
            <Grid item xs={12} md={3}>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <h6>Source of Wealth</h6>
                <p style={{ fontSize: '15px' }}>{Profile.source_of_wealth || 'Unidentified'}</p>
              </Box>
            </Grid>
            <Grid item xs={12} md={3}>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <h6>Place of Birth</h6>
                <p style={{ fontSize: '15px' }}>{Profile.place_of_birth || 'None'}</p>
              </Box>
            </Grid>
            <Grid item xs={12} md={3}>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <h6>Country of Birth</h6>
                <p style={{ fontSize: '15px' }}>{Profile.country_of_birth || 'None'}</p>
              </Box>
            </Grid>
            <Grid item xs={12} md={3}>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <h6>Tax Residency</h6>
                <p style={{ fontSize: '15px' }}>{Profile.tax_residency || 'None'}</p>
              </Box>
            </Grid>
            <Grid item xs={12} md={3}>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <h6>Tax Status</h6>
                <p style={{ fontSize: '15px' }}>{Profile.tax_residency_status || 'None'}</p>
              </Box>
            </Grid>
          </>
        ) : (
          <p>Profile data is unavailable.</p>
        )}
      </Grid>
    </div>
  );
};

export default FATCA;
