// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .tab-content{

} */
.fade {
  transition: opacity 0.15s linear;
}
.tablebrokerage {
  width: 100%;
  padding: 5px;
}
.tablebrokerage tr td {
  border-top: 0px solid #dee2e6;
  padding: 3px 2px;
}
.textleft {
  text-align: left;
}
.roboto {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.textright {
  text-align: right;
}
.tab-content {
  transition: opacity 0.15s linear;
}
`, "",{"version":3,"sources":["webpack://./src/Calculator/Intra.css"],"names":[],"mappings":"AAAA;;GAEG;AACH;EACE,gCAAgC;AAClC;AACA;EACE,WAAW;EACX,YAAY;AACd;AACA;EACE,6BAA6B;EAC7B,gBAAgB;AAClB;AACA;EACE,gBAAgB;AAClB;AACA;EACE;wEACsE;AACxE;AACA;EACE,iBAAiB;AACnB;AACA;EACE,gCAAgC;AAClC","sourcesContent":["/* .tab-content{\r\n\r\n} */\r\n.fade {\r\n  transition: opacity 0.15s linear;\r\n}\r\n.tablebrokerage {\r\n  width: 100%;\r\n  padding: 5px;\r\n}\r\n.tablebrokerage tr td {\r\n  border-top: 0px solid #dee2e6;\r\n  padding: 3px 2px;\r\n}\r\n.textleft {\r\n  text-align: left;\r\n}\r\n.roboto {\r\n  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,\r\n    Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;\r\n}\r\n.textright {\r\n  text-align: right;\r\n}\r\n.tab-content {\r\n  transition: opacity 0.15s linear;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
