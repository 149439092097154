import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { SYNAPSYS_URL } from './baseurl';

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: fetchBaseQuery({ baseUrl: SYNAPSYS_URL, }),
  endpoints: (builder) => ({
    getToken: builder.mutation({
      query: () => ({
        url: 'token/',
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body:({
          username: 'atsadmin',
          password: '@ts@dmin123',
        }),
      }),
    }),

    getUserData: builder.query({
      query: ({ phoneNumber, profileToken }) => ({
        url: `loginkyc?clientNumber=${phoneNumber}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${profileToken}`
        }
      }),
    }),
  }),
});

export const { useGetTokenMutation, useGetUserDataQuery } = userApi;
