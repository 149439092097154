import { configureStore } from '@reduxjs/toolkit';
import {
  stockAPI,
  AnalystStockAPI,
  markettrendsApi,
  mutualfundsApi,
  ActivefundsAPI,
  FinancialNewsApi,
  earningsAPI,
  researchReportApi,
  circularsAPI,
  searchApi,
  otpApi,
  chartApi,
  annualReportsApi,
  companynewsApi,
  technicalnewsApi,
  profitandlossApi,
  portfolioApi,
  userProfileApi,
  mobilemailApi,
  emailApi,
  userProfileBankApi,
  pledgeApi,
} from '../redux/index';
import tokenReducer from '../reduxslices/tokenSlices';
import localStorageMiddleware from '../reduxslices/Localstorage';
import { userApi } from '../redux/UsersPersonalData';
import { clientIncomeApi } from '../redux/ClientIncome';
import { AddNomineeApi } from '../redux/NomineeAdd';
import freezeStatusApi from '../redux/FreezeUnfreezeStatus';
import freezeUnfreezeApi from '../redux/FreezeUnfreeze';
import closureApi from '../redux/Closure';
import { SegmentApi } from '../redux/SegmentAddition';
import CreateAlertConnect from '../redux/Alert';
import { postsApi } from '../redux/getpost';
import grievanceApi from '../redux/Grievance';

export const store = configureStore({
  reducer: {
    [stockAPI.reducerPath]: stockAPI.reducer,
    [AnalystStockAPI.reducerPath]: AnalystStockAPI.reducer,
    [markettrendsApi.reducerPath]: markettrendsApi.reducer,
    [mutualfundsApi.reducerPath]: mutualfundsApi.reducer,
    [ActivefundsAPI.reducerPath]: ActivefundsAPI.reducer,
    [FinancialNewsApi.reducerPath]: FinancialNewsApi.reducer,
    [earningsAPI.reducerPath]: earningsAPI.reducer,
    [researchReportApi.reducerPath]: researchReportApi.reducer,
    [circularsAPI.reducerPath]: circularsAPI.reducer,
    [searchApi.reducerPath]: searchApi.reducer,
    [otpApi.reducerPath]: otpApi.reducer,
    [chartApi.reducerPath]: chartApi.reducer,
    [annualReportsApi.reducerPath]: annualReportsApi.reducer,
    [companynewsApi.reducerPath]: companynewsApi.reducer,
    [technicalnewsApi.reducerPath]: technicalnewsApi.reducer,
    [profitandlossApi.reducerPath]: profitandlossApi.reducer,
    [portfolioApi.reducerPath]: portfolioApi.reducer,
    [userProfileApi.reducerPath]: userProfileApi.reducer,
    [mobilemailApi.reducerPath]: mobilemailApi.reducer,
    [emailApi.reducerPath]: emailApi.reducer,
    [userProfileBankApi.reducerPath]: userProfileBankApi.reducer,
    [pledgeApi.reducerPath]: pledgeApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [clientIncomeApi.reducerPath]: clientIncomeApi.reducer,
    [AddNomineeApi.reducerPath]: AddNomineeApi.reducer,
    [freezeStatusApi.reducerPath]: freezeStatusApi.reducer,
    [freezeUnfreezeApi.reducerPath]: freezeUnfreezeApi.reducer,
    [closureApi.reducerPath]: closureApi.reducer,
    [SegmentApi.reducerPath]: SegmentApi.reducer,
    [CreateAlertConnect.reducerPath]: CreateAlertConnect.reducer,
    [postsApi.reducerPath]: postsApi.reducer,
    [grievanceApi.reducerPath]: postsApi.reducer,
    token: tokenReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(stockAPI.middleware)
      .concat(AnalystStockAPI.middleware)
      .concat(mutualfundsApi.middleware)
      .concat(markettrendsApi.middleware)
      .concat(ActivefundsAPI.middleware)
      .concat(FinancialNewsApi.middleware)
      .concat(earningsAPI.middleware)
      .concat(researchReportApi.middleware)
      .concat(circularsAPI.middleware)
      .concat(searchApi.middleware)
      .concat(otpApi.middleware)
      .concat(chartApi.middleware)
      .concat(localStorageMiddleware)
      .concat(annualReportsApi.middleware)
      .concat(companynewsApi.middleware)
      .concat(technicalnewsApi.middleware)
      .concat(profitandlossApi.middleware)
      .concat(portfolioApi.middleware)
      .concat(userProfileApi.middleware)
      .concat(mobilemailApi.middleware)
      .concat(emailApi.middleware)
      .concat(userProfileBankApi.middleware)
      .concat(pledgeApi.middleware)
      .concat(userApi.middleware)
      .concat(clientIncomeApi.middleware)
      .concat(AddNomineeApi.middleware)
      .concat(freezeStatusApi.middleware)
      .concat(freezeUnfreezeApi.middleware)
      .concat(closureApi.middleware)
      .concat(SegmentApi.middleware)
      .concat(CreateAlertConnect.middleware)
      .concat(postsApi.middleware)
      .concat(grievanceApi.middleware)
});
