import React, { useEffect, useState } from 'react';
import FooterContent from '../Footer/FooterContent';
import './Signin.css';
import {
  Button,
  Grid,
  Typography,
  useMediaQuery,
  Container,
} from '@mui/material';
import LandingPage from '../LandingPage';
import { useTheme } from '@mui/material/styles';
import { useCheckOTPMutation, useGenerateOtpMutation } from '../redux/otp';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router';

const UpdateInfo = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  // State for OTP inputs
  const [otp, setOtp] = useState({
    first: '',
    second: '',
    third: '',
    fourth: '',
    fifth: '',
    sixth: '',
  });
  const [phoneNumber, setPhoneNumber] = useState('');
  const [checkOTP, { isLoading, isSuccess, data, error }] =
    useCheckOTPMutation();
  useEffect(() => {
    
    // Retrieve phone number from localStorage when component mounts
    const phone = localStorage.getItem('sessionPhoneKEY'); 
    if (phone) {
      setPhoneNumber(phone);
    }
  }, []);
  
  // Handle OTP input change
  const handleOtpChange = (e) => {
    const { name, value } = e.target;
    setOtp({ ...otp, [name]: value });
  };

const verifyOtp = async () => {
  const completeOtp = Object.values(otp).join('');
  console.log('OTP to verify:', completeOtp);
  try {
    const result = await checkOTP({
      phone_number: phoneNumber,
      otp: completeOtp,
    }).unwrap();
    if (result.Status === 'Success') {
      alert('Phone number verified successfully');
      localStorage.setItem('verifiedData', JSON.stringify(result));
      console.log("Local Storage Data ------ ",localStorage.getItem(result));
      
      localStorage.setItem('isLoggedIn', 'true');

      localStorage.removeItem('sessionPhoneKEY');
      navigate('/');
    } else {
      alert('OTP verification failed');
    }
  } catch (err) {
    console.error('Failed to verify OTP:', err);
    alert('An error occurred while verifying OTP');
  }
};

  // const verifyOtp = async () => {
  //   const completeOtp = Object.values(otp).join('');
  //   // Replace this with your actual OTP verification API call
  //   console.log('OTP to verify:', completeOtp);
  //   try {
  //     const result = await checkOTP({
  //       phone_number: phoneNumber,
  //       otp: completeOtp,
  //     }).unwrap();
  //     if (result.Status === 'Success') {
  //       alert('Phone number verified successfully');
  //       localStorage.setItem('verifiedData', JSON.stringify(result));
  //       // Perform further actions like navigating to a different page
  //       localStorage.removeItem('sessionPhoneKEY');
  //       navigate('/');
  //     } else {
  //       alert('OTP verification failed');
  //     }
  //   } catch (err) {
  //     console.error('Failed to verify OTP:', err);
  //     alert('An error occurred while verifying OTP');
  //   }
  // };

  return (
    <div>
      <LandingPage />
      <div style={{ position: 'relative', top: '102px', height: 'auto' }}>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          spacing={3}
        >
          <Grid item xs={12} lg={6} sm={8} md={6}>
            <img
              src="https://adityatrading.in/static/images/banners/trade-desktop.png"
              alt="Banner Image"
              style={{ marginTop: '20px', width: '100%' }}
            />
          </Grid>
          <Grid
            sx={{ minHeight: '300px', lineHeight: '40px' }}
            item
            xs={12}
            lg={6}
            sm={8}
            md={6}
          >
            <h5>Please Update Your Profile</h5>
            <p>You Just one step away from us </p>
            <div
              style={{
                justifyContent: 'space-between',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <label>Name</label>
              <input
                className="fcs"
                style={{
                  height: '34px',
                  borderRadius: '6px',
                  border: '1px solid #e0e0e0',
                }}
                placeholder="Enter Your Name"
                type="text"
              />
              <label>Email</label>
              <input
                className="fcs"
                style={{
                  height: '34px',
                  borderRadius: '6px',
                  border: '1px solid #e0e0e0',
                }}
                placeholder="Enter your Email"
                type="text"
              />
            </div>
            <div style={{ width: '100%', marginTop: '20px' }}>
              <Button
                onClick={verifyOtp}
                sx={{
                  color: '#e5f2ff!important',
                  border: '1px solid #e5f2ff!important',
                  paddingTop: '7px',
                  paddingLeft: '8px',
                  paddingRight: '8px',
                  paddingBottom: '7px',
                  fontSize: '14px',
                  fontWeight: 500,
                  backgroundColor: '#34a853 ',
                  width: '100%',
                  textTransform: 'none',
                  '&:hover': {
                    backgroundColor: '#34a853',
                  },
                }}
              >
                Verify OTP
              </Button>
            </div>
          </Grid>
        </Grid>
      </div>
      <FooterContent />
    </div>
  );
};

export default UpdateInfo;
