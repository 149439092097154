// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .table-container table {
    width: 100%;
    overflow-x: auto;
    color: #212529;
    border-collapse: collapse;
    display: block;
  }
  
  .table-container th {
    width: 20%;
    font-weight: 400;
    color: #707070;
    border-bottom: 2px solid #dee2e6;
    border-top: 1px solid #dee2e6;
  }
   */
.kyc tbody tr:nth-child(odd) td {
  background-color: #f8f9fa;
}

.kyc ul li a {
  color: #374948;
  font-size: 14px;
}
.kyc tbody td:first-child {
  vertical-align: top;
  padding-top: 20px;
  padding-left: 10px;
}
.kyc tbody ul li {
  color: #777d74 !important;
}
`, "",{"version":3,"sources":["webpack://./src/UiPages/InvestorCorner/kyc.css"],"names":[],"mappings":"AAAA;;;;;;;;;;;;;;;IAeI;AACJ;EACE,yBAAyB;AAC3B;;AAEA;EACE,cAAc;EACd,eAAe;AACjB;AACA;EACE,mBAAmB;EACnB,iBAAiB;EACjB,kBAAkB;AACpB;AACA;EACE,yBAAyB;AAC3B","sourcesContent":["/* .table-container table {\r\n    width: 100%;\r\n    overflow-x: auto;\r\n    color: #212529;\r\n    border-collapse: collapse;\r\n    display: block;\r\n  }\r\n  \r\n  .table-container th {\r\n    width: 20%;\r\n    font-weight: 400;\r\n    color: #707070;\r\n    border-bottom: 2px solid #dee2e6;\r\n    border-top: 1px solid #dee2e6;\r\n  }\r\n   */\r\n.kyc tbody tr:nth-child(odd) td {\r\n  background-color: #f8f9fa;\r\n}\r\n\r\n.kyc ul li a {\r\n  color: #374948;\r\n  font-size: 14px;\r\n}\r\n.kyc tbody td:first-child {\r\n  vertical-align: top;\r\n  padding-top: 20px;\r\n  padding-left: 10px;\r\n}\r\n.kyc tbody ul li {\r\n  color: #777d74 !important;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
