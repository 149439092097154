import React, { useState } from "react";

function ExpandableSection({ title, heading, content }) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleSection = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="w-full my-4 border border-gray-300 rounded-lg shadow-lg">
      {/* Header */}
      <div
        className="cursor-pointer bg-gray-200 px-4 py-3 flex justify-between items-center"
        onClick={toggleSection}
      >
        <h2 className="text-lg font-bold text-gray-700">{title}</h2>
        <span className="text-gray-600">{isOpen ? "▲" : "▼"}</span>
      </div>

      {/* Expandable Content */}
      {isOpen && (
        <div className="px-4 py-3 bg-white">
          <h2 className="text-sm font-bold text-gray-600">{heading}</h2>
          <p className="text-sm text-gray-600 text-justify">{content}</p>
        </div>
      )}
    </div>
  );
}

export default ExpandableSection;
