import React, { useEffect, useState } from 'react';
import { usePostSegmentMutation } from '../../redux/SegmentAddition';

const Segment = () => {
  const [clientCode, setClientCode] = useState('N/A');
  const [clientName, setClientName] = useState('N/A');
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [prePaid, setPrePaid] = useState();
  const [prePaidAmount, setPrePaidAmount] = useState();
  const [nseSegment, setNseSegment] = useState();
  const [bseSegment, setBseSegment] = useState();
  const [foSegment, setFoSegment] = useState();
  const [cashSegment, setCashSegment] = useState();
  const [currencySegment, setCurrencySegment] = useState();
  const [postClientSegment, { data, isLoading, error }] = usePostSegmentMutation();

  // Load profile data from localStorage
  useEffect(() => {
    const storedUserProfile = JSON.parse(localStorage.getItem('usersProfileData'));
    if (storedUserProfile) {
      setClientCode(storedUserProfile.user.client_code);
      setClientName(storedUserProfile.user.client_name);
      setPhoneNumber(storedUserProfile.user.mobile);
    }
    console.log("client code here", clientCode);
    console.log("client name here", clientName);
    console.log("client phoneNumber", phoneNumber);
  }, []);

  const handlePrePaidChange = (event) => {
    setPrePaid(event.target.value);
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = {
      client_code: clientCode,
      client_name: clientName,
      mobile_no: phoneNumber,
      prepaid_brokerage: prePaid,
      prepaid_brokerage_amt: prePaidAmount ? prePaidAmount : '0',
      nse_segment: nseSegment ? true : false,
      bse_segment: bseSegment ? true : false,
      cash_segment: cashSegment ? true : false,
      fno_segment: foSegment ? true : false,
      currency_segment: currencySegment ? true : false,
    };
    console.log("this is the  payload we are parsing -- ", payload);

    try {
      const response = await postClientSegment(
        {
          client_code: clientCode,
          client_name: clientName,
          mobile_no: phoneNumber,
          prepaid_brokerage: prePaid,
          prepaid_brokerage_amt: prePaidAmount ? prePaidAmount : '0',
          nse_segment: nseSegment ? true : false,
          bse_segment: bseSegment ? true : false,
          cash_segment: cashSegment ? true : false,
          fno_segment: foSegment ? true : false,
          currency_segment: currencySegment ? true : false,
        }
      ).unwrap();
      if (response.status === 'success') {
        alert('Segment added successfully!');
        console.log(response.message);
        console.log("This is the data ---> ");
      } else {
        alert(response.message);
        console.log(response.message);
        console.log("This is the data ---> ");
      }
    } catch (error) {
      console.error('Failed to add segment:', error);
      alert('Failed to add segment, please try again.');
      console.log(error);
      console.log("this is the data ---> ");
    }
  };

  return (
    <div style={{ backgroundColor: 'white', padding: '2rem', borderRadius: '0.5rem', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', width: '100%', maxWidth: '768px', margin: '0 auto' }}>
      <h2 style={{ textAlign: 'center', fontSize: '24px', fontWeight: '600', marginBottom: '16px' }}>Manage Segments</h2>
      <p style={{ textAlign: 'center', color: '#4a5568', marginBottom: '32px' }}>Activate or deactivate trading segments on your account.</p>
      <form onSubmit={handleSubmit}>
        {/* Client Code */}
        <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '16px' }}>
          <label>Client Code</label>
          <input type="text" value={clientCode} readOnly />
        </div>
        {/* Client Name */}
        <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '16px' }}>
          <label>Client Name</label>
          <input type="text" value={clientName} readOnly />
        </div>
        {/* Prepaid Brokerage */}
        <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '16px' }}>
          <label>Prepaid Brokerage</label>
          <select value={prePaid} onChange={handlePrePaidChange}>
            <option value="">--</option>
            <option value="Yes">YES</option>
            <option value="No">NO</option>
          </select>
        </div>
        {/* Prepaid Brokerage Amount */}
        {prePaid === 'Yes' && (
          <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '16px' }}>
            <label>Prepaid Brokerage Amount</label>
            <input type="number" value={prePaidAmount} onChange={(e) => setPrePaidAmount(e.target.value)} placeholder="Enter amount" />
          </div>
        )}
        {/* Segments */}
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '16px' }}>
          <div>
            <input type="checkbox" checked={nseSegment} onChange={(e) => setNseSegment(e.target.checked)} />
            <label>NSE</label>
          </div>
          <div>
            <input type="checkbox" checked={foSegment} onChange={(e) => setFoSegment(e.target.checked)} />
            <label>F&O</label>
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '16px' }}>
          <div>
            <input type="checkbox" checked={bseSegment} onChange={(e) => setBseSegment(e.target.checked)} />
            <label>BSE</label>
          </div>
          <div>
            <input type="checkbox" checked={currencySegment} onChange={(e) => setCurrencySegment(e.target.checked)} />
            <label>Currency</label>
          </div>
        </div>
        <div>
          <input type="checkbox" checked={cashSegment} onChange={(e) => setCashSegment(e.target.checked)} />
          <label>Cash</label>
        </div>
        {/* Submit Button */}
        <div style={{ textAlign: 'center', marginTop: '32px' }}>
          <button type="submit" style={{ backgroundColor: '#34a853', color: 'white', padding: '12px 24px', borderRadius: '0.375rem', cursor: 'pointer' }}>
            Add Segment
          </button>
        </div>
      </form>
    </div>
  );
};

export default Segment;
