import { Button, Modal, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react';
import { useNewNumberOtpSendMutation } from '../../redux/mobileUpdate';
import NewMobileModel from './NewMobileModel';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: '300px', // Minimum width for smaller devices
  maxWidth: '90%', // Maximum width to ensure responsiveness
  padding: '20px', // Add padding for better spacing inside the modal
  bgcolor: '#fff', // White background color
  borderRadius: '8px', // Rounded corners for a smoother look
  boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.2)', // Subtle shadow effect
  outline: 'none', // Remove the default outline on focus
};
const UpdateMobileNumber = ({ open, setUpdateMobileNum }) => {
  const [mobileNumber, setMobileNumber] = useState();
  const [openNew, setOpenNew] = useState(false);
  const [sentOtp, setSentOtp] = useState();
  const [verifyNewNumber] = useNewNumberOtpSendMutation();
  const handleClose = () => {
    setUpdateMobileNum(false);
  };

  const verifyOtp = async () => {
    try {
      const response = await verifyNewNumber({
        mobileNumber,
        event: 'SEND_OTP_MOBILE_NEW',
      });
      console.log(response);
      if (response.data.status === 'success') {
        alert(response.data.message);
        setSentOtp(response.data.otp);

        setUpdateMobileNum(false);
        setOpenNew(true);
      } else {
        alert(response.data.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          Update Mobile Number Last
          <hr />
          <Box sx={{ padding: '10px' }}>
            <input
              type="text"
              value={mobileNumber}
              onChange={(e) => setMobileNumber(e.target.value)}
              placeholder="Enter the OTP"
            />
          </Box>
          <Button variant="contained" color="primary" onClick={verifyOtp}>
            Send OTP
          </Button>
        </Box>
      </Modal>
      <NewMobileModel
        open={openNew}
        setOpenNew={setOpenNew}
        sentOtp={sentOtp}
      />
    </div>
  );
};

export default UpdateMobileNumber;
