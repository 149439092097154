import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Checkbox, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import GreenButton from '../../Buttons/GreenButton';
import axios from 'axios';
import {
  useMobileOtpMutation,
  useOldotpVerifyMutation,
} from '../../redux/mobileUpdate';
import OtpModel from './OtpModel';
import { FormControl, useFormControlContext } from '@mui/base/FormControl';
import { Input, inputClasses } from '@mui/base/Input';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/system';
import clsx from 'clsx';
import {
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  makeStyles,
} from '@mui/material';
import { useBankModificationMutation } from '../../redux/bankDetails';
import { useNavigate } from 'react-router';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'auto',
  bgcolor: 'background.paper',

  boxShadow: 24,
};

export default function AddBankModel({ open, handleClose }) {
  let isBankInfoEditMode = false;
  let isBankInfoEditID = '';
  let bankStatementData = '';
  let bankStatementExt = '';
  let event =
    isBankInfoEditMode && isBankInfoEditID !== '' ? 'EDIT_BANK' : 'ADD_BANK';
  const [isFormValidatedAddBank, setIsFormValidatedAddBank] = useState(false);
  const [addBank] = useBankModificationMutation();
  const navigate = useNavigate();
  const [formData, setFormData] = React.useState({
    clientCode: 'BJ634',
    accountName: '',
    accountNumber: '',
    confirmaccountNumber: '',
    ifsccode: '',
    bankName: '',
    accountType: '',
    type: '',
    bankBranchName: '',
    password: null,
    bankProof: null,
  });

  // Step 2: Handle input changes
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleFileChange = (e) => {
    const file = e.target.files[0]; // Get the first selected file
    setFormData((prevData) => ({
      ...prevData,
      bankProof: file, // Store the file itself, not just the name
    }));
  };

  const handleSubmit = async () => {
    // Example of simple form validation (replace with your own logic)
    if (formData) {
      setIsFormValidatedAddBank(true);

      let event =
        isBankInfoEditMode && isBankInfoEditID !== ''
          ? 'EDIT_BANK'
          : 'ADD_BANK';

      // Construct the body object
      let body = {
        clientCode: 'BJ634',
        ifscCode: formData.ifsccode,
        bankName: formData.bankName,
        branchName: formData.bankBranchName,
        accountType: formData.accountType,
        accountNumber: formData.accountNumber,
        confirmAccountNumber: formData.confirmaccountNumber,
        micrCode: formData.password, // Assuming this is the MICR code
        holderName: formData.accountName,
        bankStatementData: bankStatementData,
        bankStatementExt: bankStatementExt,
        event: event,
        id: isBankInfoEditID,
      };

      // Proceed with form submission or API call using the body object
      console.log(body);
      const response = await addBank(body).unwrap();
      navigate(response.redirectUrl);
      console.log('Success:', response);
    } else {
      setIsFormValidatedAddBank(false);
      // Show validation error messages
      console.log('Form is not valid');
    }
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <div>
        <Box sx={style}>
          <Typography
            sx={{ position: 'relative', left: '10px', top: '7px' }}
            id="modal-modal-title"
            variant="h6"
          >
            Add Bank Account{' '}
            <a style={{ fontSize: 'small' }}>
              (Note : This bank will be added as default bank for payout.)
            </a>
          </Typography>
          <hr />

          {/* <Box sx={{ padding: '10px' }}>
            <RadioGroup value={selectedOption} onChange={handleOptionChange}>
              <Typography>Receive OTP on</Typography>
              <FormControlLabel
                value="mobile"
                control={<Radio color="primary" />}
                label="Mobile"
              />
              <FormControlLabel
                value="email"
                control={<Radio color="primary" />}
                label="Email"
              />
            </RadioGroup>
          </Box>
          <hr />
          <Button variant="contained" color="primary" onClick={sendOtp}>
            Send OTP
          </Button> */}
          <Grid
            sx={{ marginBottom: '20px', marginLeft: '0px' }}
            container
            spacing={2}
          >
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <Label>Account Holder Name</Label>
              <StyledInput
                name="accountName"
                value={formData.accountName}
                onChange={handleInputChange}
                placeholder="Write your name here"
              />
              <HelperText />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <Label>Account Number</Label>
              <StyledInput
                name="accountNumber"
                value={formData.accountNumber}
                onChange={handleInputChange}
                placeholder="Write your number here"
              />
              <HelperText />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <Label>Confirm Account Number </Label>
              <StyledInput
                name="confirmaccountNumber"
                value={formData.confirmaccountNumber}
                onChange={handleInputChange}
                placeholder="confirm number here"
              />
              <HelperText />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <Label>IFSC Code</Label>
              <StyledInput
                name="ifsccode"
                value={formData.ifsccode}
                onChange={handleInputChange}
                placeholder="Write your ifsc here"
              />
              <HelperText />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <Label>Bank Name</Label>
              <StyledInput
                name="bankName"
                value={formData.bankName}
                onChange={handleInputChange}
                placeholder="Write your bankName here"
              />
              <HelperText />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <InputLabel>Account Type</InputLabel>
              <Select
                sx={{
                  width: '70%',
                  height: '45px',
                  '&:hover': {
                    '&& fieldset': {
                      border: '1px solid #42a5f5',
                    },
                  },
                }}
                name="accountType"
                value={formData.accountType}
                onChange={handleInputChange}
                fullWidth
                displayEmpty
              >
                <MenuItem value="">Select accountType</MenuItem>
                <MenuItem value="Saving">Saving</MenuItem>
                <MenuItem value="Current">Current</MenuItem>
              </Select>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={6}>
              <InputLabel> Type</InputLabel>
              <Select
                sx={{
                  width: '70%',
                  height: '45px',
                  '&:hover': {
                    '&& fieldset': {
                      border: '1px solid #42a5f5',
                    },
                  },
                }}
                labelId="select-label"
                id="select"
                name="type"
                value={formData.type}
                onChange={handleInputChange}
                fullWidth
                displayEmpty
              >
                <MenuItem value=""> Type</MenuItem>
                <MenuItem value="Addition">Addition</MenuItem>
                <MenuItem value="Modification">Modification</MenuItem>

                {/* Add more options as needed */}
              </Select>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <Label>Bank Branch Name</Label>
              <StyledInput
                name="bankBranchName"
                value={formData.bankBranchName}
                onChange={handleInputChange}
                placeholder="Write your bankBranchName"
              />
              <HelperText />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <Label>Password (if any bank proof)</Label>
              <StyledInput
                name="password"
                value={formData.password}
                onChange={handleInputChange}
                placeholder="password"
              />
              <HelperText />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <Label>Upload Bank Proof</Label>
              <StyledInput
                type="file"
                name="bankProof" // Match the name to your formData field
                onChange={handleFileChange} // Use the correct file change handler
                inputProps={{ accept: '.pdf,.doc,.docx,.png,.jpg' }} // Restrict file types if needed
              />
              {formData.bankProof && (
                <Typography variant="body2" sx={{ marginTop: '8px' }}>
                  {formData.bankProof.name} {/* Display the file name */}
                </Typography>
              )}
              <HelperText />
            </Grid>
          </Grid>
          <Button
            variant="contained"
            sx={{
              border: '1px solid #36B864',
              color: '#FFFFFF',
              width: '129px',
              fontWeight: '500',
              height: '31px',
              padding: '0px',
              textTransform: 'none',
              textAlign: 'right !important',
              backgroundColor: '#3DAC5A',
              '&:hover': {
                backgroundColor: '#3DAC5A',
              },
            }}
            onClick={() => handleSubmit(formData)}
          >
            Apply Now
          </Button>
        </Box>
      </div>
    </Modal>
  );
}

const StyledInput = styled(Input)(
  ({ theme }) => `
    
      .${inputClasses.input} {
        width: 300px;
        font-family: 'IBM Plex Sans', sans-serif;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.5;
        padding: 8px 12px;
        margin-right:'10px';
        border-radius: 8px;
        color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
        background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
        border: 1px solid ${
          theme.palette.mode === 'dark' ? grey[700] : grey[200]
        };
        box-shadow: 0px 2px 2px ${
          theme.palette.mode === 'dark' ? grey[900] : grey[50]
        };
    
        &:hover {
          border-color: ${blue[400]};
        }
    
        &:focus {
          outline: 0;
          border-color: ${blue[400]};
          box-shadow: 0 0 0 3px ${
            theme.palette.mode === 'dark' ? blue[600] : blue[200]
          };
        }
      }
    `
);

const Label = styled(({ children, className }) => {
  const formControlContext = useFormControlContext();
  const [dirty, setDirty] = React.useState(false);

  React.useEffect(() => {
    if (formControlContext?.filled) {
      setDirty(true);
    }
  }, [formControlContext]);

  if (formControlContext === undefined) {
    return <p>{children}</p>;
  }

  const { error, required, filled } = formControlContext;
  const showRequiredError = dirty && required && !filled;

  return (
    <p className={clsx(className, error || showRequiredError ? 'invalid' : '')}>
      {children}
      {required ? ' *' : ''}
    </p>
  );
})`
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 0.875rem;
  margin-bottom: 4px;

  &.invalid {
    color: red;
  }
`;

const HelperText = styled((props) => {
  const formControlContext = useFormControlContext();
  const [dirty, setDirty] = React.useState(false);

  React.useEffect(() => {
    if (formControlContext?.filled) {
      setDirty(true);
    }
  }, [formControlContext]);

  if (formControlContext === undefined) {
    return null;
  }

  const { required, filled } = formControlContext;
  const showRequiredError = dirty && required && !filled;

  return showRequiredError ? <p {...props}>This field is required.</p> : null;
})`
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 0.875rem;
`;

const blue = {
  100: '#DAECFF',
  200: '#b6daff',
  400: '#3399FF',
  500: '#007FFF',
  600: '#0072E5',
  900: '#003A75',
};

const grey = {
  50: '#F3F6F9',
  100: '#E5EAF2',
  200: '#DAE2ED',
  300: '#C7D0DD',
  400: '#B0B8C4',
  500: '#9DA8B7',
  600: '#6B7A90',
  700: '#434D5B',
  800: '#303740',
  900: '#1C2025',
};
