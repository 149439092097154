import React from 'react';
import { useGetholdingsQuery } from '../../redux/pledge';

const Pledgeunpludge = () => {
  const { data } = useGetholdingsQuery();

  return (
    <div style={{ padding: '2rem' }}>
      <h1 style={{ fontSize: '1.5rem', fontWeight: 'bold', marginBottom: '1rem' }}>Active Holdings</h1>
      <div style={{ border: '1px solid #D1D5DB' }}>
        <div style={{ backgroundColor: '#10B981', color: 'white', padding: '0.5rem', display: 'flex' }}>
          <div style={{ width: '16.66%' }}>Symbol</div>
          <div style={{ width: '16.66%' }}>ISIN</div>
          <div style={{ width: '16.66%' }}>Pledged for Margin</div>
          <div style={{ width: '16.66%' }}>Qty</div>
          <div style={{ width: '16.66%' }}>Buy Avg</div>
          <div style={{ width: '16.66%' }}>Buy Val</div>
          <div style={{ width: '16.66%' }}>LTP</div>
        </div>
        <div style={{ padding: '1rem', textAlign: 'center', color: '#6B7280' }}>
          {data && data.length > 0 ? (
            data.map((holding, index) => (
              <div key={index} style={{ display: 'flex', padding: '0.5rem 0', borderBottom: '1px solid #E5E7EB' }}>
                <div style={{ width: '16.66%' }}>{holding.symbol || 'N/A'}</div>
                <div style={{ width: '16.66%' }}>{holding.isin || 'N/A'}</div>
                <div style={{ width: '16.66%' }}>{holding.pledged || 'N/A'}</div>
                <div style={{ width: '16.66%' }}>{holding.qty || 'N/A'}</div>
                <div style={{ width: '16.66%' }}>{holding.buyAvg || 'N/A'}</div>
                <div style={{ width: '16.66%' }}>{holding.buyVal || 'N/A'}</div>
                <div style={{ width: '16.66%' }}>{holding.ltp || 'N/A'}</div>
              </div>
            ))
          ) : (
            <div>Client has No Holdings</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Pledgeunpludge;
