import React from 'react';
import { Grid, useMediaQuery, useTheme } from '@mui/material';
import { Star } from '@mui/icons-material';
import { useGetMutualfundsQuery } from '../redux/MutualFunds';

const Hybrid = () => {
  const { data: debtadata } = useGetMutualfundsQuery();
  const Debt = debtadata['hybrid'];
  console.log(Debt);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <div>
      <table style={{ width: '100%', marginBottom: '7px', color: '#212529' }}>
        <thead
          style={{
            color: '#707070',
            fontSize: isSmallScreen ? 'x-small' : 'small',
          }}
        >
          <tr>
            <th>NAME</th>
            {!isSmallScreen && <th>1Y</th>}
            {!isSmallScreen && <th>2Y</th>}
            <th>3Y</th>
          </tr>
        </thead>
        <tbody>
          {' '}
          {Debt.map((debt, index) => (
            <tr key={index}>
              <td
                style={{
                  fontWeight: 500,
                  fontSize: isSmallScreen ? '10px' : '13px',
                  color: '#3c4043',
                  padding: '5px',
                  verticalAlign: 'top',
                  borderTop: '1px solid #dee2e6',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  {/* <div
                    style={{
                      width: isSmallScreen ? '40px' : '50px',
                      height: isSmallScreen ? '40px' : '50px',
                      padding: '3px',
                      border: '1px solid #eeeeee',
                      borderRadius: '6px',
                      verticalAlign: 'top',
                      marginLeft: isSmallScreen ? '0px' : '10px',
                      display: 'flex',
                    }}
                  >
                    <img
                      src={debt.logoUrl}
                      alt="Aditya Birla Sun Life Money Manager Fund"
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                      }}
                    />
                  </div> */}
                  <div
                    style={{
                      marginLeft: isSmallScreen ? '0px' : '5px',
                      marginTop: 'auto',
                      marginBottom: 'auto',
                      textAlign: isSmallScreen ? 'left' : 'left',
                    }}
                  >
                    <p>
                      <span
                        style={{
                          marginBottom: '10px',
                          fontWeight: 600,
                          fontSize: '12px',
                          color: '#3c4043',
                          textTransform: 'uppercase',
                        }}
                      >
                        {debt.name}
                      </span>
                    </p>
                    <p
                      style={{
                        marginBottom: '0px',
                        fontWeight: 600,
                        marginTop: '0px',
                        fontSize: '12px',
                        color: '#777D74!important',
                      }}
                    >
                      {' '}
                      <span>{debt.riskCategory}</span> . Hybrid . {debt.star}
                      <Star
                        sx={{ fontSize: isSmallScreen ? 'small' : 'medium' }}
                      />
                    </p>
                  </div>
                </div>
              </td>
              {!isSmallScreen && (
                <React.Fragment>
                  <td
                    style={{
                      fontWeight: 500,
                      fontSize: '13px',
                      color: '#3c4043',
                      padding: '5px',
                      verticalAlign: 'top',
                      borderTop: '1px solid #dee2e6',
                    }}
                  >
                    {debt.oneYearPercent}
                  </td>
                  <td
                    style={{
                      fontWeight: 500,
                      fontSize: '13px',
                      color: '#3c4043',
                      padding: '5px',
                      verticalAlign: 'top',
                      borderTop: '1px solid #dee2e6',
                    }}
                  >
                    {debt.twoYearPercent}
                  </td>
                </React.Fragment>
              )}
              <td
                style={{
                  fontWeight: 500,
                  fontSize: '13px',
                  color: '#3c4043',
                  padding: '5px',
                  verticalAlign: 'top',
                  borderTop: '1px solid #dee2e6',
                }}
              >
                {debt.threeYearPercent}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Hybrid;
