import React, { useState } from 'react';

import { Button, CardContent, Grid, Typography } from '@mui/material';
import { ArrowUpward, BusinessCenter } from '@mui/icons-material';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import TripOriginIcon from '@mui/icons-material/TripOrigin';
import CallIcon from '@mui/icons-material/Call';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import EmailIcon from '@mui/icons-material/Email';

import PublicIcon from '@mui/icons-material/Public';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TelegramIcon from '@mui/icons-material/Telegram';
import './Footer.css';
const Footer = () => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',

        borderRadius: '10px',
        marginTop: '70px',
        minHeight: '350px',
        padding: '5px',
      }}
    >
      <div style={{ position: 'relative', top: '50px' }}>
        {' '}
        {/* <h3
          style={{
            fontSize: '20px',
            fontWeight: 400,
            textTransform: 'capitalize',
            letterSpacing: '.3px',
            margin: '0px',
            color: '#131722',
            lineHeight: '1.5px',
            paddingLeft: '20px',
            display: 'flex',
            position: 'relative',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          Why choose us?
        </h3> */}
        <p
          style={{
            color: '#282828',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '15px',
          }}
        >
          <b>Here’s a few reasons you should choose ATS :</b>
        </p>
      </div>
      <Grid sx={{ position: 'relative', top: '100px' }} container spacing={0.4}>
        <Grid item xs={12} sm={6} md={2} lg={2.4}>
          <CardContent
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
              height: '100%', // Ensure the CardContent takes up the full height of the Grid item
            }}
          >
            <div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <BusinessCenter className="icon" />
              </div>
              <h3 className="footerh3">Easy and Instant Payouts</h3>
              <p className="footerp">
                Initiate your payout request through the Back Office by 8:30 AM, and funds will be credited to your bank account by 6:30 PM the same day.
              </p>
            </div>
          </CardContent>
        </Grid>
        <Grid item xs={12} sm={6} md={2} lg={2.4}>
          <CardContent
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
              height: '100%',
            }}
          >
            <div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <TripOriginIcon className="icon" />
              </div>
              <h3 className="footerh3">Free Demat Account</h3>
              <p className="footerp">
                Open a Demat account easily and conveniently with no stamp duty, reduced paperwork, and a fast, secure, and seamless trading experience.
              </p>
            </div>
          </CardContent>
        </Grid>
        <Grid item xs={12} sm={6} md={2} lg={2.4}>
          <CardContent
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
              height: '100%',
            }}
          >
            <div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <CurrencyExchangeIcon className="icon" />
              </div>
              <h3 className="footerh3">Lowest Brokerage Charges</h3>
              <p className="footerp">
                ATS offers the lowest brokerage charges with no hidden fees.
              </p>
            </div>
          </CardContent>
        </Grid>
        <Grid item xs={12} sm={6} md={2} lg={2.4}>
          <CardContent
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
              height: '100%',
            }}
          >
            <div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <CallIcon className="icon" />
              </div>
              <h3 className="footerh3">Call N Trade Facility</h3>
              <p className="footerp">
                Our Service Desk is available on all trading days from 9:00 AM to 11:30 PM.
              </p>
            </div>
          </CardContent>
        </Grid>
        <Grid item xs={12} sm={6} md={2} lg={2.4}>
          <CardContent
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
              height: '100%',
            }}
          >
            <div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <WorkspacePremiumIcon className="icon" />
              </div>
              <h3 className="footerh3">Premium Recommendations</h3>
              <p className="footerp">
                ATS's premium package is designed for clients looking to achieve higher returns compared to standard intraday packages.
              </p>
            </div>
          </CardContent>
        </Grid>
      </Grid>
    </div>
  );
};

export default Footer;
