// src/redux/FinancialNewsApi.js
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { NEWS_URL } from './baseurl';

export const FinancialNewsApi = createApi({
  reducerPath: 'financialNewsApi',
  baseQuery: fetchBaseQuery({ baseUrl: NEWS_URL }),
  tagTypes: ['Finance'],
  endpoints: (builder) => ({
    politicalNews: builder.query({
      query: () => ({
        method: 'POST', // Change to POST
        url: '', // If NEWS_URL is the endpoint, this should be an empty string
        body: {
          keyword: 'Political News',
          number: '5',
        },
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
        },
      }),
      providesTags: ['Finance'],
    }),
    marketNews: builder.query({
      query: () => ({
        method: 'POST',
        url: '', 
        body: {
          keyword: 'Market News',
          number: '5',
        },
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
        },
      }),
      providesTags: ['Market'],
    }),
    economyNews: builder.query({
      query: () => ({
        method: 'POST', // Change to POST
        url: '', // If NEWS_URL is the endpoint, this should be an empty string
        body: {
          keyword: 'Economy News',
          number: '5',
        },
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
        },
      }),
      providesTags: ['Economy'],
    }),
  }),
});

// Export the hook to use in your component
export const {
  usePoliticalNewsQuery,
  useMarketNewsQuery,
  useEconomyNewsQuery,
} = FinancialNewsApi;

export default FinancialNewsApi;
