import React, { useEffect, useState } from 'react';
import './Company.css';
import { useSelector } from 'react-redux';

const Overview = () => {
  const [wsData, setWsData] = useState('');
  const tokenId = useSelector((state) => state.token.tokenID);
  const [socket, setSocket] = useState(null);
  console.log(tokenId);
  // Initialize WebSocket connection
  useEffect(() => {
    if (!tokenId) return; // Early return if tokenId is not set

    const ws = new WebSocket('wss://neuronsoft.in/ws/watchlist/abc/');

    ws.onopen = () => {
      console.log('WebSocket connection opened.');
      ws.send(
        JSON.stringify({
          event: 'addMarketDepth',
          token: tokenId,
        })
      );
    };

    ws.onmessage = (event) => {
      const data = JSON.parse(event.data);
      console.log('Received WebSocket data:', data);

      // Ensure you're receiving the correct event type before setting state
      if (data.event === 'marketDepth') {
        setWsData(data);
      }
    };

    ws.onclose = () => {
      console.log('WebSocket closed.');
    };

    ws.onerror = (error) => {
      console.error('WebSocket error:', error.message);
    };

    return () => {
      ws.close(); // Clean up the WebSocket connection on component unmount
    };
  }, [tokenId]);

  useEffect(() => {
    console.log('Updated wsData:', wsData);
  }, [wsData]);

  return (
    <div style={{ marginLeft: '10px' }}>
      <div style={{ paddingTop: '10px', paddingBottom: '10px' }}>
        <h5>Market Depth</h5>
      </div>
      <div>
        <table
          className="tbl"
          style={{ color: '#212529', marginBottom: '16px' }}
        >
          <thead>
            <tr style={{ color: '#707070' }}>
              <th
                style={{ fontSize: '14px', textAlign: 'left', padding: '7px' }}
              >
                BID
              </th>
              <th
                style={{
                  fontSize: '14px',
                  textAlign: 'center',
                  padding: '7px',
                }}
              >
                ORDERS
              </th>
              <th
                style={{ fontSize: '14px', textAlign: 'right', padding: '7px' }}
              >
                QTY
              </th>
              <th></th>
              <th
                style={{ fontSize: '14px', textAlign: 'left', padding: '7px' }}
              >
                BID
              </th>
              <th
                style={{
                  fontSize: '14px',
                  textAlign: 'center',
                  padding: '7px',
                }}
              >
                ORDERS
              </th>
              <th
                style={{ fontSize: '14px', textAlign: 'right', padding: '7px' }}
              >
                QTY
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td
                className="greenText"
                style={{ fontSize: '12px', textAlign: 'left', padding: '7px' }}
              >
                {wsData.Bid1}
              </td>
              <td
                className="greenTextCenter"
                style={{
                  fontSize: '12px',
                  textAlign: 'center',
                  padding: '7px',
                }}
              >
                {wsData.Bid_Order_Count1}
              </td>
              <td
                className="greenTextRight"
                style={{ fontSize: '12px', textAlign: 'right', padding: '7px' }}
              >
                {wsData.Bid_Qty1}
              </td>
              <td></td>
              <td
                className="redText"
                style={{ fontSize: '12px', textAlign: 'left', padding: '7px' }}
              >
                {wsData.ASK1}
              </td>
              <td
                className="redTextcenter"
                style={{
                  fontSize: '12px',
                  textAlign: 'center',
                  padding: '7px',
                }}
              >
                {wsData.Sell_Order_Count1}
              </td>
              <td
                className="redTextright"
                style={{ fontSize: '12px', textAlign: 'right', padding: '7px' }}
              >
                {wsData.Sell_Qty1}
              </td>
            </tr>
            <tr>
              <td
                className="greenText"
                style={{ fontSize: '12px', textAlign: 'left', padding: '7px' }}
              >
                {wsData.Bid_Price2}
              </td>
              <td
                className="greenTextCenter"
                style={{
                  fontSize: '12px',
                  textAlign: 'center',
                  padding: '7px',
                }}
              >
                {wsData.Bid_Order_Count2}
              </td>
              <td
                className="greenTextRight"
                style={{ fontSize: '12px', textAlign: 'right', padding: '7px' }}
              >
                {wsData.Bid_Qty2}
              </td>
              <td></td>
              <td
                className="redText"
                style={{ fontSize: '12px', textAlign: 'left', padding: '7px' }}
              >
                {wsData.Sell_Price2}
              </td>
              <td
                className="redTextcenter"
                style={{
                  fontSize: '12px',
                  textAlign: 'center',
                  padding: '7px',
                }}
              >
                {wsData.Sell_Order_Count2}
              </td>
              <td
                className="redTextright"
                style={{ fontSize: '12px', textAlign: 'right', padding: '7px' }}
              >
                {wsData.Sell_Qty2}
              </td>
            </tr>
            <tr>
              <td
                className="greenText"
                style={{ fontSize: '12px', textAlign: 'left', padding: '7px' }}
              >
                {wsData.Bid_Price3}
              </td>
              <td
                className="greenTextCenter"
                style={{
                  fontSize: '12px',
                  textAlign: 'center',
                  padding: '7px',
                }}
              >
                {wsData.Bid_Order_Count3}
              </td>
              <td
                className="greenTextRight"
                style={{ fontSize: '12px', textAlign: 'right', padding: '7px' }}
              >
                {wsData.Bid_Qty3}
              </td>
              <td></td>
              <td
                className="redText"
                style={{ fontSize: '12px', textAlign: 'left', padding: '7px' }}
              >
                {wsData.Sell_Price3}
              </td>
              <td
                className="redTextcenter"
                style={{
                  fontSize: '12px',
                  textAlign: 'center',
                  padding: '7px',
                }}
              >
                {wsData.Sell_Order_Count3}
              </td>
              <td
                className="redTextright"
                style={{ fontSize: '12px', textAlign: 'right', padding: '7px' }}
              >
                {wsData.Sell_Qty3}
              </td>
            </tr>
            <tr>
              <td
                className="greenText"
                style={{ fontSize: '12px', textAlign: 'left', padding: '7px' }}
              >
                {wsData.Bid_Price4}
              </td>
              <td
                className="greenTextCenter"
                style={{
                  fontSize: '12px',
                  textAlign: 'center',
                  padding: '7px',
                }}
              >
                {wsData.Bid_Order_Count4}
              </td>
              <td
                className="greenTextRight"
                style={{ fontSize: '12px', textAlign: 'right', padding: '7px' }}
              >
                {wsData.Bid_Qty4}
              </td>
              <td></td>
              <td
                className="redText"
                style={{ fontSize: '12px', textAlign: 'left', padding: '7px' }}
              >
                {wsData.Sell_Price4}
              </td>
              <td
                className="redTextcenter"
                style={{
                  fontSize: '12px',
                  textAlign: 'center',
                  padding: '7px',
                }}
              >
                {wsData.Sell_Order_Count4}
              </td>
              <td
                className="redTextright"
                style={{ fontSize: '12px', textAlign: 'right', padding: '7px' }}
              >
                {wsData.Sell_Qty4}
              </td>
            </tr>
            <tr>
              <td
                className="greenText"
                style={{ fontSize: '12px', textAlign: 'left', padding: '7px' }}
              >
                {wsData.Bid_Price5}
              </td>
              <td
                className="greenTextCenter"
                style={{
                  fontSize: '12px',
                  textAlign: 'center',
                  padding: '7px',
                }}
              >
                {wsData.Bid_Order_Count5}
              </td>
              <td
                className="greenTextRight"
                style={{ fontSize: '12px', textAlign: 'right', padding: '7px' }}
              >
                {wsData.Bid_Qty5}
              </td>
              <td></td>
              <td
                className="redText"
                style={{ fontSize: '12px', textAlign: 'left', padding: '7px' }}
              >
                {wsData.Sell_Price5}
              </td>
              <td
                className="redTextcenter"
                style={{
                  fontSize: '12px',
                  textAlign: 'center',
                  padding: '7px',
                }}
              >
                {wsData.Sell_Order_Count5}
              </td>
              <td
                className="redTextright"
                style={{ fontSize: '12px', textAlign: 'right', padding: '7px' }}
              >
                {wsData.Sell_Qty5}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Overview;
