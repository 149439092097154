import React, { useEffect, useState } from 'react';

const MTF = ({mobileNumber}) => {
  
  const [errorMessage, setErrorMessage] = useState('');
  
  const fetchData = async (url, body, method) => {
    try {
      const response = await fetch(url, { method });
      const data = await response.json();
      return data;
    } catch (error) {
      return { status: 'error', message: 'Failed to connect to server.' };
    }
  };

  const activateMTFLink = async () => {
    console.log('Activate MTF Started...');
    if (!mobileNumber) {
      alert('Mobile number is required');
      return;
    }

    try {
      let url = `https://kyc.adityatrading.in/activate-mtf?mobile_num=${mobileNumber}`;
      console.log(url);

      let response = await fetchData(url, {}, 'GET');

      if (response.status === 'success') {
        let docId = response.documentID;
        window.open(docId);
      } else {
        setErrorMessage(
          response.message || 'Something went wrong while activating MTF.'
        );
        console.log('Sorry, something happened with activating MTF...');
      }
    } catch (e) {
      setErrorMessage('An error occurred while activating MTF.');
      console.log('Error:', e);
    }
  };

  return (
    <div>
      {mobileNumber ? (
        <p>
          Please Click the button to Activate MTF
          <a
            onClick={activateMTFLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            Click here to Proceed
          </a>
        </p>
      ) : (
        <p>Mobile Number Not found</p>
      )}

      {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
    </div>
  );
};

export default MTF;
