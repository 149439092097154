import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { KYC_API } from "./baseurl";

export const freezeStatusApi = createApi({
  reducerPath: 'freezeStatusApi',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://kyc.adityatrading.in/api/', //KYC_API
  }),
  tagTypes: ['FreezeStatus'],
  endpoints: (builder) => ({
    freezeStatus: builder.query({
      query: (clientCode) => ({
        method: 'GET',
        url: `get-freezestatus?clientCode=${clientCode}`,
      }),
    }),
  }),
});

export const { useFreezeStatusQuery } = freezeStatusApi;
export default freezeStatusApi;
