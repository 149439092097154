import React, { useEffect, useState } from 'react';
import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
} from '@mui/material';
import Paper from '@mui/material/Paper';
const Brokerage = () => {
  const [localProfile, setLocalProfile] = useState(null); 

  useEffect(() => {
    const storedUserProfile = JSON.parse(localStorage.getItem('usersProfileData'));
    if (storedUserProfile) {
      console.log("Loaded profile from localStorage:", storedUserProfile);
      setLocalProfile(storedUserProfile);
    }
  }, []);

  const brokerageData = Array.isArray(localProfile?.brokerage_info) ? localProfile?.brokerage_info : [];


  return (
    <div
      style={{ position: 'relative', top: '-15px' }}
      className="table-responsive"
    >
      <TableContainer component={Paper} className="table-responsive">
        <Table aria-label="simple table" className="tables" id="blank">
          <TableHead sx={{ backgroundColor: '#34a853', color: '#fff' }}>
            <TableRow>
              <TableCell>Client Exchange</TableCell>
              <TableCell align="right">Min Brokerage</TableCell>
              <TableCell align="right">Brokerage Percent</TableCell>
              <TableCell align="right">MCX Brokerage Percent</TableCell>
              <TableCell align="right">From Date</TableCell>
              <TableCell align="right">Through Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
              {brokerageData.length > 0 ? (
                brokerageData.map((data, index) => (
                  <TableRow
                    key={index}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row" style={{ fontSize: '12px', width: '200px' }}>
                      {data.client_exchange || 'No Data'}
                    </TableCell>
                    <TableCell align="right" style={{ fontSize: '12px', width: '200px' }}>
                      {data.brok_min || 'No Data'}
                    </TableCell>
                    <TableCell align="right" style={{ fontSize: '12px', width: '200px' }}>
                      {data.brok_percent || 'NIL%'}
                    </TableCell>
                    <TableCell align="right" style={{ fontSize: '12px', width: '200px' }}>
                      {data.brok_percent_mcx || 'NIL%'}
                    </TableCell>
                    <TableCell align="right" style={{ fontSize: '12px', width: '200px' }}>
                      {data.from_date || 'No Data'}
                    </TableCell>
                    <TableCell align="right" style={{ fontSize: '12px', width: '200px' }}>
                      {data.thru_date || 'No Data'}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    No data available
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default Brokerage;
