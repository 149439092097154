import { Box } from '@mui/system';
import React from 'react';
import { useCircularsQuery } from '../../redux/circulars';

const Bse = () => {
  const { data } = useCircularsQuery();
  const nseData = data?.['bse'] || [];
  console.log(nseData);

  return (
    <div>
      {nseData.map((nse, index) => (
        <Box
          key={index}
          sx={{
            display: 'flex',
            borderBottom: '1px solid #ededed',
            marginTop: '20px',
            marginBottom: '20px',
          }}
        >
          <div
            style={{
              flex: '0 0 83.333333%',
              maxWidth: '83.333333%',
              flexDirection: 'column',
              paddingLeft: '15px',
              paddingRight: '15px',
            }}
          >
            <a
              style={{
                color: '#3c4043',
                fontSize: '14px',
                fontWeight: '600',
                paddingTop: '7px',
                paddingBottom: '7px',
              }}
              href="#"
            >
              {nse.title}
            </a>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                paddingBottom: '7px',
              }}
            >
              <div
                style={{
                  paddingLeft: '15px',
                  paddingRight: '15px',
                  fontSize: '13px',
                  flex: '0 0 25%',
                  maxWidth: '25%',
                  color: '#3c4043',
                  fontSize: '14px',
                  fontWeight: '600',
                  paddingTop: '10px',
                  paddingBottom: '10px',
                }}
              >
                {nse.created}
              </div>
              <div
                style={{
                  paddingLeft: '15px',
                  paddingRight: '15px',
                  paddingTop: '10px',
                  flex: '0 0 75%',
                  maxWidth: '75%',
                  color: '#3c4043',
                  fontSize: '14px',
                  fontWeight: '600',
                }}
              >
                <span
                  style={{
                    color: '#0089D0',
                    border: '1px solid',
                    padding: '5px',
                    fontSize: '12px',
                  }}
                >
                  {nse.category}
                </span>
              </div>
            </div>
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <p>
              <a
                style={{ fontSize: '14px', fontWeight: '600' }}
                href={nse.redirecturl}
              >
                View
              </a>
            </p>
          </div>
        </Box>
      ))}
    </div>
  );
};

export default Bse;
