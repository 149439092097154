import React from 'react';
import LandingPage from '../LandingPage';
import Footer from '../Footer/Footer';
import FooterContent from '../Footer/FooterContent';
import desc from './desc.css';

const Disclaimer = () => {
  return (
    <>
      <LandingPage />
      <div className="container">
        {/* Disclaimer section with shaded background */}
        <div className="section">
          {/* Text Section */}
          <div className="textSection">
            <h1 style={{ fontSize: '24px', fontWeight: 'bold', marginBottom: '16px' }}>Disclaimer</h1>
            <p className="disclaimerText">
              Stock tips (and all other tips provided under a specific client subscription with ATS Wealth Managers Private Limited) are purely a view point of a group of individuals and there is no guarantee on the returns. Hence all the visitors are requested to apply their prudence and consult their financial advisor before acting on any of the recommendations by this site or any of its modes. Neither ATS Wealth Managers Private Limited nor any of its promoters, members, or employees holds any responsibility of losses incurred (if any) by acting on the recommendations.
            </p>
          </div>
          {/* Image Section */}
          <div className="imageSection">
            <img
              src="https://adityatrading.in/static/images/disclaimer-img.svg"
              alt="Illustration of a person signing a document"
              className="imageStyle"
            />
          </div>
        </div>

        {/* White section with more details */}
        <div className="whiteSection">
          <p className="disclaimerText">
            All data and reports at ATS Wealth Managers Private Limited are only information services for subscribers and are not individualized recommendations to buy or sell securities, nor offers to buy or sell securities. Under any circumstances the subscription amount paid to ATS Wealth Managers Private Limited are not refundable.
          </p>
          <p className="disclaimerText">
            Investment in equity shares, futures, options and commodities has its own risks. Sincere efforts have been made to present the right investment perspective. The information contained herein is based on analysis and on sources that we consider reliable. We, however, do not vouch for the accuracy or the completeness thereof. This material is for personal information and we are not responsible for any loss incurred due to it & take no responsibility whatsoever for any financial profits or loss which may arise from the recommendations above.
          </p>
          <p className="disclaimerText">
            ATS Wealth Managers Private Limited Research sales analysts and representatives are not investment advisers and do not recommend investment services to clients. They are only authorized to sell subscriptions to ATS Wealth Managers Private Limited's services. Based on your 'client risk profile and assessment form', qualified analysts will recommend the right service or mix of services to you. If you given unsolicited advice related to a specific security or scrip by a sales analyst, please ignore the advise and immediately report the incident to info@ATS Wealth Managers Private Limited research.co, kindly note that please do trade in all our calls provided through SMS or weblink only. We request you to kindly avoid trading on calls given by any of our executive over the phone. In case of any issues arises regarding the same, ATS Wealth Managers Private Limited Research would not be held responsible.
          </p>
          <p className="disclaimerText">
            ATS Wealth Managers Private Limited Research thrives on the value of technical analysis and employs best industry practices to ensure quality of results. Please note that ATS Wealth Managers Private Limited does not guarantee or assure any kind of return or profit to its subscribers. ATS Wealth Managers Private Limited has never (and will never in future) sold subscriptions in the name of jackpot calls or sureshot calls or bullet calls. These types of products are indicative of gamble and/or non-technical analysis. ATS Wealth Managers Private Limited advises its subscribers to be aware of these words and avoid subscriptions at companies which use these words or sell such products.</p>
          <p className="disclaimerText">
            ATS Wealth Managers Private Limited Research sales analysts and representatives are not investment advisers and do not recommend investment services to clients. They are only authorized to sell subscriptions to ATS Wealth Managers Private Limited's services. Based on your 'client risk profile and assessment form', qualified analysts will recommend the right service or mix of services to you. If you given unsolicited advice related to a specific security or scrip by a sales analyst, please ignore the advise and immediately report the incident to info@ATS Wealth Managers Private Limited research.co, kindly note that please do trade in all our calls provided through SMS or weblink only. We request you to kindly avoid trading on calls given by any of our executive over the phone. In case of any issues arises regarding the same, ATS Wealth Managers Private Limited Research would not be held responsible.
          </p>
          <p className="disclaimerText">
            This Website is for information purposes only. The information contained on this should not be construed as an offer to sell, a solicitation of an offer to buy, or a recommendation for the units of the schemes of ATS Wealth Managers Private Limited. ATS Wealth Managers Private Limited is neither responsible nor liable for third party content provided or content from any other aspect of any other Website, which may be accessed from this Website.</p>
          <p className="disclaimerText">
            Our Clients (Paid or Unpaid), any third party or anyone else have no rights to forward or share our calls or SMS or Reports or any other information provided by us to/with anyone which is received directly or indirectly by them. If found so then Serious Legal Actions can be taken.
          </p>
          <p className="disclaimerText">
            The content of the site and the interpretation of data are solely the personal views of the contributors. ATS Wealth Managers Private Limited reserves the right to make modifications and alterations to the content of the website at anytime. Users are advised to use the data for the purpose of information only and rely on their own judgment while making individual investment decisions. The values of all the indices displayed in the website, may be delayed by up to 10 minutes. The investments discussed or recommended may not be suitable for all investors. ATS Wealth Managers Private Limited does not warranty the timeliness, accuracy or quality of the electronic content on its and other affiliate websites.
          </p>
          <p className="disclaimerText">
            A possibility exists that the site could include inaccuracies or errors. Additionally, a possibility exist that unauthorized additions, deletions or alterations could be made by third parties to the site. Although ATS Wealth Managers Private Limited attempts to ensure the integrity, correctness and authenticity of the site, it makes no guarantees whatsoever as to its completeness, correctness or accuracy. In the event, that such an inaccuracy arises, please inform ATS Wealth Managers Private Limited so that it can be corrected.
          </p>
          <p className="disclaimerText">
          The price and value of investments and the income derived from them can go up or down and you may not get back the capital invested. Changes in the rate of exchange may have an adverse effect on the value, price and income of investments in deposits other than your own. Past performance is not necessarily an indicator of future performance.
            </p>
          <p className="disclaimerText">
          The content of the website cannot be copied, reproduced, republished, uploaded, posted, transmitted or distributed for any non-personal use without obtaining prior permission from ATS Wealth Managers Private Limited. We reserve the right to terminate the accounts of subscribers/customers, who violate the proprietary rights, in addition to necessary legal action.
            </p>
          <p className="disclaimerText">
          ATS Wealth Managers Private Limited and its owners/affiliates are not liable for damages caused by any performance, failure of performance, error, omission, interruption, deletion, defect, delay in transmission or operations, computer virus, communications line failure, and unauthorized access to the personal accounts. ATS Wealth Managers Private Limited is not responsible for any technical failure or malfunction of the software or delays of any kind. We are also not responsible for non-receipt of registration details or e-mails. ATS Wealth Managers Private Limited is not responsible for the content of any of the linked sites. By providing access to other web-sites, ATS Wealth Managers Private Limited is neither recommending nor endorsing the content available in the linked websites.
            </p>
          <p className="disclaimerText">
          You agree that the information gathered from your profile will be used to enhance your experience on the website. We will not rent or sell the profile to any third party. In case of a contest or a promotion scheme, we reserve the right to share the users profile with the sponsors.
            </p>
          <p className="disclaimerText">
          In the event of necessary credit checks and collection of payments, ATS Wealth Managers Private Limited can disclose such information to other authorities in good faith. ATS Wealth Managers Private Limited will use all or any part of the service and change terms without any obligation. By filling out the 'Free Trial Form' on this website, you agree to provide us your valid mobile number. Also by providing your mobile number, you provide us the consent to call you on the number provided by you and send SMS on your mobile number. You provide your consent for us to use your mobile number even if it is registered on the National 'Do Not Disturb' registry.
            </p>
          <p className="disclaimerText">
          This website is for the exclusive purpose of transactions to be carried out within the territorial jurisdiction of India and all such transactions shall be governed by the laws in India. Notice is hereby given that Non Resident Indians (NRI's) and Foreign Nationals accessing this web site and opting to transact thereon shall do so after due verification at their end of their eligibility to do so. ATS Wealth Managers Private Limited undertakes no responsibility for such pre-eligibility of qualification on part of Non-Resident Indians (NRI's) or Foreign Nationals to transact on this website.
            </p>
          <p className="disclaimerText">
          You understand and agree that no joint venture, partnership, employment or agency relationship exists between you and ATS Wealth Managers Private Limited as a result of this agreement or on account of use of our website.
           </p>
          <p className="disclaimerText">
          Price and availability of products and services offered on the site are subject to change without prior notice. We provide information about the availability of products or services to a certain extent but you should not rely on such information. ATS Wealth Managers Private Limited will not be liable for any lack of availability of products and services you may order through the site.
           </p>
           <p className="disclaimerText">
           Kindly note that please do trade in all our calls provided through SMS or weblink only. We request you to kindly avoid trading on calls given by any of our executive over the phone. In case of any issues arises regarding the same, ATS Wealth Managers Private Limited Research would not be held responsible.
          </p>
        </div>
      </div>
      <Footer />
      <FooterContent />
    </>
  );
};

export default Disclaimer;
