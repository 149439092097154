import React from "react";
import "./styles.css";

const phrases = [
  {
    text: "Beware of Fraudsters, posing themselves as ATS Employees and assuring you of high returns from Stock Markets. ATS never calls anyone with an assured returns scheme, also ATS doesn’t accept payments in Third Party Bank Account. Learn more.. || Prevent Unauthorized Transactions in your demat account > Update your Mobile Number with your Depository Participant. Receive alerts on your Registered Mobile for all debit and other important transactions in your demat account directly from CDSL on the same day. They are issued in the interest of investors. || KYC is one one-time exercise while dealing in securities markets-once KYC is done through a SEBI registered intermediary (broker, DP, Mutual Fund, etc.), you need not undergo the same process again when you approach another intermediary. || No need to issue cheques by investors while subscribing to IPO. Just write the bank account number and sign the application form to authorize your bank to make payment in case of allotment. No worries about a refund as the money remains in the investor's account.",
    url: "/theft-identity/",
  },
];

export default function App() {
  return (
    <div className="marquee-container">
      <div className="marquee">
        {phrases.map((phrase, index) => (
          <span key={index} className="marquee-item">
            {phrase.text}
            <a style={{ marginLeft: "20px" }} href={phrase.url}>
              Learn more
            </a>
          </span>
        ))}
        {/* Duplicate the content to avoid gap */}
        {phrases.map((phrase, index) => (
          <span key={`duplicate-${index}`} className="marquee-item">
            {phrase.text}
            <a style={{ marginLeft: "20px" }} href={phrase.url}>
              Learn more
            </a>
          </span>
        ))}
      </div>
    </div>
  );
}
