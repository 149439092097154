import React, { useEffect, useState } from "react";
import LandingPage from "../../LandingPage";

import FooterContent from "../../Footer/FooterContent";
import { useLocation } from "react-router-dom";

import { dark } from "@mui/material/styles/createPalette";
import GreenButton from "../../Buttons/GreenButton";
import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import DangerButton from "../../Buttons/Danger";
import { Subject } from "@mui/icons-material";
import { useCreateTicketNeuronMutation } from "../../redux/CreateTIcket";


const Createticket = () => {
  const [clientCode, setClientCode] = useState("");
  const [subject, setSubject] = useState("");
  const [description, setDescription] = useState("");
  const [createTicket] = useCreateTicketNeuronMutation();
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    var storedData = JSON.parse(localStorage.getItem("verifiedData"));
    if (storedData && storedData.data) {
      setClientCode(storedData.data);
    }
    console.log("stored data - ", storedData);
  }, []);

  const date = new Date().toISOString();

  const handleTicket = async () => {
    setError("");
    setSuccess("");

    if (!subject || !description) {
      setError("All fields are mandatory.");
      return;
    }

    try {
      const response = await createTicket({
        client_code: clientCode,
        date: date,
        description: description,
        subject: subject,
      });

      if (response?.data) {
        setSuccess(
          "Ticket created successfully! You may get call from our team shortly."
        );
        console.log("Ticket Creation Response:", response.data);
      } else {
        setError("Error creating ticket. Please try again.");
        console.error("Ticket Creation Error:", response.error);
      }
    } catch (err) {
      setError("An unexpected error occurred. Please try again.");
      console.error("API Error:", err);
    }
  };

  return (
    <div>
      <LandingPage />
      <div
        style={{
          position: "relative",
          padding: "0px",
          margin: "0px",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            width: "80%",
            top: "100px",
            position: "relative",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <div style={{ display: "normal" }}>
            <span
              style={{ fontWeight: "bold", color: "grey", fontSize: "28px" }}
            >
              HI{" "}
            </span>
            <span
              style={{ color: "green", fontWeight: "bold", fontSize: "26px" }}
            >
              {clientCode || ""}
            </span>
            <br />
            <span
              style={{ fontWeight: "bold", color: "black", fontSize: "22px" }}
            >
              you can create a ticket here on you issues.
            </span>
            <br />
          </div>
          <hr />
          <br />

          <Grid container direction="column" spacing={2}>
            <Grid container item spacing={2}>
              <Grid item xs={isMobile ? 12 : 6}>
                <Box>
                  <Typography
                    sx={{ color: "#374948 !important", fontWeight: "normal" }}
                  >
                    Client Code*
                  </Typography>
                  <input
                    type="text"
                    className="formcontroll"
                    autoComplete="off"
                    placeholder="please enter client code"
                    style={{
                      outline: "none",
                      border: "1px solid #a7a4a4",
                      width: "100%",
                      padding: "8px",
                      fontSize: "16px",
                    }}
                    value={clientCode}
                    onChange={(e) => setClientCode(e.target.value)} // Update state on change
                  />
                </Box>
              </Grid>
              <Grid item xs={isMobile ? 12 : 6}>
                <Box>
                  <Typography
                    sx={{ color: "#374948 !important", fontWeight: "normal" }}
                  >
                    Subject*
                  </Typography>
                  <input
                    type="text"
                    className="formcontroll"
                    autoComplete="off"
                    placeholder="please enter subject"
                    style={{
                      outline: "none",
                      border: "1px solid #a7a4a4",
                      width: "100%",
                      padding: "8px",
                      fontSize: "16px",
                    }}
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)} // Update state on change
                  />
                </Box>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Box>
                <Typography
                  sx={{ color: "#374948 !important", fontWeight: "normal" }}
                >
                  Description <small>(Please provide complete details)</small>
                </Typography>
                <textarea
                  type="text"
                  rows={7}
                  className="formcontroll"
                  autoComplete="off"
                  style={{
                    outline: "none",
                    border: "1px solid #a7a4a4",
                    width: "100%",
                    padding: "8px",
                    fontSize: "16px",
                    borderRadius: "4px",
                    resize: "vertical",
                    height: "auto",
                  }}
                  value={description}
                  onChange={(e) => setDescription(e.target.value)} // Update state on change
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box
                sx={{ display: "flex", justifyContent: "center" }}
                width="100%"
                height="100px"
              >
                <GreenButton children="Create Ticket" onClick={handleTicket} />{" "}
                {/* Call the handler */}
              </Box>
              <Box
                sx={{ display: "flex", justifyContent: "center" }}
                width="100%"
                height="100px"
              >
                {error && <Typography color="error">{error}</Typography>}
                {success && <Typography color="green">{success}</Typography>}
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Grid item sx={{ top: "70px", position: "relative" }} xs={12} md={6}>
          <FooterContent />
        </Grid>
      </div>
    </div>
  );
};

export default Createticket;
