export const BASE_URL = 'https://adityatrading.in';
export const SERVER_URL = 'https://adityatrading.in';
export const NEWS_URL = 'https://neuronsoft.in/stocks/api/cms/get-news/';
export const SEARCH_URL = 'https://neuronsoft.in/api/cm_ticker_search/';
export const CHART_API = 'https://neuronsoft.in/stocks/api/ticker_price_data/';
export const COMPANY_NEWS_API = 'https://neuronsoft.in/stocks/api/getFundamentalReport/';
export const TECHNICAL_NEWS_APi = 'https://neuronsoft.in/stocks/api/getTechnicalReport/';
export const PROFITLOSS_API = 'https://neuronsoft.in/stocks/api/getProfitLoss/';
export const NUERON_STOCKS_API = 'https://neuronsoft.in/stocks';
export const KYC_API = 'https://kyc.adityatrading.in/api/';
export const SYNAPSYS_URL = 'https://synapses.adityatrading.in/api/';
export const LOCAL_URL = 'http://127.0.0.1:8000/';
