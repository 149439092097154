import { CardContent, Grid, Typography } from '@mui/material';
import React, { useEffect, useState, useCallback } from 'react';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import LinearIndeterminate from '../Assets/Load';
// Component to display individual stock data

const Currency = () => {
  const [currency, setCurrency] = useState([]);
  const [socket, setSocket] = useState(null);
  const [wsData, setWsData] = useState([]);

  // Fetch stock data
  const fetchData = useCallback(async () => {
    try {
      const response = await fetch(
         'https://adityatrading.in/api/get-index-stocks/',
        {
          method: 'POST',
        }
      );
      const data = await response.json();
      setCurrency(data.currencies || []);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }, []);

  // Initialize WebSocket connection
  useEffect(() => {
    const ws = new WebSocket('wss://neuronsoft.in/ws/watchlist/companyInfo/');

    ws.onopen = () => {
      console.log('WebSocket connection opened.');
      // Send subscription messages for all tokenIDs
      if (currency.length > 0) {
        currency.forEach((stock) => {
          const message = JSON.stringify({
            event: 'addTicker',
            token: stock.tokenID,
          });
          ws.send(message);
          console.log(`Sent tokenID ${stock.tokenID} to WebSocket.`);
        });
      }
    };

    ws.onmessage = (event) => {
      const data = JSON.parse(event.data);

      // Update WebSocket data by filtering out duplicates and adding new data
      setWsData((prevData) => {
        const updatedData = prevData.filter(
          (item) => item.tokenID !== data.tokenID
        );
        updatedData.push(data);
        return updatedData;
      });
    };

    // ws.onmessage = (event) => {
    //   const data = JSON.parse(event.data);

    //   setWsData((prevData) => {
    //     const updatedData = prevData.filter(
    //       (item) => item.tokenID !== data.tokenID
    //     );
    //     updatedData.push(data);
    //     return updatedData;
    //   });
    // };

    ws.onclose = () => console.log('WebSocket connection closed.');
    ws.onerror = (error) => console.log('WebSocket error:', error);

    setSocket(ws);

    return () => {
      ws.close();
    };
  }, [currency]);

  // Fetch data on component mount
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // Combine stocks with WebSocket data
  const combinedStocks = currency.map((stock) => {
    const updatedStock = wsData.find((data) => data.tokenID === stock.tokenID);
    return updatedStock ? { ...stock, ...updatedStock } : stock;
  });
  const formatChange = (value) => {
    return Number(value).toFixed(2);
  };

  return (
    <div>
      <div style={{ marginTop: '40px' }}>
        <Grid container spacing={2} sx={{ justifyContent: 'center' }}>
          {combinedStocks.length > 0 ? (
            combinedStocks.map((stock, index) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                <CardContent
                  sx={{
                    width: '100%',
                    height: 'auto',
                    border: '1px solid #eeeeee',
                    borderRadius: '10px',
                    padding: '8px 12px',
                    marginBottom: '12px',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <div
                        style={{
                          backgroundColor:
                            stock.percentageChange && stock.Change < 0
                              ? '#ffdfe2'
                              : '#d1ffdd',
                          borderRadius: '5px',
                          textAlign: 'center',
                          width: '32px',
                          height: '32px',
                        }}
                      >
                        {stock.percentageChange && stock.Change < 0 ? (
                          <ArrowDownward
                            sx={{
                              marginTop: '8px',
                              fontSize: '14px',
                              color: '#dc3545',
                            }}
                          />
                        ) : (
                          <ArrowUpward
                            sx={{
                              marginTop: '8px',
                              fontSize: '14px',
                              color: '#34a853',
                            }}
                          />
                        )}
                      </div>
                      <div style={{ marginLeft: '10px' }}>
                        <Typography
                          sx={{
                            color: '#3c4043',
                            fontSize: '.75rem',
                            fontWeight: 600,
                            textTransform: 'uppercase',
                          }}
                          component="p"
                        >
                          {stock.ticker_code}
                        </Typography>
                        <p
                          style={{
                            fontSize: '12px',
                          }}
                        >
                          <span
                            style={{
                              color:
                                stock.percentageChange >= 0
                                  ? '#34a853'
                                  : '#dc3545',
                            }}
                          >
                            {stock.percentageChange >= 0
                              ? `${stock.percentageChange}%`
                              : `${stock.percentageChange}%`}
                          </span>
                        </p>
                      </div>
                    </div>
                    <div style={{ textAlign: 'right' }}>
                      <Typography
                        sx={{
                          fontSize: '.75rem',
                          fontWeight: 600,
                          textTransform: 'uppercase',
                        }}
                        component="p"
                      >
                        {stock.LTP}
                      </Typography>
                      <p style={{ fontSize: '12px' }}>
                        <span
                          style={{
                            color: stock.Change >= 0 ? '#34a853' : '#dc3545',
                          }}
                        >
                          {stock.Change >= 0
                            ? `+${formatChange(stock.Change)}`
                            : `${formatChange(stock.Change)}`}
                        </span>
                      </p>
                    </div>
                  </div>
                </CardContent>
              </Grid>
            ))
          ) : (
            <> </>
          )}
        </Grid>
      </div>
    </div>
  );
  
};

export default Currency;
