import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import {
  CardContent,
  IconButton,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import Modal from '@mui/material/Modal';
import { useSelector } from 'react-redux';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { Add, Remove } from '@mui/icons-material';
import TextField from '@mui/material/TextField';
import GreenButton from '../Buttons/GreenButton';
import DangerButton from '../Buttons/Danger';
import { usePortfolioMutation } from '../redux/portfolio';

const style = {
  position: 'relative',
  height: '418px',
  overflowY: 'auto',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  borderRadius: '7px',
  boxShadow: 24,
  p: 4,
};

export default function StockModal({ open, handleOpen }) {
  const [clientCode, setClientCode] = useState('');
  const [phonenumber, setPhoneNumber] = useState('');
  const [wsData, setWsData] = useState(null);
  const tokenID = useSelector((state) => state.token.tokenID);
  const CompanyName = useSelector((state) => state.token.companyName);
  const [alignment, setAlignment] = useState('Stocks');
  const [exchange, setExchange] = useState('NSE');
  const [quantity, setQuantity] = useState("0");
  const [price, setPrice] = useState('');
  const [date, setDate] = useState('');
  const [dateError, setDateError] = useState('');
  const [portfolio] = usePortfolioMutation();
  const handleChange = () => {
    setAlignment(alignment === 'Currency' ? 'Stocks' : 'Currency');
  };

  useEffect(() => {
    var storedData = JSON.parse(localStorage.getItem('verifiedData'));
    if (storedData && storedData.data) {
      setClientCode(storedData.data);
      setPhoneNumber(storedData.Phone_Number);
      console.log("======== in stock model =========");
      console.log(storedData);
    }
    console.log("stored data - ", storedData);
  }, [clientCode, phonenumber]);

  const handleExchange = () => {
    setExchange(exchange === 'NSE' ? 'BSE' : 'NSE');
  };

  const handleModelClose = () => {
    handleOpen(false);
  };

  const handleIncrease = () => {
    setQuantity((prevQuantity) => (parseInt(prevQuantity, 10) + 1).toString());
  };

  const handleDecrease = () => {
    if (parseInt(quantity, 10) > 0) {
      setQuantity((prevQuantity) => (parseInt(prevQuantity, 10) - 1).toString());
    }
  };

  const handleNumChange = (event) => {
    const value = parseInt(event.target.value, 10);
    setQuantity(value >= 0 ? value : 0);
  };

  const handlePriceChange = (event) => {
    setPrice(event.target.value);
  };

  const handleDateChange = (event) => {
    const { value } = event.target;
    setDate(value);
    if (value && !isValidDate(value)) {
      setDateError('Please enter a valid date (mm/dd/yyyy).');
    } else {
      setDateError('');
    }
  };

  const handleExchnage = () => {
    setExchange(exchange === 'NSE' ? 'BSE' : 'NSE');
  };

  const handleSave = async () => {
    if (quantity <= 0) {
      alert("Quantity can't be 0");
      return;
    }

    if (price <= 0) {
      alert('Price must be a positive number.');
      return;
    }

    // Convert date from mm/dd/yyyy to YYYY-MM-DD
    const formatDate = (dateString) => {
      const [month, day, year] = dateString.split('/');
      return `${month}/${day}/${year}`; // format: YYYY-MM-DD -> dd-mm-yyyy
    };

    // const data = {
    //   action: "add",
    //   date: "10/28/2024",
    //   exchange: "NSE",
    //   price: "862",
    //   quantity: "2",
    //   securityName: "TATAMOTORS",
    //   segment: "A",
    //   token: "NSECM3456",
    //   type: "BUY",
    // };
    //console.log("this is the body - ", data);

    try {
      // const result = await portfolio(data).unwrap();
      const result = await portfolio({
        clientCode,
        action: "add",
        date,
        exchange,
        price,
        quantity: quantity.toString(),
        securityName: CompanyName,
        segment: "A",
        token: tokenID,
        type: "BUY",
      }).unwrap();

      if (result.status === "success") {
        console.log('Portfolio saved successfully:', result.Message);
        alert('Successfully created the portfolio --', result.status);
      } else {
        console.error("We are facing issue while making portfolio- ")
        console.log('Error saving portfolio:', result);
        alert('Try again later');
      }
    } catch (error) {
      console.error('Failed to save portfolio:', error);
      alert('An error occurred while saving the portfolio');
    }
  };

  useEffect(() => {
    const ws = new WebSocket('wss://neuronsoft.in/ws/watchlist/companyInfo/');

    ws.onopen = () => {
      console.log('WebSocket connection opened.');

      const message = JSON.stringify({
        event: 'addTicker',
        token: tokenID,
      });
      ws.send(message);
      console.log(`Sent tokenID ${tokenID} to WebSocket.`);
    };

    ws.onmessage = (event) => {
      const data = JSON.parse(event.data);
      setWsData(data);
    };

    ws.onclose = () => console.log('WebSocket connection closed.');
    ws.onerror = (error) => console.log('WebSocket error:', error);

    return () => {
      ws.close();
    };
  }, [tokenID]);

  const formatChange = (value) => Number(value).toFixed(2);

  const isValidDate = (dateString) => {
    const regex = /^\d{2}\/\d{2}\/\d{4}$/; // dd/mm/yyyy format
    if (!dateString.match(regex)) return false;

    const [day, month, year] = dateString.split('/').map(Number);
    const date = new Date(day, month - 1, year);
    return date.getFullYear() === year && date.getMonth() === month - 1 && date.getDate() === day;
  };

  return (
    <Modal
      open={open}
      onClose={handleModelClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <CardContent
          sx={{
            width: '294px',
            height: '74px',
            border: '1px solid #eeeeee',
            borderRadius: '10px',
            padding: '8px 12px',
            marginBottom: '12px',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  backgroundColor:
                    wsData?.percentageChange && wsData?.Change < 0
                      ? '#ffdfe2'
                      : '#d1ffdd',
                  borderRadius: '5px',
                  textAlign: 'center',
                  width: '42px',
                  height: '32px',
                }}
              >
                {wsData?.percentageChange && wsData?.Change < 0 ? (
                  <ArrowDownward
                    sx={{
                      marginTop: '8px',
                      fontSize: '14px',
                      color: '#dc3545',
                    }}
                  />
                ) : (
                  <ArrowUpward
                    sx={{
                      marginTop: '8px',
                      fontSize: '14px',
                      color: '#34a853',
                    }}
                  />
                )}
              </div>
              <div style={{ marginLeft: '10px' }}>
                <Typography
                  sx={{
                    color: '#3c4043',
                    fontSize: '.75rem',
                    fontWeight: 600,
                    textTransform: 'uppercase',
                  }}
                  component="p"
                >
                  {CompanyName}
                </Typography>
                <p style={{ fontSize: '12px' }}>
                  <span
                    style={{
                      color:
                        wsData?.percentageChange >= 0 ? '#34a853' : '#dc3545',
                    }}
                  >
                    {wsData?.percentageChange
                      ? `${wsData?.percentageChange}%`
                      : 'N/A'}
                  </span>
                </p>
              </div>
            </div>
            <div style={{ textAlign: 'right' }}>
              <Typography
                sx={{
                  fontSize: '.75rem',
                  fontWeight: 600,
                  textTransform: 'uppercase',
                }}
                component="p"
              >
                {wsData?.LTP || 'N/A'}
              </Typography>
              <p style={{ fontSize: '12px' }}>
                <span
                  style={{
                    color: wsData?.Change >= 0 ? '#34a853' : '#dc3545',
                  }}
                >
                  {wsData?.Change ? `${formatChange(wsData?.Change)}` : 'N/A'}
                </span>
              </p>
            </div>
          </div>
        </CardContent>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Box>
            <ToggleButtonGroup
              value={alignment}
              exclusive
              onChange={handleChange}
              aria-label="Platform"
              sx={{
                mt: '37px',
                justifyContent: 'space-between',
                width: '201px',
              }}
            >
              <Box>
                <Typography
                  sx={{
                    position: 'relative',
                    top: '10px',
                    fontSize: '17px',
                    color: '#374948 ',
                    fontWeight: '600',
                  }}
                >
                  Orders:
                </Typography>
              </Box>
              <Box>
                <ToggleButton
                  sx={{
                    padding: '4px,16px',
                    color: '#1a1818',
                    fontSize: '11px',
                    fontWeight: 'normal',
                    border: '1px solid rgba(0, 0, 0, 0.12)!important',
                    '&.MuiButtonBase-root': {
                      padding: '8px',
                    },
                    '&.Mui-selected': {
                      color: '#fff',
                      backgroundColor: '#34a853',
                      '&:hover': {
                        backgroundColor: '#34a853',
                      },
                    },
                    // '&:not(.Mui-selected):hover': {
                    //   backgroundColor: '#34a853',
                    //   color: '#fff',
                    // },
                  }}
                  value="Stocks"
                >
                  Buy
                </ToggleButton>
                <ToggleButton
                  sx={{
                    padding: '4px,10px',
                    fontSize: '11px',
                    color: '#1a1818',
                    fontWeight: 'normal',
                    border: '1px solid rgba(0, 0, 0, 0.12)!important',
                    '&.MuiButtonBase-root': {
                      padding: '8px',
                    },
                    '&.Mui-selected': {
                      color: '#fff',
                      backgroundColor: '#dc3545',
                      '&:hover': {
                        backgroundColor: '#dc3545',
                      },
                    },
                    // '&:not(.Mui-selected):hover': {

                    //   color: '#fff',
                    // },
                  }}
                  value="Currency"
                >
                  Sell
                </ToggleButton>
              </Box>
            </ToggleButtonGroup>
          </Box>
          <Box>
            <ToggleButtonGroup
              value={exchange}
              exclusive
              onChange={handleExchnage}
              aria-label="Platform"
              sx={{
                mt: '37px',
                justifyContent: 'space-between',
                width: '201px',
              }}
            >
              <Box>
                <Typography
                  sx={{
                    position: 'relative',
                    top: '10px',
                    fontSize: '17px',
                    color: '#374948 ',
                    fontWeight: '600',
                  }}
                >
                  Exchange:
                </Typography>
              </Box>

              <Box sx={{ display: 'flex' }}>
                <ToggleButton
                  sx={{
                    padding: '4px,16px',
                    color: '#1a1818',
                    fontSize: '11px',
                    fontWeight: 'normal',
                    border: '1px solid rgba(0, 0, 0, 0.12)!important',
                    '&.MuiButtonBase-root': {
                      padding: '8px',
                    },
                    '&.Mui-selected': {
                      color: '#fff',
                      backgroundColor: '#34a853',
                      '&:hover': {
                        backgroundColor: '#34a853',
                      },
                    },
                    // '&:not(.Mui-selected):hover': {
                    //   backgroundColor: '#34a853',
                    //   color: '#fff',
                    // },
                  }}
                  value="NSE"
                >
                  NSE
                </ToggleButton>
                <ToggleButton
                  sx={{
                    padding: '4px,10px',
                    fontSize: '11px',
                    color: '#1a1818',
                    fontWeight: 'normal',
                    border: '1px solid rgba(0, 0, 0, 0.12)!important',
                    '&.MuiButtonBase-root': {
                      padding: '8px',
                    },
                    '&.Mui-selected': {
                      color: '#fff',
                      backgroundColor: '#dc3545',
                      '&:hover': {
                        backgroundColor: '#dc3545',
                      },
                    },
                    // '&:not(.Mui-selected):hover': {

                    //   color: '#fff',
                    // },
                  }}
                  value="BSE"
                >
                  BSE
                </ToggleButton>
              </Box>
            </ToggleButtonGroup>
          </Box>
        </Box>

        {/* Form fields */}
        <Box
          component="form"
          sx={{
            '& .MuiTextField-root': { m: 1, width: '25ch' },
            display: 'flex',
            justifyContent: 'center',
            position: 'relative',
            top: '10px',
          }}
          noValidate
          autoComplete="off"
        >
          <div>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                height: '65px',
                width: '350px',
              }}
            >
              <IconButton onClick={handleDecrease} size="small">
                <Remove sx={{ width: '15px' }} />
              </IconButton>
              <TextField
                label="Quantity"
                size="small"
                variant="standard"
                type="text"
                value={quantity}
                onChange={handleNumChange}
                InputProps={{ inputProps: { min: 0 } }}
              />
              <IconButton onClick={handleIncrease} size="small">
                <Add sx={{ width: '15px' }} />
              </IconButton>

              <TextField
                label="Date"
                size="small"
                variant="standard"
                value={date}
                onChange={handleDateChange}
                placeholder="mm/dd/yyyy"
                error={!!dateError}
              />

              <TextField
                label="Price"
                type="text"
                size="small"
                variant="standard"
                value={price}
                onChange={handlePriceChange}
              />
            </Box>
          </div>
        </Box>

        {/* Save and Close buttons */}
        <div
          style={{
            display: 'flex',
            position: 'relative',
            top: '25px',
            justifyContent: 'space-around',
          }}
        >
          <GreenButton onClick={handleSave}>Save</GreenButton>
          <DangerButton name={'close'} onClick={handleModelClose} />
        </div>
      </Box>
    </Modal>
  );
}
