import React, { createContext, useContext, useState, useEffect } from 'react';

const WebSocketContext = createContext();

// WebSocketProvider Component
export const WebSocketProvider = ({ children }) => {
    const [socket, setSocket] = useState(null);
    const [messageQueue, setMessageQueue] = useState([]); // Track queued messages

    useEffect(() => {
        const ws = new WebSocket("wss://neuronsoft.in/ws/watchlist/companyInfo/"); // Replace with your WebSocket URL


        const onOpen = () => {
            console.log("WebSocket connection established");

            // Send all queued messages after connection is established
            messageQueue.forEach((message) => ws.send(message));
            setMessageQueue([]); // Clear the message queue
        };

        // Function to handle WebSocket close event
        const onClose = () => {
            console.log("WebSocket connection closed");
            // You can attempt to reconnect here if needed
            setSocket(null); // Set socket to null to allow for a new connection to be established
        };

        // Handle errors
        const onError = (error) => {
            console.error("WebSocket error:", error);
        };

        ws.onopen = onOpen;
        ws.onclose = onClose;
        ws.onerror = onError;

        // Set the WebSocket instance in state
        setSocket(ws);

        return () => {
            ws.close();
        };
    }, [messageQueue]);

    // Function to send message, queue if WebSocket is not open
    const sendMessage = (message) => {
        if (socket && socket.readyState === WebSocket.OPEN) {
            socket.send(message);
        } else {
            // Queue message if WebSocket is not open
            setMessageQueue((prevQueue) => [...prevQueue, message]);
        }
    };

    //   if (!socket) {
    //     return <div>Loading WebSocket...</div>;
    //   }

    console.log(">>>>>>>>>>>>>>WebSocketProvider is rendered");

    return (
        <WebSocketContext.Provider value={{ socket, sendMessage }}>
            {children}
        </WebSocketContext.Provider>
    );
};

// Custom hook to access WebSocket context
export const useWebSocket = () => {
    const { socket, sendMessage } = useContext(WebSocketContext);
    if (!socket) {
        throw new Error("useWebSocket must be used within a WebSocketProvider");
    }
    return { socket, sendMessage };
};
