import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Checkbox, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import GreenButton from '../../Buttons/GreenButton';
import axios from 'axios';
import {
  useMobileOtpMutation,
  useOldotpVerifyMutation,
} from '../../redux/mobileUpdate';
import OtpModel from './OtpModel';
import { border, padding } from '@mui/system';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'auto',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  padding: "10px", 
};

export default function MobileModel({ open, handleClose, clientCode }) {
  const [checked, setChecked] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');
  const [otp, setOtp] = useState('');
  const [otpopen, setOtpopen] = useState(false);
  const [sentOtp, setSentOtp] = useState('');
  const [generateOtp] = useMobileOtpMutation();
  const [verify] = useOldotpVerifyMutation();

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleCheckboxChange = (event) => {
    setChecked(event.target.checked);
  };

  //   const eventType =
  //     selectedOption === 'mobile' ? 'SEND_OTP_MOBILE_OLD' : 'SEND_OTP_EMAIL';
  //   try {
  //     const response = await axios.post(
  //       'http://127.0.0.1:8000/api/sdk-sign-in-mobile',
  //       {
  //         event: eventType,
  //         clientCode: 'ML04',
  //       },
  //       {
  //         headers: {
  //           'X-CSRFToken': csrfToken, // Add CSRF token in the header
  //         },
  //         withCredentials: true,
  //       }
  //     );
  //     if (response.data.status === 'success') {
  //       setSentOtp(response.data.otp); // Store OTP to compare later
  //       alert(response.data.message);
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  const sendOtp = async () => {
    try {
      const eventType =
        selectedOption === 'mobile' ? 'SEND_OTP_MOBILE_OLD' : 'SEND_OTP_EMAIL';
      const response = await generateOtp({
        clientCode: clientCode, 
        event: eventType,
      }).unwrap();

      if (response.status === 'success') {
        handleClose();
        setOtpopen(true);
        setSentOtp(response.otp);
        alert(response.message);
      }
    } catch (error) {
      console.error('Error sending OTP:', error);
    }
  };

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <Typography
            sx={{ position: 'relative', left: '10px', top: '7px' , padding:'10px'}}
            id="modal-modal-title"
            variant="h6"
          >
            Confirm Mobile Update
          </Typography >
          <hr />
          <Box sx={{ padding: '10px' }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={checked}
                  onChange={handleCheckboxChange}
                  color="primary"
                  sx={{ padding: '10px' }}
                />
              }
              label={
                <p>
                  I/We hereby declare that the mobile number being updated here
                  belongs to me/us. Further, I authorize ATS Share Broker to use
                  this to send me any information/alert/SMS. This change will
                  affect the Trading and Demat account that I hold with ATS
                  Share Broker.
                </p>
              }
            />
          </Box>
          <Box sx={{ padding: '5px' }}>
            <RadioGroup value={selectedOption} onChange={handleOptionChange}>
              <Typography sx={{ padding: '10px' }}>Receive OTP on</Typography>
              <FormControlLabel
                value="mobile"
                control={<Radio color="primary" />}
                label="Mobile"
              />
              <FormControlLabel
                value="email"
                control={<Radio color="primary" />}
                label="Email"
              />
            </RadioGroup>
          </Box>
          <hr />
          <Button variant="contained" color="primary" padding="10px" margin="10px" onClick={sendOtp}>
            Send OTP
          </Button>
        </Box>
      </Modal>

      <OtpModel sentOtp={sentOtp} open={otpopen} setOtpopen={setOtpopen} />
    </>
  );
}
