// src/redux/localStorageMiddleware.js
const localStorageMiddleware = (store) => (next) => (action) => {
  const result = next(action);

  // Check if the action is `setTokenID`
  if (action.type === 'token/setTokenID') {
    const { tokenID, companyName } = action.payload;

    // Update localStorage
    if (tokenID && companyName) {
      localStorage.setItem('tokenID', tokenID);
      localStorage.setItem('companyName', companyName);
    } else {
      localStorage.removeItem('tokenID');
      localStorage.removeItem('companyName');
    }
  }

  return result;
};

export default localStorageMiddleware;
