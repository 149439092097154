import { AddCircleTwoTone } from "@mui/icons-material";
import { margin } from "@mui/system";
import { GridFilterAltIcon } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import NomineeCard from "../Cards/NomineeEsignCard";
import { Button, Dialog, DialogContent } from "@mui/material";
import { useGetTokenMutation } from "../../redux/UsersPersonalData";
import { useGetNomineeQuery } from "../../redux/NomineeAdd";
import CryptoJS from 'crypto-js';
import Hashids from 'hashids';


//cretign the unique code for parsing into the token
const generateUniqueCode = (mobileNumber) => {
  const hashids = new Hashids('$%n@p$&$@x0n@t$', 16, 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890');

  const mobileNumberInt = parseInt(mobileNumber.replace('+91', ''), 10);

  const code = hashids.encode(mobileNumberInt);

  const generateLink = {
    // axonLink: `https://axon.adityatrading.in/index?request_id=${code}`,
    // fundLink: `https://axon.adityatrading.in/depositpg/?request_id=${code}`,
    // ipoLink: `https://ipo.adityatrading.in/SSO=${code}/`,
    // referralLink: `https://referral.adityatrading.in/SSO=${code}/`,
    // pocketLink: `https://pocket.adityatrading.in/SSO=${code}/`,
    hash: code
  };

  return generateLink;
};

const Nominee = ({ mobileNumber, clientCode }) => {
  const [localProfile, setLocalProfile] = useState(null);
  const [profileToken, setProfileToken] = useState();
  const { data: nomineeStatus, error, isLoading } = useGetNomineeQuery({ phoneNumber: mobileNumber });

  useEffect(() => {
    const storedUserProfile = JSON.parse(localStorage.getItem('usersProfileData'));
    if (storedUserProfile) {
      console.log("Loaded profile from localStorage:", storedUserProfile);
      setLocalProfile(storedUserProfile);
    }
  }, []);

  useEffect(() => {
    if (mobileNumber) {
      const hashedClientcode = generateUniqueCode(mobileNumber);
      setProfileToken(hashedClientcode);
    }
  }, [mobileNumber]);

  // Redirect link generator
  const generateRedirectLink = (type, token) => {
    return `https://kyc.adityatrading.in/nominee?type=${type}&token=${token}`;
  };

  if (isLoading) return <p>Loading Nominee data...</p>;
  if (error) return <p>Failed to load Nominee data</p>;

  const nominees = [
    { data: nomineeStatus.nominee1, label: "Nominee 1" },
    { data: nomineeStatus.nominee2, label: "Nominee 2" },
    { data: nomineeStatus.nominee3, label: "Nominee 3" }
  ];

  return (
    <div style={{ padding: "12px" }}>
      {nominees.map((nomineeObj, index) => {
        const nominee = nomineeObj.data;
        const nomineeType = `NOMINEE_${index + 1}`;
        const redirectLink = generateRedirectLink(nomineeType, profileToken?.hash);

        return (
          <div key={index} style={{ display: "flex", justifyContent: "space-between", marginBottom: "16px" }}>
            <div>
              <p
                style={{ color: "#34a853", fontWeight: "bold", cursor: "pointer", textDecoration: "underline" }}
                onClick={() => window.open(redirectLink, "_blank")}
              >
                {nominee ? nominee.name : nomineeObj.label}
              </p>
              {nominee && nominee.nomineeEsign === 'Esign Pending' && (
                <p
                  style={{ color: "#ff4d4f", cursor: "pointer", textDecoration: "underline" }}
                  onClick={() => window.open(redirectLink, "_blank")}
                >
                  (Esign is Pending)
                </p>
              )}
            </div>
            {/* <div>
              <p style={{ color: "#34a853", fontWeight: "bold" }}>
                {nominee ? nominee.address || 'No Address' : 'No Address'}
              </p>
            </div> */}
            {/* <div>
              <p style={{ color: "#34a853", fontWeight: "bold" }}>
                {nominee ? nominee.relation || 'No Data' : 'No Relation'}
              </p>
            </div> */}
          </div>
        );
      })}
    </div>
  );
  // return (
  //   <div style={{ padding: "12px" }}>
  //     {nomineeStatus?.status === 'success' && nomineeStatus.nominee1 && (
  //       <>
  //         {[nomineeStatus.nominee1, nomineeStatus.nominee2, nomineeStatus.nominee3].map((nominee, index) => {
  //           if (!nominee) return null; 

  //           const nomineeType = `NOMINEE_${index + 1}`;
  //           const redirectLink = generateRedirectLink(nomineeType, profileToken?.hash);

  //           return (
  //             <div key={index} style={{ display: "flex", justifyContent: "space-between", marginBottom: "16px" }}>
  //               <div>
  //                 <p style={{ color: "#34a853", fontWeight: "bold" }}>
  //                   {nominee.name || "Nominee Name"}
  //                 </p>
  //                 {nominee.nomineeEsign === 'Esign Pending' && (
  //                   <p
  //                     style={{ color: "#ff4d4f", cursor: "pointer", textDecoration: "underline" }}
  //                     onClick={() => window.open(redirectLink, '_blank')}
  //                   >
  //                     (Esign is Pending)
  //                   </p>
  //                 )}
  //               </div>
  //               <div>
  //                 <p style={{ color: "#34a853", fontWeight: "bold" }}>
  //                   {nominee.name || "Nominee Name"}
  //                 </p>
  //                 {nominee.nomineeEsign === 'Esign Pending' && (
  //                   <p
  //                     style={{ color: "#ff4d4f", cursor: "pointer", textDecoration: "underline" }}
  //                     onClick={() => window.open(redirectLink, '_blank')}
  //                   >
  //                     (Esign is Pending)
  //                   </p>
  //                 )}
  //               </div>
  //               <div>
  //                 <p style={{ color: "#34a853", fontWeight: "bold" }}>
  //                   {nominee.name || "Nominee Name"}
  //                 </p>
  //                 {nominee.nomineeEsign === 'Esign Pending' && (
  //                   <p
  //                     style={{ color: "#ff4d4f", cursor: "pointer", textDecoration: "underline" }}
  //                     onClick={() => window.open(redirectLink, '_blank')}
  //                   >
  //                     (Esign is Pending)
  //                   </p>
  //                 )}
  //               </div>
  //             </div>
  //           );
  //         })}
  //       </>
  //     )}

  //     {(!nomineeStatus?.nominee1 && !nomineeStatus?.nominee2 && !nomineeStatus?.nominee3) && (
  //       <p>No Nominee data available...</p>
  //     )}
  //   </div>
  // );
};


export default Nominee;