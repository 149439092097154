import React, { useEffect } from 'react';
import { useState } from 'react';
import { useAccountClosureMutation } from '../../redux/Closure';
import CryptoJS from 'crypto-js';

const base64Encode = (str) => {
  return btoa(str);
};

const base64Decode = (str) => {
  return atob(str);
};

const encryptMobileNumber = (mobileNumber, key) => {
  const encodedNumber = base64Encode(mobileNumber);
  return `${encodedNumber}.${base64Encode(key)}`;
};

const decryptMobileNumber = (encryptedData, key) => {
  const [encodedNumber, encodedKey] = encryptedData.split('.');
  if (base64Decode(encodedKey) !== key) {
    throw new Error('Invalid key for decryption');
  }
  return base64Decode(encodedNumber);
};

const Closure = ({ mobileNumber }) => {
  const [remarks, setRemarks] = useState('');
  const [accountClosure] = useAccountClosureMutation();
  const [encryptedMobileNumber, setEncryptedMobileNumber] = useState('');
  const [decryptedMobileNumber, setDecryptedMobileNumber] = useState('');

  const key = "AbpJFT-1sQro5Ibc_REHSDHDWpjomjbtON1K4rE71RI=";

  useEffect(() => {
    const encryptNumber = async () => {
      const mobileNumberStr = mobileNumber.toString(); 
      const encrypted = encryptMobileNumber(mobileNumberStr, key);
      setEncryptedMobileNumber(encrypted);
      
      // Decrypting to check if it's working
      const decrypted = decryptMobileNumber(encrypted, key);
      setDecryptedMobileNumber(decrypted);
    };
    encryptNumber();
  }, [mobileNumber]);

  console.log("mobile no in the closure page -- ", mobileNumber);

  const handleSubmit = async () => {
    console.log("Remarks:", remarks);
    console.log("Encrypted Mobile Number:", encryptedMobileNumber);
    console.log("Decrypted Mobile Number:", decryptedMobileNumber);
    
    try {
      const result = await accountClosure({
        remarks,
        mobileNumber: encryptedMobileNumber, 
      });
      
      if (result.status === "success") {
        console.log("We are getting success ... ");
        //window.location.href = result.data.redirectUrl;
      } else {
        console.log("Result data we got while closing - ", result);
        console.error(result.message || "An error occurred");
      }
    } catch (err) {
      console.error('An error occurred:', err);
    }
  };
  
  return (
    <div
      style={{
        backgroundColor: 'white',
        padding: '2rem',
        borderRadius: '0.5rem',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        width: '100%',
        maxWidth: '768px',
        margin: '0 auto',
      }}
    >
      <h1
        style={{
          fontSize: '1.125rem',
          fontWeight: '600',
          marginBottom: '1rem',
        }}
      >
        Account closure is irreversible and permanent. Please{' '}
        <span style={{ color: '#34a853' }}>read</span> this before proceeding
      </h1>
      <ul style={{ marginBottom: '1rem', listStyleType: 'none', padding: 0 }}>
        <li
          style={{
            padding: '0.75rem',
            border: '1px solid #e2e8f0',
            borderRadius: '0.375rem',
            marginBottom: '0.5rem',
          }}
        >
          * Please make sure you have zero holdings in your Demat account. You can either sell off your holdings or transfer them to a different Demat account.
        </li>
        <li
          style={{
            padding: '0.75rem',
            border: '1px solid #e2e8f0',
            borderRadius: '0.375rem',
            marginBottom: '0.5rem',
          }}
        >
          * Any remaining funds will be credited to your primary linked Bank account.
        </li>
        <li
          style={{
            padding: '0.75rem',
            border: '1px solid #e2e8f0',
            borderRadius: '0.375rem',
            marginBottom: '0.5rem',
          }}
        >
          * Any debit funds need to clear before closing the account.
        </li>
        <li
          style={{
            padding: '0.75rem',
            border: '1px solid #e2e8f0',
            borderRadius: '0.375rem',
            marginBottom: '0.5rem',
          }}
        >
          * Client needs to mention the remarks of account closing.
        </li>
      </ul>
      <textarea
        style={{
          width: '100%',
          padding: '0.75rem',
          border: '1px solid #e2e8f0',
          borderRadius: '0.375rem',
          marginBottom: '1rem',
          //value: {},
        }}
        rows="4"
        placeholder="Why are you closing your account?"
        value={remarks}
        onChange={(e) => setRemarks(e.target.value)}
      />
      <button
        style={{
          backgroundColor: '#ef4444',
          color: 'white',
          padding: '0.5rem 1rem',
          borderRadius: '0.375rem',
          cursor: 'pointer',
        }}
        onClick={handleSubmit}
      >
        Deactivate
      </button>
    </div>
  );
};

export default Closure;
