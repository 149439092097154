export { default as stockAPI } from './indexStocks';
export { default as AnalystStockAPI } from './Analyst';
export { default as markettrendsApi } from './Markettrends';
export { default as mutualfundsApi } from './MutualFunds';
export { default as ActivefundsAPI } from './Activefunds';
export { default as FinancialNewsApi } from './FinnancialNews';
export { default as earningsAPI } from './Calender';
export { default as researchReportApi } from './sharemarketnews';
export { default as circularsAPI } from './circulars';
export { default as searchApi } from './searchBox';
export { default as otpApi } from './otp';
export { default as chartApi } from './chart';
export { default as annualReportsApi } from './annualreports';
export { default as companynewsApi } from './companyNews';
export { default as technicalnewsApi } from './technicaltable';
export { default as profitandlossApi } from './p&l';
export { default as portfolioApi } from './portfolio';
export { default as userProfileApi } from './Userprofile';
export { default as mobilemailApi } from './mobileUpdate';
export { default as emailApi } from './emailUpdate';
export { default as userProfileBankApi } from './bankDetails';
export { default as pledgeApi } from './pledge';
