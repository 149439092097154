import React, { useEffect, useState } from 'react';
import {
  Container,
  Typography,
  Grid,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Button,
  Paper,
} from '@mui/material';
import { usePostClientIncomeMutation } from '../../redux/ClientIncome';
import CloseIcon from '@mui/icons-material/Close';
import NotificationCard from '../Cards/NotificatioinCard';



function App() {
  const [postClientIncome, { data, isLoading, error }] = usePostClientIncomeMutation();
  const [selectedIncome, setSelectedIncome] = useState('');
  //const [localProfile, setLocalProfile] = useState(null);
  const [clientCode, SetClientCode] = useState('');
  const [email, SetEmail] = useState('');
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState('');


  useEffect(() => {
    const storedUserProfile = JSON.parse(localStorage.getItem('usersProfileData'));
    if (storedUserProfile) {
      console.log("Loaded profile from localStorage:", storedUserProfile);
      // setLocalProfile(storedUserProfile);
      SetClientCode(storedUserProfile.user.client_code);
      SetEmail(storedUserProfile.user.email);
    }
  }, []);


  const handleRadioChange = (event) => {
    setSelectedIncome(event.target.value);
  };

  //console.log('hi profile-', localProfile);
  console.log('clientCode -', clientCode);
  console.log('email -', email);
  console.log("selected income -- ", selectedIncome);


  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log("Selected Income Range:", selectedIncome);
  
    try {
      const response = await postClientIncome({
        rangeVal: selectedIncome,
        clientCode: clientCode,
        emailid: email,
      }).unwrap(); 

      if (response && response.message) {
        console.log("This is the data in income field -- ", response.message);
        setNotificationMessage(response.message); 
      } else {
        setNotificationMessage("Something happened but no message was provided.");
      }
    } catch (error) {
      console.error("Error during postClientIncome:", error);
      setNotificationMessage("An error occurred: " + (error.message || "Unknown error"));
    } finally {
      setShowNotification(true); 
    }
  };
  

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <p>We Got an Error - {error}</p>
  }

  if (data) {
    console.log("we got data - ", data.message);
  }

  const handleCloseNotification = () => {
    setShowNotification(false);
  };

  return (
    <Container
      maxWidth="md"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '50vh',
        backgroundColor: 'white',
      }}
    >
      <Paper elevation={3} sx={{ padding: 4, width: '100%' }}>
        <Typography variant="h4" gutterBottom>
          Annual Income
        </Typography>
        <Typography variant="body1" gutterBottom>
          Select one of the options
        </Typography>
        <form onSubmit={handleSubmit}>
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="income"
              value={selectedIncome}
              onChange={handleRadioChange}
            >
              <Grid container spacing={2}>
                {/* Income options */}
                <Grid item xs={12} sm={4}>
                  <FormControlLabel
                    value="100000"
                    control={<Radio color="success" />}
                    label="Upto 1 Lakh"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControlLabel
                    value="100000 - 500000"
                    control={<Radio color="success" />}
                    label="1 Lakh - 5 Lakh"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControlLabel
                    value="500000 - 1000000"
                    control={<Radio color="success" />}
                    label="5 Lakh - 10 Lakh"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControlLabel
                    value="1000000 - 2500000"
                    control={<Radio color="success" />}
                    label="10 Lakh - 25 Lakh"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControlLabel
                    value="2500000 - 5000000"
                    control={<Radio color="success" />}
                    label="25 Lakh - 50 Lakh"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControlLabel
                    value="5000000 - 10000000"
                    control={<Radio color="success" />}
                    label="50 Lakh - 1 Crore"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControlLabel
                    value="10000000 - 50000000"
                    control={<Radio color="success" />}
                    label="1 Crore - 5 Crores"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControlLabel
                    value="50000000"
                    control={<Radio color="success" />}
                    label="More than 5 Crores"
                  />
                </Grid>
              </Grid>
            </RadioGroup>
          </FormControl>
          <Button
            type="submit"
            variant="contained"
            color="success"
            sx={{ marginTop: 3 }}
          >
            Submit
          </Button>
        </form>
        {showNotification && (
          <NotificationCard message={notificationMessage} onClose={handleCloseNotification} />
        )}
      </Paper>
    </Container>
  );
}

export default App;
