import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import AtsLogo from '../../Assets/ATS Icon 1.png';
import { useNavigate } from 'react-router-dom';

const drawerWidth = 240;
const navItems = [
  'Home',
  'Pricing',
  'Advisory',
  'Premium service',

  'Contact Us',
];

function CareerNavbar(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const navigate = useNavigate();

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const handleNavigation = (item) => {
    switch (item) {
      case 'Home':
        navigate('/');
        break;
      case 'Contact Us':
        navigate('/contactus/');
        break;
      default:
        navigate(`/${item.replace(' ', '').toLowerCase()}`);
    }
  };

  const handleCreateAccount = () => {
    navigate('/account-opening/');
  };

  const handleLogin = () => {
    navigate('/sign-in/');
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        MUI
      </Typography>
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem key={item} disablePadding>
            <ListItemButton
              sx={{ textAlign: 'center', color: '#36B864' }}
              onClick={() => handleNavigation(item)}
            >
              <ListItemText primary={item} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box
      sx={{
        display: 'flex',
        position: 'fixed',
        zIndex: '999',
        backgroundColor: '#ffffff',
        borderBottom: '1px solid #d4d4d4',
        width: '100%',
        height: '72px',
        alignItems: 'center',
      }}
    >
      <CssBaseline />
      <AppBar
        sx={{
          backgroundColor: '#fff',
          boxShadow: 'none',
          position: 'relative',
        }}
        component="nav"
      >
        <Toolbar sx={{ display: 'flex', width: '100%' }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' }, color: '#999' }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            onClick={() => handleNavigation('Home')}
            variant="h6"
            component="div"
            sx={{
              flex: '33.333%',
              flexGrow: 1,
              display: { xs: 'block', sm: 'block' },
            }}
          >
            <img
              style={{
                width: '107px',
                height: '47px',
                cursor: 'pointer',
                display: 'inline-block',
                zIndex: '333',
              }}
              src="https://adityatrading.in/static/images/logo.png"
              alt="Logo"
            />
          </Typography>
          <Box
            sx={{
              display: { xs: 'none', sm: 'flex' },
              justifyContent: 'space-around',
              flex: '33.333%',
            }}
          >
            {navItems.map((item) => (
              <Button
                key={item}
                onClick={() => handleNavigation(item)}
                sx={{
                  color: '#36B864',
                  padding: '0px',
                  textTransform: 'none',
                  fontWeight: '600',
                  lineHeight: '24.5px',
                }}
              >
                <Typography
                  sx={{
                    fontWeight: '400',
                    fontStyle: 'poppins',
                    fontSize: '16px',
                  }}
                >
                  {item}
                </Typography>
              </Button>
            ))}
          </Box>
          <Box
            sx={{
              display: { xs: 'none', sm: 'none', md: 'flex' },
              justifyContent: 'space-around',
              flex: '25.333%',
            }}
          >
            <Button
              variant="contained"
              sx={{
                border: '1px solid #36B864',
                color: '#FFFFFF',
                width: '129px',
                fontWeight: '500',
                height: '31px',
                padding: '0px',
                textTransform: 'none',
                textAlign: 'right !important',
                backgroundColor: '#3DAC5A',
                '&:hover': {
                  backgroundColor: '#3DAC5A',
                },
              }}
              onClick={handleCreateAccount}
            >
              Create Account
            </Button>
            <Button
              variant="outlined"
              sx={{
                border: '1px solid #36B864',
                color: '#000000B2',
                width: '81px',
                height: '31px',
                fontSize: '14px',
                padding: '0px',
                textTransform: 'none',
                textAlign: 'right !important',
              }}
              onClick={handleLogin}
            >
              Log in
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
    </Box>
  );
}

CareerNavbar.propTypes = {
  window: PropTypes.func,
};

export default CareerNavbar;
