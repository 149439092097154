import React, { useEffect, useState } from 'react';
import './Component.css';
import { ArrowDownward } from '@mui/icons-material';
import { useNavigate } from 'react-router';
import { useGetAnalystQuery } from '../redux/Analyst';
import { setTokenID } from '../reduxslices/tokenSlices';
import { useDispatch } from 'react-redux';
import { useTheme } from '@emotion/react';
import { useMediaQuery } from '@mui/system';

const Shortterm = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const handleNavigation = (companyName, tokenID) => {
    console.log("IntraDay ----->>>>>>", tokenID);
    dispatch(setTokenID({ companyName, tokenID }));
    navigate(`/companyPage/${companyName}`);
  };


  const [socket, setSocket] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null); // Track the selected index
  const [wsData, setWsData] = useState([]);
  const handleDetails = (index) => {
    setSelectedIndex((prevIndex) => (prevIndex === index ? null : index)); // Toggle the selected index
  };

  const theme = useTheme();

  // Add a fallback media query string if theme.breakpoints or down method is not available
  const isMobile = useMediaQuery(theme?.breakpoints?.down('sm') || '(max-width:600px)');


  const { data: analystdata } = useGetAnalystQuery();
  const intraday = analystdata['equityShortTerm'] || []; // Handle case where intraday might be undefined

  useEffect(() => {
    const ws = new WebSocket('wss://neuronsoft.in/ws/watchlist/companyInfo/');

    ws.onopen = () => {
      console.log('WebSocket connection opened.');
      // Send subscription messages for all tokenIDs
      if (intraday.length > 0) {
        intraday.forEach((stock) => {
          const message = JSON.stringify({
            event: 'addTicker',
            token: stock.tokenID,
          });
          ws.send(message);
          console.log(`Sent tokenID ${stock.tokenID} to WebSocket.`);
        });
      }
    };

    ws.onmessage = (event) => {
      const data = JSON.parse(event.data);

      setWsData((prevData) => {
        const updatedData = prevData.filter(
          (item) => item.tokenID !== data.tokenID
        );
        updatedData.push(data);
        return updatedData;
      });
    };

    ws.onclose = () => console.log('WebSocket connection closed.');
    ws.onerror = (error) => console.log('WebSocket error:', error);

    setSocket(ws);

    return () => {
      ws.close();
    };
  }, [intraday]);

  const combinedStocks = intraday.map((stock) => {
    const updatedStock = wsData.find((data) => data.tokenID === stock.tokenID);
    return updatedStock ? { ...stock, ...updatedStock } : stock;
  });

  return (
    <div>
      <div className="politicalnewscontainer">
        {combinedStocks.map((intra, index) => (
          <div key={index}
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              margin: '-12px'
            }}>
            <div
              style={{
                padding: '0.7rem',
                marginTop: '0.5rem',
                boxShadow: '0 0.125rem 0.25rem rgba(0, 0, 0, 0.075)',
                borderRadius: '0.25rem',
                width: '100%',
                boxSizing: 'border-box'
              }}>
              <div className="content">
                <div className="content-img">
                  <div className="imge">
                    <img src={intra.logoUrl} alt="Nifty" style={{ maxWidth: '100%', maxHeight: '100%' }} />
                  </div>
                  <div>
                    <p className="nifty" style={{ margin: 0 }}>{intra.ticker_code}</p>
                    <p className="opencls" style={{ margin: 0 }}>{intra.status}</p>
                    <p className="opencls" style={{ margin: 0 }}>{intra.tokenID}</p>
                  </div>
                </div>

                <div className="butn" onClick={() => handleNavigation(intra.ticker_code, intra.tokenID)}>
                  <p style={{ padding: '0 12px', backgroundColor: '#d1ffdd', color: '#34a853', fontSize: '12px', cursor: 'pointer', borderRadius: '8px', height: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: '8px' }}>
                    {intra.orderside}
                  </p>
                </div>

                <div className="cmp" style={{ marginLeft: isMobile ? '0' : 'auto', marginTop: isMobile ? '10px' : '0' }}>
                  <p style={{ marginTop: '10px', fontSize: '0.783rem', fontFamily: 'sans-serif', color: '#777D74' }}>
                    CMP: <span style={{ fontSize: '0.785rem', fontWeight: 600 }}>{intra.LTP || 'N/A'}</span>
                  </p>
                  <div className="rednumber" style={{ display: 'flex', justifyContent: 'start' }}>
                    <p className={intra.Change <= 0 ? 'red-text' : 'green-text'} style={{ fontSize: '12px', marginRight: '5px' }}>
                      {intra.Change !== undefined ? intra.Change.toFixed(2) : ''}
                    </p>
                    <p className={intra.percentageChange <= 0 ? 'red-text' : 'green-text'} style={{ fontSize: '12px', margin: 0 }}>
                      {intra.percentageChange !== undefined ? `(${intra.percentageChange})` : 'N/A'}
                    </p>
                  </div>
                </div>

                <p onClick={() => handleDetails(index)} className="icn" style={{ padding: '0.2rem', display: 'flex', alignItems: 'center' }}>
                  <ArrowDownward sx={{ height: '15px' }} />
                </p>
              </div>

              {selectedIndex === index && (
                <div style={{ display: 'flex', marginTop: '10px' }}>
                  <table style={{ width: '100%', color: '#212529' }}>
                    <thead>
                      <tr><th></th><th></th><th></th><th></th></tr>
                    </thead>
                    <tbody className="tdsint">
                      <tr>
                        <td><p>Date: {new Date(intra.created).toLocaleDateString()}</p></td>
                        <td><p>Entry Price: {intra.entryPrice}</p></td>
                        <td><p>Target: {intra.targetPrice}</p></td>
                        <td><p>Stop Loss: {intra.stopLossPrice}</p></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Shortterm;
