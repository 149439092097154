import React, { useEffect, useState } from 'react';
import { useUserprofileQuery } from '../../redux/Userprofile';
import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
} from '@mui/material';
import Paper from '@mui/material/Paper';
import SelectButton from '../../Buttons/SelectButton';
import AddBankModel from './AddBankModel';

const Bank = () => {
  const [localProfile, setLocalProfile] = useState(null); 
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const storedUserProfile = JSON.parse(localStorage.getItem('usersProfileData'));
    if (storedUserProfile) {
      console.log("Loaded profile from localStorage:", storedUserProfile);
      setLocalProfile(storedUserProfile);
    }
  }, []);

  const bankData = Array.isArray(localProfile?.bank_info) ? localProfile.bank_info : [];

  const handleBankOpen = () => {
    setOpen(true);
  };

  const handleBankClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <div style={{ position: 'relative', top: '-15px' }} className="table-responsive">
        <div style={{ display: 'flex', justifyContent: 'end' }}>
          <SelectButton onClick={handleBankOpen}>Add Bank</SelectButton>
        </div>
        <TableContainer component={Paper} className="table-responsive">
          <Table aria-label="simple table" className="tables" id="blank">
            <TableHead sx={{ backgroundColor: '#34a853', color: '#fff' }}>
              <TableRow>
                <TableCell style={{ fontSize: '15px', width: '200px' }}>Account Holder</TableCell>
                <TableCell align="right" style={{ fontSize: '15px', width: '150px' }}>Bank Name</TableCell>
                <TableCell align="right" style={{ fontSize: '15px', width: '150px' }}>MICR</TableCell>
                <TableCell align="right" style={{ fontSize: '15px', width: '150px' }}>IFSC</TableCell>
                <TableCell align="right" style={{ fontSize: '15px', width: '150px' }}>Account Type</TableCell>
                <TableCell align="right" style={{ fontSize: '15px', width: '150px' }}>Account No</TableCell>
                <TableCell align="right" style={{ fontSize: '15px', width: '150px' }}>Branch</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {bankData.length > 0 ? (
                bankData.map((bank, index) => (
                  <TableRow
                    key={index}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row" style={{ fontSize: '12px', width: '200px' }}>
                      {bank.account_holder_name || 'N/A'}
                    </TableCell>
                    <TableCell align="right" style={{ fontSize: '12px', width: '200px' }}>
                      {bank.bank_name || 'N/A'}
                    </TableCell>
                    <TableCell align="right" style={{ fontSize: '12px', width: '200px' }}>
                      {bank.micr_code || 'N/A'}
                    </TableCell>
                    <TableCell align="right" style={{ fontSize: '12px', width: '200px' }}>
                      {bank.ifsc_code || 'N/A'}
                    </TableCell>
                    <TableCell align="right" style={{ fontSize: '12px', width: '200px' }}>
                      {bank.account_type || 'N/A'}
                    </TableCell>
                    <TableCell align="right" style={{ fontSize: '12px', width: '200px' }}>
                      {bank.account_number || 'N/A'}
                    </TableCell>
                    <TableCell align="right" style={{ fontSize: '12px', width: '200px' }}>
                      {bank.branch_name || 'N/A'}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    No data available
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <AddBankModel open={open} handleClose={handleBankClose} />
      </div>
    </div>
  );
}

export default Bank;
