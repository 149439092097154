import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const ticketApi = createApi({
    reducerPath: 'ticketApi',
    baseQuery: fetchBaseQuery({ baseUrl: 'https://server.adityatrading.in/' }),
    tagTypes: ['createTicketNeuron'],
    endpoints: (builder) => ({
        createTicketNeuron: builder.mutation({
            query: ({
                client_code,
                date,
                description,
                subject,
            }) => ({
                method: 'POST',
                url: 'crm/create-ticket/',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    client_code,
                    date,
                    description,
                    employee_id: "3339",
                    group_id: "",
                    owner: "9999",
                    source: "WEB",
                    subject,
                    thread_id: "",
                    ticket_type: "",
                }),
            })
        }),
    }),
});

export const { useCreateTicketNeuronMutation } = ticketApi;
export default ticketApi;

