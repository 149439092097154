// import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query';
// import { AnalystOpinion_Stocks_API } from '../Api'; // Ensure this path is correct
// import { BASE_URL } from './baseurl'; // Ensure this path is correct

// export const AnalystOpinionApi = createApi({
//   reducerPath: 'analystOpinion',
//   baseQuery: fetchBaseQuery({
//     baseUrl: BASE_URL,
//   }),
//   tagTypes: ['Analyst'],
//   endpoints: (builder) => ({
//     getAnalyst: builder.query({
//       query: () => ({
//         method: 'POST',
//         url: AnalystOpinion_Stocks_API,
//         headers: {
//           'Content-Type': 'application/json; charset=utf-8',
//         },
//       }),
//       providesTags: ['Analyst'],
//     }),
//   }),
// });

// export const { useGetAnalystQuery } = AnalystOpinionApi;
// export default AnalystOpinionApi;
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { AnalystOpinion_Stocks_API } from '.././Api';
import { SERVER_URL } from './baseurl';

export const AnalystStockAPI = createApi({
  reducerPath: 'analystApi',
  baseQuery: fetchBaseQuery({
    baseUrl: SERVER_URL,
  }),
  tagTypes: ['Analyst'],
  endpoints: (builder) => ({
    getAnalyst: builder.query({
      query: () => ({
        method: 'POST',
        url: AnalystOpinion_Stocks_API,
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
        },
      }),
      providesTags: ['Analyst'],
    }),
  }),
});

export const { useGetAnalystQuery } = AnalystStockAPI;

export default AnalystStockAPI;
