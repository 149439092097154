import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { SEARCH_URL } from './baseurl';

export const searchApi = createApi({
  reducerPath: 'searchApi',
  baseQuery: fetchBaseQuery({
    baseUrl: SEARCH_URL,
  }),
  tagTypes: ['search'],
  endpoints: (builder) => ({
    searchStocks: builder.mutation({
      query: (ticker) => ({
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
        },
        body: JSON.stringify({ ticker }), // Ensure body is stringified
      }),
      providesTags: ['search'], // Make sure this matches tagTypes
    }),
  }),
});

export const { useSearchStocksMutation } = searchApi;

export default searchApi;
