import React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/system';

const StyledButton = styled(Button)(({ theme, selected }) => ({
  borderRadius: '20px',
  padding: '0px 16px', // Corrected padding format
  color: '#1a1818',
  fontSize: '11px',
  fontWeight: 'normal',
  border: '1px solid rgba(0, 0, 0, 0.12)!important',
  '&.MuiButtonBase-root': {
    padding: '4px',
    marginLeft: '10px',
    marginBottom: '10px',
  },
  ...(selected && {
    color: '#34a853',
    backgroundColor: '#d1ffdd', // Background color when selected
    '&:hover': {
      backgroundColor: '#d1ffdd', // Maintain selected background color on hover
    },
  }),
  '&:not(.Mui-selected):hover': {
    backgroundColor: '#ffffff', // Hover color when not selected
    color: '#34a853',
  },
}));

const SelectButton = ({ onClick, selected, children }) => (
  <StyledButton onClick={onClick} selected={selected}>
    {children}
  </StyledButton>
);

export default SelectButton;
