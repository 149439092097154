import React, { useEffect, useState } from "react";
import LandingPage from "../LandingPage";
import { Button, Grid } from "@mui/material";
import FooterContent from "../Footer/FooterContent";
import "./CreateAlert.css";
import { useCreateAlertMutation } from "../redux/Alert";

import AlertModal from "./searchmodal";
import { Token } from "@mui/icons-material";
import { useSelector } from "react-redux";

const CreateAlert = () => {
  const [createAlertConnect, { isSuccess, isError }] = useCreateAlertMutation();
  const [stockName, setStockName] = useState("");
  const [triggerCondition, setTriggerCondition] = useState("PRICE_RISE_ABOVE");
  const [triggerValue, setTriggerValue] = useState("");
  const [alertFrequency, setAlertFrequency] = useState("ONLY_ONCE");
  const [alertName, setAlertName] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [modalOpen, setModalOpen] = useState(true);
  const [selectedStock, setSelectedStock] = useState({ name: "", tokenId: "" });
  const [csrfToken, setCsrfToken] = useState(null); // CSRF token state

  const tokenID = useSelector((state) => state.token.tokenID);
  const companyName = useSelector((state) => state.token.companyName);


  const fetchCsrfToken = async () => {
    try {
      const response = await fetch('http://127.0.0.1:8000/csrf/', {
        method: 'GET',
        credentials: 'include',  // Ensure credentials are included in the request
        headers: {
            'Content-Type': 'application/json',
        }
    })
    
      const data = await response.json();
      setCsrfToken(data.csrfToken); // Store CSRF token in state
      return data.csrfToken;
    } catch (error) {
      console.error('Error fetching CSRF token:', error);
      return null;
    }
  };

  useEffect(() => {
    fetchCsrfToken(); // Fetch CSRF token when component mounts
  }, []);

  const handleStockSelect = (companyName, tokenID) => {
    console.log("Selected Stock:", companyName, tokenID);
    if (typeof companyName === 'string') {
      setStockName(companyName);
    } else {
      setStockName("");
    }
    setSelectedStock({ name: companyName, tokenId: tokenID });
    setModalOpen(false);
  };


  const handleOpenModal = () => {
    setModalOpen(true);
  };

  async function makeAlert() {
    console.log("makeAlert hit --");

    const alertData = {
      exchange: "NSE",
      frequency: alertFrequency,
      segment: "A",
      ticker_code: selectedStock.name.companyName,
      tokenID: selectedStock.name.tokenID,
      triggerName: alertName,
      type: triggerCondition,
      value: triggerValue,
    };

    if (
      selectedStock.name.companyName.trim() &&
      triggerCondition.trim() &&
      triggerValue.trim() &&
      alertFrequency.trim() &&
      alertName.trim()
    ) {

      // const alertData = {
      //   exchange: "NSE",
      //   frequency: alertFrequency,
      //   segment: "EQUITY",
      //   ticker_code: selectedStock.name.companyName,
      //   tokenID: selectedStock.name.tokenID,
      //   triggerName: alertName,
      //   type: triggerCondition,
      //   value: triggerValue,
      // };

      console.log("CSRF Token ->", tokenID);
      console.log("Alert Data - -> ", alertData);

      try {
        const response = await createAlertConnect({
          exchange: "NSE",
          frequency: alertFrequency,
          segment: "A",
          ticker_code: selectedStock.name.companyName,
          tokenID: selectedStock.name.tokenID,
          triggerName: alertName,
          type: triggerCondition,
          value: triggerValue,
        }).unwrap();
        console.log("Alert created successfully:", response);
        setErrorMessage("");
      } catch (error) {
        console.log("Alert Data - -> ", alertData);
        console.error("Error creating alert:", error);
        setErrorMessage("Failed to create alert. Please try again.");
      }
    } else {
      console.log("Alert Data - -> ", alertData);
      console.log("Please fill out all fields");
      setErrorMessage("Please fill out all fields");
    }
  }
  

  const handleCreateAlertClick = () => {
    console.log("Stock Name:", selectedStock.name.companyName);
    
    if (!selectedStock.name.companyName || !triggerCondition || !triggerValue || !alertFrequency || !alertName) {
      setErrorMessage("Please fill out all fields.");
      return;
    }
    makeAlert();
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  return (
    <div>
      <LandingPage />
      <div>
        <Button
          sx={{
            color: "#34a853!important",
            backgroundColor: "#d1ffdd!important",
            borderRadius: "50px",
            border: "1px solid #e5f2ff!important",
            padding: "8px",
            fontSize: "11px",
            fontWeight: 600,
            marginBottom: "10px",
            textTransform: "none",
            top: "100px",
            left: "50px !important",
          }}
          onClick={handleCreateAlertClick}
        >
          Create Alert
        </Button>
        <div className="content-page">
          <div className="container">
            <div className="rows">
              <div className="p-content">
                <p style={{ marginBottom: "0px", fontWeight: "bold" }}>
                  Free realtime market alerts
                </p>
                <p style={{ marginBottom: "0px", color: "#374948 !important" }}>
                  <small>
                    Create powerful market alerts on the cloud for stocks,
                    futures and options, and more for free.
                  </small>
                </p>
              </div>
            </div>

            {/* Form Elements */}
            {console.log("selectedStock:", selectedStock.name)}
            <div className="centre-content">
              <div className="form-inline">
                <label>Alert Me When</label>
                <input
                  className="search"
                  type="text"
                  placeholder="Stock Name"
                  value={selectedStock.name.companyName || ''}
                  onClick={handleOpenModal}
                  readOnly
                  style={{ textTransform: "uppercase" }}
                />
                <select
                  value={triggerCondition}
                  onChange={(e) => {
                    console.log("Selected condition:", e.target.value);
                    setTriggerCondition(e.target.value);
                  }}
                >
                  <option value="PRICE_RISE_ABOVE">Price rises above</option>
                  <option value="PRICE_DROPS_TO">Price drops to</option>
                  <option value="CHANGE_IS_OVER">Change is over</option>
                  <option value="CHANGE_IS_UNDER">Change is under</option>
                  <option value="24H_CHANGE_OVER">24H Change is over</option>
                  <option value="24H_CHANGE_IS_UNCER">24H Change is under</option>
                </select>

              </div>

              <div className="form-inline">
                <input
                  className="search"
                  type="text"
                  placeholder="Enter a Value"
                  value={triggerValue}
                  onChange={(v) => setTriggerValue(v.target.value)}
                  style={{ textTransform: "uppercase" }}
                />
                <select
                  value={alertFrequency}
                  onChange={(e) => {
                    console.log("Selected frequency:", e.target.value);
                    setAlertFrequency(e.target.value);
                  }}
                >
                  <option value="ONLY_ONCE">Only once</option>
                  <option value="ONCE_A_DAY">Once a day</option>
                  <option value="ALWAYS">Always</option>
                </select>

              </div>

              <div className="form-inline">
                <input
                  className="search"
                  type="text"
                  placeholder="Trigger Name"
                  value={alertName}
                  onChange={(e) => setAlertName(e.target.value)}
                  style={{ textTransform: "uppercase" }}
                />
              </div>
            </div>
            <div
              style={{
                position: "relative",
                paddingLeft: "15px",
                paddingRight: "15px",
                width: "100%",
                flex: "16.6666",
                maxWidth: "16.6666",
              }}
            ></div>
            <div
              style={{
                position: "relative",
                paddingLeft: "15px",
                paddingRight: "15px",
                width: "100%",
                flex: "16.6666",
                maxWidth: "16.6666",
                minHeight: "20px",
              }}
            ></div>
            <div
              style={{ float: "right", textAlign: "right" }}
              className="centre-content"
            >
              <Button
                onClick={() => {
                  console.log("Create Alert button clicked");
                  handleCreateAlertClick();
                }}
                sx={{
                  color: "#34a853!important",
                  backgroundColor: "#d1ffdd!important",

                  borderRadius: "50px",
                  border: "1px solid #e5f2ff!important",
                  paddingTop: "3px",
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  paddingBottom: "3px",
                  fontSize: "11px",
                  fontWeight: 600,
                  marginBottom: "10px",
                  marginLeft: "15px",
                  textTransform: "none",
                }}
              >
                Create
              </Button>
            </div>
            <div
              style={{
                position: "relative",
                paddingLeft: "15px",
                paddingRight: "15px",
                width: "100%",
                flex: "16.6666",
                maxWidth: "16.6666",
                minHeight: "20px",
              }}
            ></div>
            <div
              style={{
                border: "1px solid #b9b8b8",
                borderRadius: "4px",
                marginTop: "20px",
              }}
            >
              <div className="rows">
                <div style={{ flex: "0 0 50%", maxWidth: "50%" }}>
                  {" "}
                  <h6
                    style={{
                      marginBottom: "0px",
                      padding: "8px",
                      fontSize: "16px",
                      fontWeight: "600",
                      margin: "0px",
                      lineHeight: "1.5",
                      color: "##374948",
                      position: "relative",
                      left: "20px",
                    }}
                  >
                    My Triggers
                  </h6>
                </div>
                <div
                  style={{
                    flex: "0 0 50%",
                    maxWidth: "50%",
                    float: "right",
                    textAlign: "right",
                  }}
                >
                  <Button
                    sx={{
                      color: "#34a853!important",
                      border: "1px solid #e5f2ff!important",
                      paddingTop: "2px",
                      paddingLeft: "8px",
                      paddingRight: "8px",
                      paddingBottom: "2px",
                      fontSize: "11px",
                      fontWeight: 600,
                      position: "relative",

                      top: "7px",
                      textTransform: "none",
                    }}
                  >
                    Download
                  </Button>{" "}
                </div>
                <div
                  style={{
                    flex: " 0 0 100%",
                    maxwidth: "100%",
                    position: "relative",
                    width: "100%",
                    paddingLeft: "15px",
                    paddingRight: "15px",
                  }}
                >
                  <div className="table">
                    <table style={{ width: "100%" }}>
                      <thead>
                        <tr>
                          <th
                            style={{
                              fontSize: "12px",
                              fontWeight: "normal",
                              textAlign: "left",
                            }}
                          >
                            Name
                          </th>
                          <th
                            style={{
                              fontSize: "12px",
                              fontWeight: "normal",
                              textAlign: "left",
                            }}
                          >
                            Triggered
                          </th>
                          <th
                            style={{
                              fontSize: "12px",
                              fontWeight: "normal",
                              textAlign: "left",
                            }}
                          >
                            Status
                          </th>
                          <th
                            style={{
                              fontSize: "12px",
                              fontWeight: "normal",
                              textAlign: "left",
                            }}
                          >
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td colSpan="4" style={{ textAlign: "center", padding: 15 }}>
                            No data available
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AlertModal open={modalOpen} onClose={() => setModalOpen(false)} onStockSelect={handleStockSelect} />
      <Grid item sx={{ top: "70px", position: "relative" }} xs={12} md={6}>
        <FooterContent />
      </Grid>
    </div>
  );
};

export default CreateAlert;
