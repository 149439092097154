import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { SERVER_URL } from "./baseurl";
import { USER_PROFILE_API } from ".././Api";
import { Token } from "@mui/icons-material";

export const userProfileApi = createApi({
  reducerPath: 'userProfileApi',
  baseQuery: fetchBaseQuery({
    baseUrl: SERVER_URL,
  }),
  endpoints: (builder) => ({
    userprofile: builder.query({
      query: (clientCode) => ({
        method: 'GET',
        url: USER_PROFILE_API,
        params: {
          // clientCode,
          sessionStorage,
          // Token,
        },
      }),
    }),
  }),
});

export const { useUserprofileQuery } = userProfileApi;

export default userProfileApi;

