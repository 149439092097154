import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { useMediaQuery } from '@mui/system';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const images = [
  {
    label: 'San Francisco – Oakland Bay Bridge, United States',
    imgPath: require('../Assets/sliderimage1.png'), // Ensure this path is correct
  },
  {
    label: 'Bird',
    imgPath: require('../Assets/sliderimage2.png'), // Ensure this path is correct
  },
];

function Carousel() {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = images.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => (prevActiveStep + 1) % maxSteps);
  };

  const handleBack = () => {
    setActiveStep(
      (prevActiveStep) => (prevActiveStep - 1 + maxSteps) % maxSteps
    );
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  const handleImageClick = () => {
    window.location.href = 'https://kyc.adityatrading.in/';
  };

  // Check if screen size is mobile (xs breakpoint)
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      sx={{
        maxWidth: 1000,
        flexGrow: 1,
        display: 'initial',
        position: 'relative',
        top: '50px',
        margin: 'auto',
      }}
    >
      <AutoPlaySwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        {images.map((step, index) => (
          <div key={step.label}>
            {Math.abs(activeStep - index) <= 2 ? (
              <Box component="div" onClick={() => handleImageClick()}>
                <Box
                  component="img"
                  sx={{
                    width: '100%',
                    height: {
                      xs: '150px', // For mobile view, adjust image size
                      sm: '360px', // Larger image size for web view
                    },
                    objectFit: 'cover',
                  }}
                  src={step.imgPath}
                  alt={step.label}
                />
              </Box>
            ) : null}
          </div>
        ))}
      </AutoPlaySwipeableViews>

      {/* Arrow Buttons */}
      {!isMobile && ( // Only show the arrow buttons on larger screens (not on mobile)
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            backgroundColor: 'inherit',
            zIndex: 1,
            // On mobile, the arrows should be outside the carousel
            [theme.breakpoints.down('sm')]: {
              display: 'none', // Hide the arrows on mobile view
            },
          }}
        >
          {/* Back Button */}
          <Button
            sx={{
              position: 'absolute',
              left: '0px',
              top: '50%',
              transform: 'translateY(-40%)',
              backgroundColor: 'rgba(0, 0, 0, 0)',
              borderRadius: '60%',
              width: { xs: '15px', sm: '30px' }, // Smaller size for mobile
              height: { xs: '25px', sm: '30px' }, // Smaller size for mobile
              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0)',
              },
            }}
            size="small"
            onClick={handleBack}
          >
            {theme.direction === 'rtl' ? (
              <KeyboardArrowRight sx={{ color: ' #000000' }} />
            ) : (
              <KeyboardArrowLeft sx={{ color: ' #000000' }} />
            )}
          </Button>


        </Box>
      )}
      {/* Next Button */}
      <Button
        sx={{
          position: 'absolute',
          right: '10px',
          top: '50%',
          transform: 'translateY(-50%)',
          backgroundColor: 'rgba(0, 0, 0, 0)',
          borderRadius: '50%',
          width: { xs: '15px', sm: '30px' }, // Smaller size for mobile
          height: { xs: '15px', sm: '30px' }, // Smaller size for mobile
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0)',
          },
        }}
        size="small"
        onClick={handleNext}
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowLeft sx={{ color: ' #000000' }} />
        ) : (
          <KeyboardArrowRight sx={{ color: ' #000000' }} />
        )}
      </Button>
    </Box>
  );
}

export default Carousel;