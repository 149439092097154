import React from 'react';

const Reactivation = () => {
  return (
    <div>
      <p>
        Please Click the button to Continue Reactivation{' '}
        <a href="https://reactivation.adityatrading.in/" target="_blank">
          Click here to proceed
        </a>
      </p>
    </div>
  );
};

export default Reactivation;
