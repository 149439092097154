// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* BasicServicesTable.css */

.table-container {
  background-color: #ffffff;
  padding: 1rem;
}

.table-wrapper {
  max-width: 1024px;
  margin: 0 auto;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 1rem;
  overflow-x: hidden; /* No scrolling by default */
  overflow-y: hidden;
}

.table-wrapper h1 {
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  padding: 0;
}

.table {
  width: 100%;
  border-collapse: collapse;
  background-color: white;
  border: none;
}

.table th, .table td {
  padding: 0.75rem;
  border: 1px solid #ddd;
  text-align: left;
}

.table th {
  background-color: #f1f5f9;
  font-weight: 600;
}

.table td {
  background-color: #fff;
}

.table-row:hover {
  background-color: #f9fafb;
}

/* Media Query for Mobile View */
@media (max-width: 770px) {
  .table-wrapper {
    overflow-x: auto; /* Enable horizontal scrolling on mobile */
  }

  .table {
    min-width: 800px; /* Prevent table from collapsing */
    display: block; /* Allow table to scroll */
  }
}
`, "",{"version":3,"sources":["webpack://./src/UiPages/Investorcharter/dp.css"],"names":[],"mappings":"AAAA,2BAA2B;;AAE3B;EACE,yBAAyB;EACzB,aAAa;AACf;;AAEA;EACE,iBAAiB;EACjB,cAAc;EACd,sBAAsB;EACtB,wCAAwC;EACxC,kBAAkB;EAClB,aAAa;EACb,kBAAkB,EAAE,4BAA4B;EAChD,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,qBAAqB;EACrB,UAAU;AACZ;;AAEA;EACE,WAAW;EACX,yBAAyB;EACzB,uBAAuB;EACvB,YAAY;AACd;;AAEA;EACE,gBAAgB;EAChB,sBAAsB;EACtB,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;EACzB,gBAAgB;AAClB;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA,gCAAgC;AAChC;EACE;IACE,gBAAgB,EAAE,0CAA0C;EAC9D;;EAEA;IACE,gBAAgB,EAAE,kCAAkC;IACpD,cAAc,EAAE,0BAA0B;EAC5C;AACF","sourcesContent":["/* BasicServicesTable.css */\r\n\r\n.table-container {\r\n  background-color: #ffffff;\r\n  padding: 1rem;\r\n}\r\n\r\n.table-wrapper {\r\n  max-width: 1024px;\r\n  margin: 0 auto;\r\n  background-color: #fff;\r\n  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\r\n  border-radius: 8px;\r\n  padding: 1rem;\r\n  overflow-x: hidden; /* No scrolling by default */\r\n  overflow-y: hidden;\r\n}\r\n\r\n.table-wrapper h1 {\r\n  font-size: 1.25rem;\r\n  font-weight: 600;\r\n  margin-bottom: 0.5rem;\r\n  padding: 0;\r\n}\r\n\r\n.table {\r\n  width: 100%;\r\n  border-collapse: collapse;\r\n  background-color: white;\r\n  border: none;\r\n}\r\n\r\n.table th, .table td {\r\n  padding: 0.75rem;\r\n  border: 1px solid #ddd;\r\n  text-align: left;\r\n}\r\n\r\n.table th {\r\n  background-color: #f1f5f9;\r\n  font-weight: 600;\r\n}\r\n\r\n.table td {\r\n  background-color: #fff;\r\n}\r\n\r\n.table-row:hover {\r\n  background-color: #f9fafb;\r\n}\r\n\r\n/* Media Query for Mobile View */\r\n@media (max-width: 770px) {\r\n  .table-wrapper {\r\n    overflow-x: auto; /* Enable horizontal scrolling on mobile */\r\n  }\r\n\r\n  .table {\r\n    min-width: 800px; /* Prevent table from collapsing */\r\n    display: block; /* Allow table to scroll */\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
