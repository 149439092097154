import React, { useEffect, useState } from "react";
import {
  Container,
  Box,
  Typography,
  Button,
  TextField,
  Select,
  MenuItem,
  InputAdornment,
} from "@mui/material";
import { CalendarToday } from "@mui/icons-material";
import { usePostNomineeMutation } from "../../redux/NomineeAdd";
import { isValid, isBefore, isAfter } from 'date-fns';
import { useGetTokenMutation } from "../../redux/UsersPersonalData";

const today = new Date();

function NomineeCard({nomineesLength}) {
  const [type, setType] = useState("");
  const [relationship, setRelationship] = useState("");
  const [nomineeName, setNomineeName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [dob, setDob] = useState("");
  const [percentage, setPercentage] = useState("");
  const [address, setAddress] = useState("");
  const [pinCode, setPinCode] = useState("");
  const [idType, setIdType] = useState("");
  const [idNumber, setIdNumber] = useState("");
  const [localProfile, setLocalProfile] = useState(null);
  const [profileToken,setProfileToken] = useState();
  const [getToken] = useGetTokenMutation();
  //const [postNomineeData, { data, isLoading, error }] = usePostNomineeMutation();


  useEffect(() => {
    if (nomineesLength !== undefined) {
      setType(`NOMINEE_${nomineesLength + 1}`);
    }
    console.log("Nominee type we are parsing -- ",type);
  }, [nomineesLength]);

  useEffect(() => {
    const fetchToken = async () => {
      try {
        console.log("Attempting to fetch token...");
        const response = await getToken().unwrap();
        console.log("Token fetched successfully:", response);
        setProfileToken(response.access); 
      } catch (error) {
        console.error('Failed to fetch token:', error);
      }
    };
    fetchToken(); 
  }, [getToken]);

  useEffect(() => {
    const storedUserProfile = JSON.parse(localStorage.getItem('usersProfileData'));
    if (storedUserProfile) {
      console.log("Loaded profile from localStorage:", storedUserProfile);
      setLocalProfile(storedUserProfile);
    }
    console.log("Local Data -- nominee ", localProfile);
  }, []);

  const handleDateChange = (event) => {
    const enteredDate = event.target.value;
    const [day, month, year] = enteredDate.split('-');
    const formattedDate = new Date(`${year}-${month}-${day}`);
  
    if (!isValid(formattedDate)) {
      console.log("Invalid date format");
      return; 
    }
  
    if (isBefore(formattedDate, today.getTime() + 1)) {
      setDob(enteredDate); 
    } else {
      console.log("Date cannot be after today");
    }
  };
  

  const handleSubmit = async (event) => {
    event.preventDefault();
    const nomineeDetails = {
      relationship,
      nomineeName,
      email,
      phoneNumber,
      dob,
      percentage,
      address,
      pinCode,
      idType,
      idNumber
    };
    console.log("Nominee Details Submitted:", nomineeDetails);
    try {
      //const response = await postNomineeData(nomineeDetails, profileToken, type).unwrap();
      // if (response.status === 'success') {
      //   alert('Nomniee added successfully!');
      //   console.log("Response we are getting -- ", response.message);
      // } else {
      //   alert(response.message);
      //   console.log(response.message);
      // }
    } catch (error) {
      console.error('Failed to add segment:', error);
      alert('Failed to add segment, please try again.');
      console.log("why this error?? ==> ", error.data);
    }
  };

  return (
    <Container
      maxWidth="sm"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        backgroundColor: "white",
      }}
    >
      <Box
        bgcolor="white"
        p={4}
        borderRadius={2}
        boxShadow={3}
        width="100%"
        maxWidth="lg"
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
        >
          <Typography variant="h6" color="primary">
            Hey <strong>{localProfile?.user?.client_name || "User"}</strong>
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Having troubles?{" "}
            <span style={{ color: "#4caf50", cursor: "pointer" }}>
              Get Help
            </span>
          </Typography>
        </Box>
        <Typography variant="h5" mb={2} fontWeight="bold">
          Nominee Details
        </Typography>
        <form onSubmit={handleSubmit}>
          <Box mb={2}>
            <Select
              fullWidth
              value={relationship || "Relationship"}
              onChange={(e) => setRelationship(e.target.value)}
            >
              <MenuItem value="Relationship">Relationship</MenuItem>
              <MenuItem value="Mother">Mother</MenuItem>
              <MenuItem value="Father">Father</MenuItem>
              <MenuItem value="Brother">Brother</MenuItem>
              <MenuItem value="Sister">Sister</MenuItem>
              <MenuItem value="Daughter">Daughter</MenuItem>
              <MenuItem value="Son">Son</MenuItem>
              <MenuItem value="Spouse">Spouse</MenuItem>
              <MenuItem value="Grand Daughter">Grand Daughter</MenuItem>
              <MenuItem value="Grand Son">Grand Son</MenuItem>
              <MenuItem value="Grand Mother">Grand Mother</MenuItem>
              <MenuItem value="Grand Father">Grand Father</MenuItem>
            </Select>
          </Box>
          <Box mb={2}>
            <TextField
              fullWidth
              variant="outlined"
              value={nomineeName || ""}
              onChange={(e) => setNomineeName(e.target.value)}
              label="Nominee Name"
              required
            />
          </Box>
          <Box mb={2}>
            <TextField
              fullWidth
              variant="outlined"
              value={email || ""}
              onChange={(e) => setEmail(e.target.value)}
              label="Email"
              type="email"
              required
            />
          </Box>
          <Box mb={2}>
            <TextField
              fullWidth
              variant="outlined"
              value={phoneNumber || ""}
              onChange={(e) => setPhoneNumber(e.target.value)}
              label="Phone Number"
              required
            />
          </Box>
          <Box mb={2}>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="mm-dd-yyyy"
              value={dob || ""}
              onChange={handleDateChange}
              label="Date of Birth"
              required
              error={dob && isAfter(new Date(dob), today)}
              helperText={dob && isAfter(new Date(dob), today) ? 'Date cannot be after today' : ''}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <CalendarToday color="action" />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box mb={2}>
            <TextField
              fullWidth
              variant="outlined"
              value={percentage  || ""}
              onChange={(e) => setPercentage(e.target.value)}
              label="Percentage"
              required
            />
          </Box>
          <Box mb={2}>
            <TextField
              fullWidth
              variant="outlined"
              value={address || ""}
              onChange={(e) => setAddress(e.target.value)}
              label="Address"
              required
            />
          </Box>
          <Box mb={2}>
            <TextField
              fullWidth
              variant="outlined"
              value={pinCode || ""}
              onChange={(e) => setPinCode(e.target.value)}
              label="PIN Code"
              required
            />
          </Box>
          <Box mb={2}>
            <Select
              fullWidth
              value={idType || "AADHAR_NUM"}
              onChange={(e) => setIdType(e.target.value)}
            >
              <MenuItem value="AADHAR_NUM">Aadhaar Number</MenuItem>
              <MenuItem value="PAN_NUM">Pan Number</MenuItem>
              <MenuItem value="ACC_NUM">Account Number</MenuItem>
              <MenuItem value="DEMAT_ID">Demat Id</MenuItem>
            </Select>
          </Box>
          <Box mb={2}>
            <TextField
              fullWidth
              variant="outlined"
              value={idNumber || ""}
              onChange={(e) => setIdNumber(e.target.value)}
              label="ID Number"
              required
            />
          </Box>
          <Box display="flex" justifyContent="space-between">
            {/* <Button variant="contained" color="error">
              Back
            </Button> */}
            <Button type="submit" variant="contained" color="success">
              Process Esign
            </Button>
          </Box>
        </form>
      </Box>
    </Container>
  );
};

export default NomineeCard;
