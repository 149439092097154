import React from "react";
import { Box, Typography } from "@mui/material";

const RiskManagementPolicy = () => {
  return (
    <>
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
        {/* Text Section */}
        <div className="lg:col-span-2">
          <h2 className="text-base font-bold mb-4">
            Collection of Margin Amount
          </h2>
          <ul className="list-disc list-outside pl-5 space-y-4">
            {/* First two list items aligned with the image */}
            <li className="text-justify">
              All margins as specified by Exchanges are collected in advance
              from clients before positions can be taken by client. Clients are
              required to have sufficient balance in their accounts to hold/
              carry forward positions.
            </li>
            <li className="text-justify">
              Margin Limit is set for trading based only on cleared funds and
              not given against Cheques in Transit. Payments/Transfers will be
              accepted only from the client’s registered bank account.
            </li>
            <li className="text-justify">
              Positions held without sufficient margin funds will be squared off
              any time at the discretion of the ATS RMS Team. There may not be
              margin calls or intimation from our RMS desk, especially when the
              market is panic or very volatile.
            </li>
          </ul>
        </div>

        {/* Image Section */}
        <div className="lg:col-span-1 flex justify-center items-start mt-12">
          <img
            src="https://adityatrading.in/static/images/terms-and-conditions.svg"
            alt="Illustration of people checking off a list on a large document"
            className="w-full lg:w-auto"
          />
        </div>

        {/* Remaining list items */}

        <div className="lg:col-span-3">
          <ul className="list-disc list-outside pl-5 space-y-4">
            <li className="text-justify">
              ATS reserves the right to call for additional funds in the cases
              of heavy volatility in the market and can square off such
              positions for want of margin.
            </li>
            <li className="text-justify">
              In case at any point of time, if the client ledger goes debit due
              to market volatility or higher positions or dishonor of Pay in
              Cheques; client will be responsible to pay the dues to clear the
              outstanding in his/her ledger.
            </li>
          </ul>

          <h2 className="text-base font-bold mb-4 mt-8">
            Setting of Exposure Limit
          </h2>
          <ul className="list-disc list-outside pl-5 space-y-4">
            <li className="text-justify">
              ATS may provide exposure limit for intraday, for buying and
              selling based on ledger balance, stocks purchased, value of
              securities and collaterals computed after appropriate haircut (as
              decided by ATS from time to time). Collateral will be valued on a
              daily basis at latest / previous day’s closing price and
              appropriate hair-cut shall be applicable.
            </li>
            <li className="text-justify">
              Securities that are acceptable as collateral and their haircut may
              be changed by ATS from time to time at its sole discretion
              depending upon the internal risk and surveillance criteria. The
              acceptable collateral and the applicable hair cut will be as per
              ATS’s internal risk and surveillance criteria, which may/ may not
              be as specified by the Regulators/Exchanges.
            </li>
            <li className="text-justify">
              PATS will provide collateral margin for those securities held in
              the DEMAT Account of the clients who has provided the Power Of
              Attorney (POA) in favor of ATS. Client will be able to use this
              entire margin after haircut for taking intraday or overnight
              positions in Futures, and for writing Options of equities,
              indices, and currencies. Collateral Margin will not be available
              for those shares held in non POA accounts.
            </li>
            <li className="text-justify">
              On the basis of available Funds and Collaterals, Margin Reporting
              of the Clients will be done to Exchanges and Shortfall Penalty if
              any will be debited in Clients ledger Statement. ATS also levy
              delayed payment charges on the shortage margin as per the rates
              decided by ATS from time to time.
            </li>
            <li className="text-justify">
              Option Premium received from writing/Selling options will not be
              considered as margin for taking additional positions.
            </li>
            <li className="text-justify">
              Intraday profit realized and MTM profit is not added to margin for
              taking new positions on the same day, as the same is not
              considered as accepted margin by exchanges for that day.
            </li>
          </ul>

          <h2 className="text-base font-bold mb-4 mt-8">
            Market Position Square off
          </h2>
          <ul className="list-disc list-outside pl-5 space-y-4">
            <li className="text-justify">
              MTM Square off:-All positions will be subject to square off if
              your losses exceed 60% of the available funds in your account.
              This will be done at the discretion of our RMS team and there will
              be no margin call especially when the market is volatile. However
              in Normal market, MTM warnings are sent to clients through SMS on
              MTM Loss reaching 60% and then at 77% and at 80% and also alerts
              are flashed in trading terminals. Once the MTM loss of the
              positions reach 80% of the deposits or funds available, the
              positions will be closed by RMS Department of ATS and client will
              be put in square off mode.
            </li>
          </ul>

          <h2 className="text-base font-bold mb-4 mt-8">
            Selling sequence when ATS does RMS selling:
          </h2>
          <ul className="list-disc list-outside pl-5 space-y-4">
            <li className="text-justify">
              The open position in F&O Segment will be squared off towards
              margin shortage.
            </li>
            <li className="text-justify">
              In case there is ledger debit still in client’s accounts,
              collateral stocks to the extent of ledger debit (including MTM)
              will be sold off.
            </li>
            <li className="text-justify">
              Client is updated that he will be placed on Square off mode in
              case Ledger debit is not cleared in Cash segment on T+5 days
              (working days)
            </li>
            <li className="text-justify">
              ATS may also sell off all or any securities of the Client lying
              with ATS as collateral or otherwise, for any amounts due by the
              Client and adjust the proceeds of such liquidation/close out
              against the client's liabilities/obligations to ATS. It is
              client’s obligation to clear his obligations on T+2 days (T
              indicates Trading day). The client shall timely provide funds /
              securities to ATS for the purchase / sale of securities for
              meeting his obligations to the Exchange. In case of client falling
              short of providing fund / securities, ATS has the right to close
              the positions / sell the clients securities with or without giving
              prior notice to client to the extent of ledger debit and / or to
              the extent of margin obligations. Any and all losses and financial
              charges on account of such liquidation/closing-out shall be
              charged to and borne by the client
            </li>
          </ul>

          <h2 className="text-base font-bold mb-4 mt-8">
            Selling sequence when ATS does RMS selling:
          </h2>
          <ul className="list-disc list-outside pl-5 space-y-4">
            <li className="text-justify">
              All Ledger debits more than 5 days have to be cleared by 2:30 PM
              on T+5th day. RMS selling in clients account will be done on T+5
              days (T indicates Trading day) for the Ledger debit on due basis.
              For example, if the position has been taken on Monday then the
              funds payment is due on Wednesday (T+2 day). Shares so bought on
              Monday will be withheld by ATS till the debit is cleared. If the
              funds are not received by Friday, shares will be sold on following
              Monday. Incase stock valuation falls below 20% of the total ledger
              debit, square off can be done even before T+5 days. Client is
              updated that he will be placed on Square off mode. In this regard,
              ATS shall not be responsible for any loss that incur to the client
              on individual basis. If client wishes to do any NEFT/IMPS/RTGS to
              clear the debit, then it should be done and funds have to be
              received by ATS before 2:30 pm on T+5th day.
            </li>
            <li className="text-justify">
              ATS shall from time to time classify a list of securities which
              are illiquid as per the list of illiquid securities notified on a
              periodic basis by the Stock Exchanges concerned and / or based on
              such internal criteria as ATS may deem fit. ATS has the discretion
              to reject execution of such orders based on its risk perception.
            </li>
            <li className="text-justify">
              ATS also reserves the right not to allow any trades or
              transactions in respect of certain securities or segments or
              orders/requests which may be below / above certain value /
              quantity as may be decided by ATS from time to time.
            </li>
            <li className="text-justify">
              In case of F & O segment, all the option contracts beyond the far
              month contract (i.e. having expiry period of more than 3 months
              from current date) will not have buy and sell limit due to its
              illiquid nature
            </li>
            <li className="text-justify">
              However in all above cases if client still wish to trade then the
              client needs to coordinate with the respective branch and the
              limit will be set by Head Office after analyzing the requirement.
            </li>
            <li className="text-justify">
              No Trading to take fresh positions is allowed in Contracts in
              Delivery Period in MCX NCDEX and before Corporate Action in
              NSE/BSE derivative segments
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default RiskManagementPolicy;
