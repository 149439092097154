import Hashids from 'hashids';


class HashidsEncoder {
    constructor() {
        this.hashids = new Hashids(
            '$%n@p$&$@x0n@t$',
            16,
            'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890'
        );
    }

    encodeClientID(clientCode) {
        // Assuming clientCode is a number
        // console.log("this is the phone number I am getting in HashID -->", clientCode)
        return this.hashids.encode(Number(clientCode));
    }

    decodeClientID(encodedID) {
        // Will return an array with a single number
        // console.log("This is the encoded phone number -->", encodedID);
        const decoded = this.hashids.decode(encodedID);
        return decoded;
    }
}

export default HashidsEncoder;
