import React, { useEffect, useState } from 'react';
import { useGetannualReportsMutation } from '../redux/annualreports';
import { useSelector } from 'react-redux';

const Annual = () => {
  const [getAnnualReports, { isLoading }] = useGetannualReportsMutation();
  const companyticker = useSelector((state) => state.token.companyName);
  const [reportData, setReportData] = useState([]);
  const [error, setError] = useState(null);
  console.log(reportData);
  useEffect(() => {
    const fetchReports = async () => {
      try {
        if (companyticker) {
          const response = await getAnnualReports({
            tickerCode: companyticker,
          });
          if (response.error) {
            setError('Failed to fetch data');
          } else {
            setReportData(response.data.data || []);
          }
        }
      } catch (err) {
        console.error('Error fetching data:', err);
        setError('An unexpected error occurred');
      }
    };

    fetchReports();
  }, [companyticker, getAnnualReports]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div
      style={{
        maxHeight: '450px',
        overflowY: 'auto',
        paddingLeft: '15px',
        paddingRight: '15px',
      }}
    >
      <table className="even" style={{ width: '100%' }}>
        <tbody>
          {reportData.length > 0 ? (
            [...reportData]
              .sort((a, b) => b.year - a.year) // Sort in descending order based on the year
              .map((report, index) => (
                <tr key={index}>
                  <td>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <div>
                        <span
                          style={{
                            fontSize: '15px',
                            color: '#3c4043',
                            fontWeight: '500',
                            marginBottom: '0px',
                            textTransform: 'capitalize',
                          }}
                        >
                          {report.reportName}
                        </span>
                        <div>
                          <span
                            style={{ borderColor: '#880002', color: '#880002' }}
                          >
                            {report.year}
                          </span>
                        </div>
                      </div>
                      <div>
                        <p
                          style={{
                            marginTop: '15px',
                            marginBottom: '15px',
                            textAlign: 'right',
                          }}
                        >
                          <a
                            href={report.redirectUrl}
                            style={{
                              color: '#34a853',
                              textDecoration: 'none',
                              backgroundColor: 'transparent',
                            }}
                          >
                            View
                          </a>
                        </p>
                      </div>
                    </div>
                  </td>
                </tr>
              ))
          ) : (
            <tr>
              <td>
                <p>No data found</p>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default Annual;
