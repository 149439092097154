// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.marquee-container {
  overflow: hidden;
  white-space: nowrap;
  box-sizing: border-box;
  position: relative;
  background: #f0f0f0;
  padding: 10px 0;
  margin-bottom: 20px;
  margin-top: 20px;
}

.marquee {
  display: inline-block;
  animation: marquee 250s linear infinite;
}

.marquee-item {
  display: inline-block;
  padding: 0 15px;
  font-size: 16px;
  color: #333;
  margin-right: 30px;
  white-space: nowrap;
}

@keyframes marquee {
  0% {
    transform: translateX(10%); /* Start off-screen to the right */
  }
  100% {
    transform: translateX(-100%); /* End off-screen to the left */
  }
}

.marquee-container:hover .marquee {
  animation-play-state: paused; /* Pause on hover */
}
`, "",{"version":3,"sources":["webpack://./src/styles.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,mBAAmB;EACnB,sBAAsB;EACtB,kBAAkB;EAClB,mBAAmB;EACnB,eAAe;EACf,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,qBAAqB;EACrB,uCAAuC;AACzC;;AAEA;EACE,qBAAqB;EACrB,eAAe;EACf,eAAe;EACf,WAAW;EACX,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE;IACE,0BAA0B,EAAE,kCAAkC;EAChE;EACA;IACE,4BAA4B,EAAE,+BAA+B;EAC/D;AACF;;AAEA;EACE,4BAA4B,EAAE,mBAAmB;AACnD","sourcesContent":[".marquee-container {\r\n  overflow: hidden;\r\n  white-space: nowrap;\r\n  box-sizing: border-box;\r\n  position: relative;\r\n  background: #f0f0f0;\r\n  padding: 10px 0;\r\n  margin-bottom: 20px;\r\n  margin-top: 20px;\r\n}\r\n\r\n.marquee {\r\n  display: inline-block;\r\n  animation: marquee 250s linear infinite;\r\n}\r\n\r\n.marquee-item {\r\n  display: inline-block;\r\n  padding: 0 15px;\r\n  font-size: 16px;\r\n  color: #333;\r\n  margin-right: 30px;\r\n  white-space: nowrap;\r\n}\r\n\r\n@keyframes marquee {\r\n  0% {\r\n    transform: translateX(10%); /* Start off-screen to the right */\r\n  }\r\n  100% {\r\n    transform: translateX(-100%); /* End off-screen to the left */\r\n  }\r\n}\r\n\r\n.marquee-container:hover .marquee {\r\n  animation-play-state: paused; /* Pause on hover */\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
