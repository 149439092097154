import React, { useState, useEffect } from 'react';
import LandingPage from '../../LandingPage';
import { Box, Button, Grid } from '@mui/material';
import FooterContent from '../../Footer/FooterContent';
import SelectButton from '../../Buttons/SelectButton';
import Personalinfo from './Personalinfo';
import Reactivation from './Reactivation';
import Brokerage from './Brokerage';
import Bank from './Bank';
import POA from './POA';
import MTF from './MTF';
import FATCA from './FATCA';
import Nominee from './Nominee';
import Pledgeunpludge from './Pledgeunpludge';
import Segment from './Segment';
import Closure from './Closure';
import Contract from './Contract';
import Annual from './Annual';
import Freeze from './Freeze';
import { useUserprofileQuery } from '../../redux/Userprofile';
import Cookies from 'js-cookie';
const getCsrfTokenFromCookie = () => {
  const csrfToken = Cookies.get("csrftoken");
  //console.log("This is cookie -> ", Cookies)
  console.log("CSRF Token:", csrfToken);
  return csrfToken || "NO CSRF TOKEN FOUND";
};

const UserProfile = () => {
  const [clientCode, setClientCode] = useState("");
  const [phonenumber, setPhoneNumber] = useState("");
  const [userName, setUserName] = useState("");
  const [selected, setSelected] = useState("");
  const { data: profileDatas } = useUserprofileQuery();
  console.log("this is the users data ---- ", profileDatas);

  useEffect(() => {
    var storedData = JSON.parse(localStorage.getItem("verifiedData"));
    if (storedData && storedData.data) {
      setClientCode(storedData.data);
      setPhoneNumber(storedData.Phone_Number);
      console.log(
        "=================================== storedData ===================================="
      );
      console.log(storedData);
    }
    // console.log("here is the datas", storedData.session);
    console.log("stored data - ", storedData);
  }, [clientCode, phonenumber]);

  const csrfToken = getCsrfTokenFromCookie();
  console.log("this is the csrf token ---> ", csrfToken);
  const userInfo = useUserprofileQuery(clientCode);
  // console.log("clientCode -- ", clientCode);
  // console.log("This is the user Info --- ",userInfo);

  console.log("clientcode -- ", clientCode);
  console.log("phone_number -- ", phonenumber);

  const renderComponent = () => {
    switch (selected) {
      case "Personal Info":
        return <Personalinfo />;
      case "Reactivation":
        return <Reactivation />;
      case "Brokerage Info":
        return <Brokerage />;
      case "Bank / DP":
        return <Bank />;
      case "Active POA / DDPI":
        return <POA mobileNumber={phonenumber} />;
      case "Active MTF":
        return <MTF mobileNumber={phonenumber} />;
      case "FATCA":
        return <FATCA />;
      case "Nominee":
        return <Nominee mobileNumber={phonenumber} clientCode={clientCode} />;
      case "PledgeUnpledge":
        return <Pledgeunpludge />;
      case "Segment Addition":
        return <Segment />;
      case "Closure":
        return <Closure mobileNumber={phonenumber} />;
      // case 'Contract':
      //   return <Contract />;
      case "Annual Income":
        return <Annual />;
      case "freeze-Unfreeze":
        return <Freeze clientCode={clientCode} />;
      default:
        return <Personalinfo />;
    }
  };

  return (
    <div>
      <LandingPage />
      <div
        style={{
          position: "relative",
          padding: "0px",
          margin: "0px",
          top: "90px",
        }}
      >
        {/* <Button
          sx={{
            color: "#34a853!important",
            backgroundColor: "#d1ffdd!important",
            borderRadius: "30px",
            border: "1px solid #e5f2ff!important",
            paddingTop: "10px",
            paddingLeft: "12px",
            paddingRight: "12px",
            paddingBottom: "10px",
            fontSize: "14px",
            fontWeight: 600,
            marginBottom: "10px",
            marginLeft: "15px",
            textTransform: "none",
            left: "100px",
          }}
        >
          Profile
        </Button> */}
        <Box sx={{ width: "90%", margin: "auto" }}>
          {/* User Information */}
          <Box sx={{ position: "relative", left: "8px", marginTop: "10px" }}>
            <h5>
              Hello <span style={{ color: "#34a853" }}>{clientCode}</span>
            </h5>
            {/* <h5>
      Client Code <span style={{ color: "#34a853" }}>{clientCode}</span>
    </h5> */}
          </Box>

          {/* Horizontal Scrollable Buttons */}
          <Box
            sx={{
              display: "flex",
              overflowX: "auto", // Enable horizontal scrolling
              gap: "10px", // Space between buttons
              padding: "10px 0",
              whiteSpace: "nowrap", // Prevent text wrapping
              alignItems: "center",
              scrollbarWidth: "none", // Initially hide scrollbar for Firefox
              "&::-webkit-scrollbar": {
                display: "none", // Initially hide scrollbar for Chrome/Safari
              },
              "&:hover": {
                scrollbarWidth: "thin", // Show thin scrollbar on hover (Firefox)
                "&::-webkit-scrollbar": {
                  height: "6px", // Thin scrollbar height (Chrome/Safari)
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: "transparent", // Transparent track
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#e0e0e0", // Lighter gray thumb
                  borderRadius: "10px", // Rounded thumb
                },
                "&::-webkit-scrollbar-thumb:hover": {
                  backgroundColor: "#d6d6d6", // Slightly darker gray on hover
                },
              },
            }}
          >
            {[
              "Personal Info",
              "Reactivation",
              "Brokerage Info",
              "Bank / DP",
              "Active POA / DDPI",
              "Active MTF",
              "FATCA",
              "Nominee",
              "Pledge/Unpledge",
              "Segment Addition",
              "Closure",
              "Annual Income",
              "freeze-Unfreeze",
            ].map((item) => (
              <Box
                key={item}
                sx={(theme) => ({
                  padding: "8px 16px",
                  borderRadius: "20px",
                  border: `1px solid ${selected === item ? "#34a853" : "#ddd"}`,
                  color: selected === item ? "#fff" : "#34a853",
                  backgroundColor: selected === item ? "#34a853" : "transparent",
                  fontSize: "14px",
                  fontWeight: 600,
                  cursor: "pointer",
                  whiteSpace: "nowrap",
                  textAlign: "center",
                  flexShrink: 0, // Allow buttons to shrink on smaller screens
                  minWidth: "80px", // Adjusted for small screens
                  "&:hover": {
                    backgroundColor: selected === item ? "#34a853" : "#d1ffdd",
                  },
                  [theme.breakpoints.down("sm")]: {
                    fontSize: "12px", // Smaller text on mobile
                    padding: "6px 12px", // Adjusted padding
                    minWidth: "70px", // Smaller width for small screens
                  },
                })}
                onClick={() => setSelected(item)}
              >
                {item}
              </Box>
            ))}
          </Box>

          {/* Rendered Component */}
          <div>{renderComponent()}</div>
        </Box>

        <Grid item sx={{ top: "70px", position: "relative" }} xs={12} md={6}>
          <FooterContent />
        </Grid>
      </div>
    </div>
  );
};

export default UserProfile;
