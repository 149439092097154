import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import TabButtons from './tncButtons';
import ClientRegistration from './tncClientRegistration';
import OtherPolicy from './tncOtherPolicy';
import RiskManagementPolicy from './tncRiskPolicy';
import Footer from '../../Footer/Footer';
import FooterContent from '../../Footer/FooterContent';

const TermsAndConditions = () => {
  const [activeTab, setActiveTab] = useState('Client Registration');

  return (
    <>
    <div className="max-w-4xl mx-auto p-8">
      <h1 className="text-2xl font-bold mb-4">Terms And Conditions</h1>
      <TabButtons activeTab={activeTab} setActiveTab={setActiveTab} />
      
      {activeTab === 'Client Registration' && <ClientRegistration />}
      {activeTab === 'Other Policy' && <OtherPolicy />}
      {activeTab === 'Risk Management Policy' && <RiskManagementPolicy />}
    </div>
    <Footer/>
    <FooterContent/>
    </>
  );
};

export default TermsAndConditions;
