// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Transitions.css */
.fade-enter {
  opacity: 0;
  transform: scale(0.9);
}
.fade-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 300ms, transform 300ms;
}
.fade-exit {
  opacity: 1;
  transform: scale(1);
}
.fade-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}
`, "",{"version":3,"sources":["webpack://./src/Charges/Transition.css"],"names":[],"mappings":"AAAA,oBAAoB;AACpB;EACE,UAAU;EACV,qBAAqB;AACvB;AACA;EACE,UAAU;EACV,mBAAmB;EACnB,0CAA0C;AAC5C;AACA;EACE,UAAU;EACV,mBAAmB;AACrB;AACA;EACE,UAAU;EACV,qBAAqB;EACrB,0CAA0C;AAC5C","sourcesContent":["/* Transitions.css */\r\n.fade-enter {\r\n  opacity: 0;\r\n  transform: scale(0.9);\r\n}\r\n.fade-enter-active {\r\n  opacity: 1;\r\n  transform: scale(1);\r\n  transition: opacity 300ms, transform 300ms;\r\n}\r\n.fade-exit {\r\n  opacity: 1;\r\n  transform: scale(1);\r\n}\r\n.fade-exit-active {\r\n  opacity: 0;\r\n  transform: scale(0.9);\r\n  transition: opacity 300ms, transform 300ms;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
