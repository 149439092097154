import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { KYC_API } from "./baseurl";

export const freezeUnfreezeApi = createApi({
  reducerPath: 'freezeUnfreezeApi',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://kyc.adityatrading.in/api/', //KYC_API
  }),
  tagTypes: ['FreezeUnfreeze'],
  endpoints: (builder) => ({
    freezeUnfreeze: builder.mutation({
      query: ({ event, clientCode, choice }) => ({
        method: 'POST',
        url: 'freeze-or-unfreeze',
        body: {
          event,
          clientCode,
          choice,
        },
      }),
    }),
  }),
});

export const { useFreezeUnfreezeMutation } = freezeUnfreezeApi;
export default freezeUnfreezeApi;
