import { Grid, IconButton } from '@mui/material';
import { Box} from '@mui/system';
import React, { useEffect, useState } from 'react';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import MobileModel from './Mobilemodel';
import EmailModel from './EmailModel';
import { useGetTokenMutation, useGetUserDataQuery } from '../../redux/UsersPersonalData';

const Personalinfo = () => {
const [phoneNumber, setPhoneNumber] = useState('');
const [open, setOpen] = useState(false);
const [emailOpen, setEmailOpen] = useState(false);
const [profileToken,setProfileToken] = useState();
const [userProfile, setUserProfileData] =  useState(null);
const handleOpen = () => setOpen(true);
const handleEmailOpen = () => setEmailOpen(true);
const handleEmailCLose = () => setEmailOpen(false);
const handleClose = () => setOpen(false);

    useEffect(() => {
      const storedData = JSON.parse(localStorage.getItem('verifiedData'));
      if (storedData && storedData.Phone_Number) {
        setPhoneNumber(storedData.Phone_Number); 
        console.log("Data from localStorage in personalinfo.js: ", storedData);
        console.log("This is the phone number: ", storedData.Phone_Number);
      }
    }); 
    
const [getToken, { isLoading: tokenLoading }] = useGetTokenMutation();

useEffect(() => {
  const fetchToken = async () => {
    try {
      console.log("Attempting to fetch token...");
      const response = await getToken().unwrap();
      console.log("Token fetched successfully:", response);
      setProfileToken(response.access); 
    } catch (error) {
      console.error('Failed to fetch token:', error);
    }
  };
  fetchToken(); 
}, [getToken]);

const { data: userData, error, isLoading } = useGetUserDataQuery(
  { phoneNumber, profileToken },
  { skip: !profileToken }
);

useEffect(()=>{
  if(userData){
    console.log("Full user data response:", userData);

    const usersProfileData = { ...userData}
    localStorage.setItem('usersProfileData', JSON.stringify(usersProfileData));
    setUserProfileData(usersProfileData);
  }
  if(error){
    console.error("Error fetching user data:", error);
  }
},[userData,error]);

// useEffect(() => {
//   console.log("Fetching token:", profileToken, "and phoneNumber:", phoneNumber);
//   if (error) {
//     console.error("Error while fetching user data:", error);
//   }
// }, [userData, error, profileToken, phoneNumber]);

useEffect(() => {
  const storedUserProfile = JSON.parse(localStorage.getItem('usersProfileData'));
  if (storedUserProfile) {
    console.log("Loaded profile from localStorage:", storedUserProfile);
    setUserProfileData(storedUserProfile);
  }
}, []); 


  if (tokenLoading || isLoading) {
    return <p>Token Loading...</p>;
  }

  if (isLoading) {
    return <p>Loading user data...</p>;
  }

  if (error) {
    return <p>Error fetching user data: {error.message}</p>;
  }

  const Profile = userData?.user;

  return (
    <div style={{ position: 'relative' , padding:'15px'}}>
      <Grid container>
        <Grid item xs={12} md={3}>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <h6>Full Name</h6>
            <p style={{ fontSize: '15px' }}>{Profile?.client_name || 'N/A'}</p>
          </Box>
        </Grid>
        <Grid item xs={12} md={3}>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <h6>Mobile No</h6>
            <p style={{ fontSize: '15px' }}>
              {Profile?.mobile || 'N/A'}{' '}
              <IconButton onClick={handleOpen}>
                <ModeEditIcon sx={{ fontSize: '15px' }} />
              </IconButton>{' '}
            </p>
          </Box>
        </Grid>
        <Grid item xs={12} md={3}>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <h6>Email</h6>
            <p style={{ fontSize: '15px' }}>
              {Profile?.email || 'N/A'}
              <IconButton onClick={handleEmailOpen}>
                <ModeEditIcon sx={{ fontSize: '15px' }} />
              </IconButton>{' '}
            </p>
          </Box>
        </Grid>
        <Grid item xs={12} md={3}>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <h6>Joined Date</h6>
            <p style={{ fontSize: '15px' }}>{Profile?.date_joined || 'No Data'}</p>
          </Box>
        </Grid>
        <Grid item xs={12} md={3}>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <h6>Pan Number</h6>
            <p style={{ fontSize: '15px' }}>{Profile?.pan_id || 'N/A'}</p>
          </Box>
        </Grid>
        <Grid item xs={12} md={3}>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <h6>Status</h6>
            <p>{Profile?.active ? 'Active' : 'Inactive'}</p>
          </Box>
        </Grid>
        <Grid item xs={12} md={3}>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <h6>Demat ID</h6>
            <p style={{ fontSize: '15px' }}>{Profile?.demat_id || 'N/A'}</p>
          </Box>
        </Grid>
        <MobileModel open={open} handleClose={handleClose} clientCode = {Profile?.client_code}/>
        <EmailModel open={emailOpen} handleClose={handleEmailCLose} clientCode = {Profile?.client_code}/>
      </Grid>
    </div>
  );
};

export default Personalinfo;
