import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import { useSearchStocksMutation } from '../redux/searchBox'; // Update import path if needed
import { CardContent, Grid, Typography } from '@mui/material';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { setTokenID } from '../reduxslices/tokenSlices';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';


const style = {
    position: 'relative',
    height: '500px',  // Increase height for more stock results
    width: '500px',  // Wider modal for better display
    overflowY: 'auto',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: '#f9f9f9',  // Lighter background
    borderRadius: '10px',  // Smoother edges
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',  // Softer shadow
    p: 4,
};


const debounce = (func, delay) => {
    let timer;
    return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            func.apply(this, args);
        }, delay);
    };
};

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: '20px',  // More rounded for a sleek look
    backgroundColor: '#ffffff',  // White background for clarity
    '&:hover': {
        backgroundColor: '#f0f0f0',  // Slight hover effect
    },
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: '400px',  // Make the search bar larger
    },
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',  // Subtle shadow for depth
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '270px',
        },
    },
}));

export default function AlertSearch({ onStockSelect }) {
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [inputValue, setInputValue] = React.useState('');
    const [stocks, setStocks] = React.useState([]);
    const [socket, setSocket] = React.useState(null);
    const [wsData, setWsData] = React.useState([]);
    const [searchValueTicker, setSearchValueTicker] = React.useState([]);
    const [searchStocks, { data: stockdata, error, isLoading }] = useSearchStocksMutation();
    const intraday = (stockdata && stockdata['stockSearch']) || [];

    React.useEffect(() => {
        const ws = new WebSocket('wss://neuronsoft.in/ws/watchlist/companyInfo/');

        ws.onopen = () => {
            console.log('WebSocket connection opened.');
            searchValueTicker.forEach((tokenID) => {
                const message = JSON.stringify({
                    event: 'addTicker',
                    token: tokenID,
                });
                ws.send(message);
                console.log(`Sent tokenID ${tokenID} to WebSocket.`);
            });
        };

        ws.onmessage = (event) => {
            const data = JSON.parse(event.data);
            setWsData(data);
        };

        ws.onclose = () => console.log('WebSocket connection closed.');
        ws.onerror = (error) => console.log('WebSocket error:', error);

        setSocket(ws);

        return () => {
            ws.close();
        };
    }, [searchValueTicker]);

    const debouncedSearchStocks = React.useCallback(
        debounce((query) => {
            if (query) {
                searchStocks(query);
            }
        }, 300),
        [searchStocks]
    );

    const handleInputChange = React.useCallback(
        (e) => {
            const newValue = e.target.value.toUpperCase();
            setInputValue(newValue);

            if (newValue) {
                debouncedSearchStocks(newValue);
            } else {
                setInputValue('');
                searchStocks('');
            }
        },
        [debouncedSearchStocks, searchStocks]
    );

    React.useEffect(() => {
        if (intraday.length > 0 && socket) {
            const tokens = intraday.map((item) => item.tokenID);
            setSearchValueTicker((prev) => {
                if (JSON.stringify(prev) !== JSON.stringify(tokens)) {
                    tokens.forEach((tokenID) => {
                        const message = JSON.stringify({
                            event: 'addTicker',
                            token: tokenID,
                        });
                        sendMessageOnOpen(socket, message);
                    });
                }
                return tokens;
            });
        }
    // eslint-disable-next-line no-use-before-define
    }, [intraday, socket]);

    const sendMessageOnOpen = React.useCallback((ws, message) => {
        if (ws.readyState === WebSocket.OPEN) {
            ws.send(message);
        } else {
            setTimeout(() => sendMessageOnOpen(ws, message), 100);
        }
    }, []);

    const handleStockSelect = (companyName, tokenID) => {
        console.log('Selected Stock', companyName, tokenID);
        if (onStockSelect) {
            onStockSelect({ companyName, tokenID }); // Pass selected stock back to parent
        }
        setInputValue(''); 
        if (location.pathname === '/') {
            navigate(`/companyPage/${companyName}`);
        } else {
            // If modal is needed, implement modal handling here
        }
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Box sx={{ flexGrow: 1, display: 'grid', width: 'auto', marginTop: '30px' }}>
                <Search sx={{ borderRadius: '10px' }}>
                    <SearchIcon />
                    <StyledInputBase
                        placeholder="Search for Stock Mutualfunds & more"
                        inputProps={{ 'aria-label': 'search' }}
                        value={inputValue}
                        onChange={handleInputChange}
                    />
                </Search>

                {isLoading && <Typography>Loading...</Typography>}
                {error && <Typography color="error">Error fetching data</Typography>}
                {intraday.length > 0 && (
                    <Box sx={{ width: '400px', height: '263px', marginTop: '10px', backgroundColor: '#fff' }}>
                        <Grid container spacing={2} sx={{ padding: '20px' }}>
                            {intraday.map((stock) => (
                                <Grid item xs={12} key={stock.tokenID}>
                                    <CardContent sx={{
                                        backgroundColor: '#f0f0f0',  // Lighter background for contrast
                                        borderRadius: '10px',
                                        padding: '16px',
                                        transition: 'transform 0.3s',  // Animation on hover
                                        '&:hover': {
                                            transform: 'scale(1.05)',  // Slight zoom effect on hover
                                            cursor: 'pointer',
                                            backgroundColor: '#ffffff',
                                        },
                                    }}
                                        onClick={() => handleStockSelect(stock.ticker_code, stock.tokenID)}
                                    >
                                        <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#333' }}>
                                            {stock.ticker_code} ({stock.exchange})
                                        </Typography>
                                        <Typography variant="body2" sx={{ color: '#666' }}>
                                            LTP: {wsData?.LTP || ''}
                                            {console.log('this is the data we are gettting ', stock.LTP)}
                                        </Typography>
                                    </CardContent>
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                )}
            </Box>
        </div>
    );
}

