import React, { useState } from 'react';
import OngoingIpo from './OngoingIpo';
import UpcomingIpo from './UpcomingIpo';
import { Button } from '@mui/material';
import ipos from './Ipo';
const ActiveFunds = () => {
  const [selectedComponent, setSelectedComponent] = useState('Ongoing Ipo');

  const renderComponent = () => {
    switch (selectedComponent) {
      case 'Ongoing Ipo':
        return <OngoingIpo />;
      case 'Upcoming Ipo':
        return <UpcomingIpo />;
      default:
        return <OngoingIpo />;
    }
  };

  const getButtonStyles = (component) => ({
    color: selectedComponent === component ? '#ffffff' : '#000000', // White text when selected, black when unselected
    backgroundColor: selectedComponent === component ? '#34a853' : 'transparent', // Green background when selected, transparent when unselected
    borderRadius: '50px', // Rounded button
    border: '1px solid #34a853', // Green border for both selected and unselected
    padding: '4px 12px', // Larger padding for better spacing
    fontSize: selectedComponent === component ? '13px' : '11px', // Bigger font when selected
    fontWeight: 600, // Bold text
    marginBottom: '10px',
    marginLeft: '10px',
    textTransform: 'none',
    cursor: 'pointer',
    transition: 'transform 0.2s ease, background-color 0.3s ease, color 0.3s ease, font-size 0.3s ease', // Smooth transitions
    transform: selectedComponent === component ? 'scale(1.08)' : 'scale(1)', // Slightly bigger button when selected
    '&:hover': {
      backgroundColor: selectedComponent === component ? '#34a853' : '#d1ffdd', // Hover background color for unselected buttons
      transform: 'scale(1.1)', // Slightly larger on hover
    },
    '&:active': {
      transform: 'scale(0.95)', // Shrink on click
    },
  });

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        border: '1px solid #cfc2c2',
        borderRadius: '10px',
        //marginTop: '0px',
        alignItems: 'center',
        minHeight: '350px',
      }}
    >
      <h5
        style={{
          fontSize: '20px',
          fontWeight: 400,
          letterSpacing: '.3px',
          color: '#131722',
          lineHeight: '1.5px',
          textAlign: 'center',
          //display: 'flex',
          width: '100%',
          //paddingLeft: '20px',
          marginTop: '25px',
        }}
      >
        Active Funds
      </h5>
      <div
        style={{
          width: '100%',

          marginTop: '10px',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginTop: '20px',
          }}
        >
          <Button
            onClick={() => setSelectedComponent('Ongoing Ipo')}
            sx={getButtonStyles('Ongoing Ipo')}
          >
            Ongoing Ipo
          </Button>
          <Button
            onClick={() => setSelectedComponent('Upcoming Ipo')}
            sx={getButtonStyles('Upcoming Ipo')}
          >
            Upcoming Ipo
          </Button>
        </div>
        <hr style={{ marginLeft: '20px' }} />
        {renderComponent()}
      </div>
    </div>
  );
};

export default ActiveFunds;
