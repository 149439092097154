import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { MORNING_REPORT_API } from '.././Api';
import { BASE_URL } from './baseurl';

export const researchReportApi = createApi({
  reducerPath: 'researchreportApi',
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
  }),
  tagTypes: ['report'],
  endpoints: (builder) => ({
    researchReport: builder.query({
      query: () => ({
        method: 'POST',
        url: MORNING_REPORT_API,
        body: {
          PAGENO: 1,
          POST_PER_PAGE: 12,
        },
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
        },
      }),
      providesTags: ['report'],
    }),
  }),
});

export const { useResearchReportQuery } = researchReportApi;

export default researchReportApi;
