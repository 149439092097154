import React, { useEffect, useState } from 'react';
import LandingPage from '../../LandingPage';

import FooterContent from '../../Footer/FooterContent';
import { useLocation } from 'react-router-dom';

import { dark } from '@mui/material/styles/createPalette';
import GreenButton from '../../Buttons/GreenButton';
import {
  Box,
  Button,
  CardContent,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import DangerButton from '../../Buttons/Danger';
import SuccessButton from '../../Buttons/Success';
import CareerNavbar from './CareerNavbar';
import BackImg from '../../Assets/D2ADB4F0-AED9-43A9-82B9-6B4DD3ABF51C.png';
import ArrowPng from '../../Assets/Polygon 1.png';
import Elpise from '../../Assets/Ellipse 67.png';
import Arw from '../../Assets/arrow.jpg';
import Bbl from '../../Assets/bubble-chart-fill.jpg';
import mny from '../../Assets/money-dollar-circle-fill.jpg';
import prsn from '../../Assets/user-fill.jpg';
import erth from '../../Assets/earth-fill.jpg';
import usr2 from '../../Assets/user-5-fill.jpg';
import tmr1 from '../../Assets/timer-fill.jpg';
import JobInput from './JobInput';
const JobDetails = () => {
  const location = useLocation();
  const [open, setOpen] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const handleModelOpen = () => {
    setOpen(!open);
  };
  const handleClose = () => setOpen(false);

  return (
    <div>
      <CareerNavbar />
      {/* <div
        style={{
          position: 'relative',
          padding: '0px',
          margin: '0px',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            width: '80%',
            top: '100px',
            position: 'relative',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        >
          <div style={{ display: 'flex' }}>
            <GreenButton sx={{ position: 'relative', left: '-20px' }}>
              Careers
            </GreenButton>
          </div>
          <p style={{ fontWeight: 'bold', color: 'black' }}>
            Find your Next Job with ATS
          </p>
          <p>
            <small>
              Make your career grow with us and have a beatiful work Experience
            </small>
          </p>
          <hr />

          <Grid container direction="column" spacing={2}>
            <Grid item xs={12}>
              <Box
                sx={{
                  border: '1px solid #dee2e6!important',
                  display: 'flex',
                  padding: '15px',
                  marginBottom: '7px',
                  justifyContent: 'space-between',
                }}
              >
                <div>
                  <h5>Relationship Manager</h5>
                  <p>
                    Hello
                    <br />
                    Greetings of the day!
                    <br />
                    We have an urgent opening!!
                  </p>
                  <p>Designation - Re…</p>
                </div>
                <div>
                  <SuccessButton name="Apply Now" />
                </div>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box
                sx={{
                  border: '1px solid #dee2e6!important',
                  display: 'flex',
                  padding: '15px',
                  marginBottom: '7px',
                  justifyContent: 'space-between',
                }}
              >
                <div>
                  <h5>Equity Advisor</h5>
                  <p>
                    <strong>Equity Advisor</strong>
                    for<strong> Equity Dealer: </strong>
                  </p>
                </div>
                <div>
                  <SuccessButton name="Apply Now" />
                </div>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Grid item sx={{ top: '70px', position: 'relative' }} xs={12} md={6}>
          <FooterContent />
        </Grid>
      </div> */}
      <Box
        sx={{
          display: 'flex',

          alignItems: 'center',
          position: 'relative',
          top: '163px',
          flexDirection: 'column',
          lineHeight: isMobile ? '22px' : '60px',
          gap: '20px', // Adjust the value as needed
        }}
      >
        <Typography
          sx={{
            fontWeight: '600',
            fontSize: '48px',
            textAlign: 'center',
            color: '#2a2525!important',
          }}
        >
          Full Stack Developer
          <Typography
            sx={{
              fontWeight: '400',
              fontSize: '19px',
              lineHeight: '32px',
              maxWidth: '606px',
            }}
          >
            Full Time, Remote
          </Typography>
        </Typography>

        <Typography
          sx={{
            maxWidth: '700px',
            fontWeight: '400',
            fontSize: '17px',
            color: '#161C2D',
          }}
        >
          Headquartered in Melbourne, Australia, Envato is a completely online
          company with an ecosystem of sites and services to help people get
          creative. We’ve consistently been named as one of the Best Places to
          Work in Australia, since 2015, in the BRW Awards, and we’ve also been
          awarded the title of Australia's Coolest Company for Women and
          Diversity by JobAdvisor. <br /> Envato was found in 2006 and, since
          then, we’ve helped a community of creative sellers earn more than $500
          Million. Millions of people around the world choose our marketplace,
          studio and courses to buy files, hire freelancers, or learn the skills
          needed to build websites, videos, apps, graphics and more. Find out
          more at Envato Market, Envato Elements, Envato Sites, Envato Studio
          and Tuts+. <br /> You might be surprised to know that not only do we
          run some of the biggest websites in the world; we’re also growing
          really fast! We have close to 600 staff and contractors worldwide,
          adding more than 100 people to the business, year on year, since 2017.
        </Typography>

        <Typography
          sx={{
            fontWeight: '700',
            fontSize: '24px',
            textAlign: 'center',
            color: '#2a2525!important',
          }}
        >
          What you’ll be doing
        </Typography>

        <Typography
          sx={{
            maxWidth: '732px',
            fontWeight: '400',
            fontSize: '17px',
            color: '#161C2D!important',
            opacity: '70%',
          }}
        >
          A Senior Data Engineer provides technical and delivery leadership for
          a team of developers working on data integration and processing
          projects. This role will work with stakeholders and other developers
          to design and implement technical data solutions for the business in a
          way that balances quality, cost, time, and maintainability.
        </Typography>
        <Box style={{ display: 'flex', justifyContent: 'center' }}>
          <Typography
            sx={{
              fontWeight: '700',
              fontSize: '24px',
              textAlign: 'center',
              color: '#2a2525!important',
            }}
          >
            Skills & Qualifications
          </Typography>
        </Box>
      </Box>
      <div
        style={{
          position: 'relative',
          top: '175px',
          display: 'flex',
          flexDirection: 'column',
          marginLeft: '23%',
        }}
      >
        <ul>
          <li>Buisness Intelligence Solution</li>
        </ul>
        <Button
          onClick={handleModelOpen}
          variant="contained"
          sx={{
            border: '1px solid #36B864',
            color: '#FFFFFF',
            width: '210px',
            fontWeight: '700',
            height: '61px',
            padding: '0px',
            fontSize: '17px',
            textTransform: 'none',
            textAlign: 'right !important',
            backgroundColor: '#3DAC5A',
            '&:hover': {
              backgroundColor: '#3DAC5A',
            },
          }}
        >
          Apply for Job
        </Button>
        <JobInput open={open} close={handleClose} />
      </div>
    </div>
  );
};

export default JobDetails;
