// src/redux/tokenSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  tokenID: localStorage.getItem('tokenID') || null,
  companyName: localStorage.getItem('companyName') || '',
};

const tokenSlice = createSlice({
  name: 'token',
  initialState,
  reducers: {
    setTokenID: (state, action) => {
      state.tokenID = action.payload.tokenID;
      state.companyName = action.payload.companyName;
    },
  },
});

export const { setTokenID } = tokenSlice.actions;

export default tokenSlice.reducer;
