import React from "react";
import { Box, Typography } from "@mui/material";

const OtherPolicy = () => {
  return (
    <>
      <h2 className="text-xl font-bold mb-2 text-justify">PMLA Guidelines</h2>
      <p className="mb-4 text-justify">
        The main objective of AML policy of ATS is to prevent ATS from being
        used, intentionally or unintentionally, by criminal elements for money
        laundering or terrorist financing activities. The objective of this
        policy is also to enable ATS understand its customers and their
        financial dealings better, which in turn will help ATS to manage its
        risks prudently.
      </p>
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
        {/* Text Section */}
        <div className="lg:col-span-2">
          <h2 className="text-base font-bold mb-4">
            The objective of the policy is to:–
          </h2>
          <ul className="list-disc list-outside pl-5 space-y-4">
            {/* First two list items aligned with the image */}
            <li className="text-justify">
              To have a proper Customer Due Diligence (CDD) process before
              registering clients
            </li>
            <li className="text-justify">
              To monitor/maintain records of all cash transactions of the value
              of more than Rs.10 lacs.
            </li>
            <li className="text-justify">
              To maintain records of all series of integrally connected cash
              transactions within one calendar month.
            </li>
            <li className="text-justify">
              To monitor and report suspicious transactions.
            </li>
          </ul>
        </div>

        {/* Image Section */}
        <div className="lg:col-span-1 flex justify-center items-start mt-12">
          <img
            src="https://adityatrading.in/static/images/terms-and-conditions.svg"
            alt="Illustration of people checking off a list on a large document"
            className="w-full lg:w-auto"
          />
        </div>

        {/* Remaining list items */}

        <div className="lg:col-span-3">
          <ul className="list-disc list-inside space-y-4">
            <li className="text-justify">
              To discourage and identify money laundering or terrorist financing
              activities.
            </li>
            <li className="text-justify">
              To take adequate and appropriate measures to follow the spirit of
              the PMLA
            </li>
          </ul>

          <p className="mb-4 text-justify mt-8">
            ATS shall put in place a system of periodical review of risk
            categorization of accounts. Such review of risk categorization of
            customers shall be carried out at a periodicity of not less than
            once in six months. Client will be categorized as High, Medium and
            Low risk customer as per their risk appetite and their current
            profile as mentioned in Know your client form (KYC). The same will
            be reviewed at regular intervals as per PMLA Policy of ATS. Exposure
            to client may also be governed by customer profiling mentioned above
            as well as clients financial income made available to ATS from time
            to time.
            <br />
            <br />
            Client needs to furnish their income details on yearly basis.
            Regular communications by means of SMS, Email are sent to clients at
            various intervals requesting them to update their latest financial
            and KYC details available with us.
          </p>

          <h2 className="text-base font-bold mb-4 mt-8">
            Following documents will be accepted as income proof:
          </h2>
          <ul className="list-disc list-inside space-y-4">
            <li className="text-justify">Copy of ITR Acknowledgement</li>
            <li className="text-justify">Copy of Annual Accounts</li>
            <li className="text-justify">
              To maintain records of all series of integrally connected cash
              transactions within one calendar month.
            </li>
            <li className="text-justify">
              Copy of Form 16 in case of salary income
            </li>
            <li className="text-justify">Net worth certificate</li>
            <li className="text-justify">Salary Slip</li>
            <li className="text-justify">
              Bank account statement for last 6 months
            </li>
            <li className="text-justify">
              Copy of demat account Holding statement.
            </li>
            <li className="text-justify">
              Any other relevant documents substantiating ownership of assets.
            </li>
          </ul>

          <p className="mb-4 text-justify mt-8">
            If there is a major disparity between financial details and trading
            volumes, client will be asked to furnish suitable explanation and
            based on the same further trading limits will be sanctioned. ATS may
            withhold the payout of client and suspend his trading account due to
            any internal surveillance (if client indulges into manipulative
            trade practice) / regulatory orders (debarring orders) / etc.) The
            Suspicious Transaction Report (STR) shall be furnished within 7 days
            of arriving at a conclusion that any transaction, whether cash or
            non-cash, or a series of transactions integrally connected are of
            suspicious nature. Systems shall be put in place for identifying
            transactions likely to be market manipulation, and which appears to
            be insider trading and also any transaction which seems to have no
            bona fide intention.
          </p>

          <h2 className="text-base font-bold mb-4 mt-8">
            Policy on Delayed Payment Charges and Penalty
          </h2>
          <p className="text-justify mb-4">
            Clients will be liable to pay delayed/late payment charges for not
            making payment of their payin/margin obligation on time as per the
            exchange requirement/schedule at the rate of 24% pa. All clients are
            advised to make the payment before the pay-in time on the settlement
            date. It is also ensured that no position is allowed to be carried
            forward beyond the fifth trading day from the date of payin to meet
            the exchange regulations.
            <br />
            <br />
            ATS may also impose fines/Penalties for any orders/trades/nonpayment
            of required margins, dishonor of cheques, cash deposit and other
            actions of the client which are contrary to the exchange rules or
            any other law in force, in such rates and in such form as it may
            deem fit. Further where ATS has to pay any fine or bear any
            punishment from any authority in connection with/as a consequence
            of/ in relation to any of the orders/trades/actions of the client,
            the same shall be borne by the client.
            <br />
            <br />
            Delayed charges are also collected for margin shortage in derivative
            segments. ATS deploys its own funds for meeting margin requirement
            of its client at the Exchange against the stock collateral
            maintained by the client; the Charges on FO position are levied to
            the extent of shortfall in fund requirement.
            <br />
            <br />
            Currently ATS charges late payment fees only for shortage in SPAN
            margin if any, and shortage in exposure margin is not charged in NSE
            FO segment; and in other segments exchange stipulated margin is
            considered in full for arriving at margin shortage amount. ATS
            collects late charges at a flat rate of Rs.100/- if shortage in
            Margin is less than Rs.1,00,000/- and less than 10% of Total Margin
            Requirement and Rs 500 for margin shortage of Rs 100,000 to Rs.
            10,00,000/ or where shortage is more than 10% of total margin
            required and Rs.1000 if margin shortage is more than Rs.10,00,000.
            <br />
            <br />
            Deposit of cash for margin money is strictly prohibited by exchanges
            and any cash deposits made to ATS bank accounts will be refunded
            back to Client’s Registered Bank Account after deducting a fine of
            Rs.500/-
            <br />
          </p>

          <h2 className="text-base font-bold mb-4 mt-8">
            Auction Procedure for Non Delivery of Shares and Internal Netting of
            Trades
          </h2>
          <p className="text-justify mb-4">
            Exchanges follow a T+2 rolling settlement cycle in cash segment. In
            case of short deliveries on the T+2 day in the normal segment,
            exchange conducts a buy – in auction on the T+2 day itself and the
            settlement for the same is completed on the T+3 day. Auction
            settlement is at the price determined by the exchange, which
            normally falls within a range of 20% of the previous day close; and
            the same is debited to the defaulting client ledger. Also bad/short
            delivery charges and processing fees levied by exchange will be
            debited to client.
            <br />
            <br /> Additionally ATS charges Rs.100 per scrip from the defaulting
            clients as fine for non-delivery of securities on time and leading
            to auction.
          </p>

          <h2 className="text-base font-bold mb-4 mt-8">
            Internal Netting of Trades
          </h2>
          <p className="text-justify mb-4">
            As per the Exchange Settlement Norms the Pay in Obligation for
            securities are netted at member level and only net obligation is
            paid in to the exchange. It could be possible that within the
            clients of ATS, there could be opposite positions in the same
            scrips, which then, necessitates the internal adjustment/netting. In
            case a seller client fails to deliver which was to be paid out for
            the account of another of our clients, such a situation necessitates
            the Internal Auction. The settlement currently followed is as below:
            <br />
            <br />
            Auction price of the scrip informed by the NSE for that settlement
            number or If Auction Price is not available in NSE for a scrip, for
            that settlement, highest price of the scrip prevailing in the NSE
            from the first day of the relevant trading period till the day of
            closing out on the auction day whichever is higher is considered.
            <br />
            Generally, the auction day is considered to be T+3 day i.e. the day
            next to the official settlement day. The seller client will be
            debited with this settlement prince and the same price will be
            credited to the buyer client. Additionally ATS charges Rs.100/ per
            scrip from the defaulting clients as fine for non-delivery of
            securities on time and leading to auction.
          </p>

          <h2 className="text-base font-bold mb-4 mt-8">
            Policy for Quarterly/Monthly Settlement
          </h2>
          <p className="text-justify mb-4">
            ATS takes the preference of settlement of clients’ at the time of
            account opening through the Account Opening Form. While settling the
            accounts, ATS may retain the requisite securities/funds towards such
            obligations and may also retain the funds expected to be required to
            meet margin obligations for next 5 trading days, calculated in the
            manner specified by the exchanges.
          </p>
          <ul className="list-disc list-outside pl-5 space-y-4">
            <li className="text-justify">
              In respect of derivative market transactions, apart from margin
              liability as on the date of settlement, additional margins
              (maximum up-to 125% of margin requirement on the day of
              settlement) to take care of any margin obligation arising in next
              5 days may be retained.
            </li>
            <li className="text-justify">
              In respect of capital market transactions, entire pay-in
              obligation of funds & securities due from clients as on date of
              settlement may be retained. Further, in the capital market
              segment, for next day‟s business, member may retain
              funds/securities/margin to the extent of value of transactions
              executed on the day of such settlement in the capital market only.
            </li>
            <li className="text-justify">
              In case of settling the accounts of regular trading clients
              (active clients), the Member may retain an amount of up to Rs
              10,000/- (net amount across segment and across stock exchanges),
              only after taking written consent of the client.
            </li>
            <li className="text-justify">
              The above threshold limit on retention of amount shall not be
              applicable in case of clients who have not traded even once during
              the last one month/quarter, as the case may be; i.e settlement
              shall be done as per the aforesaid SEBI circular, in such cases.
            </li>
          </ul>
          <p className="text-justify mb-4 mt-6">
            ATS provides the platform to buy mutual funds for clients who invest
            money and wait for favorable market timing can buy liquid mutual
            funds to ensure the exchange compliance of settlement of accounts.
            <br />
            <br />
            ATS sends a statement of Accounts to its clients along with the
            settlement of funds and also a Retention statement in case the funds
            are retained, explaining the utilization of funds to the clients’
            registered mail id.
          </p>

          <h2 className="text-base font-bold mb-4 mt-8">
            Issuance of contract notes
          </h2>
          <p className="text-justify mb-4">
            ATS issues contract notes to its clients within 24 hours of the
            trade taking place. ATS offers the issuance of Electronic Contract
            Note to clients who opt for the same and it is also available to be
            viewed on their respective back-office Login. Along with the
            Contract Note, the client shall also be furnished with a copy of the
            daily margin status. Also trade confirmation SMS is sent to client
            immediately after the trade execution.
            <br />
            <br /> ATS will issue the contract note, margin statement and trade
            SMS to the mail IDs and mobile numbers mentioned in the Client
            registration Form. So kindly ensure that the correct mail IDs and
            mobile numbers are mentioned and submitted in the KYC form.
            <br />
            <br /> The clients will be responsible to check the digital
            documents including Electronic Contract Notes and bring the
            discrepancies if any to our notice within stipulated time of such
            issuance of contract notes. Non-verification or not accessing the
            documents on regular basis shall not be a reason for disputing the
            documents at any time. It will be the responsibility of client to
            ensure that any change in mail id is informed to ATS urgently.
          </p>

          <h2 className="text-base font-bold mb-4 mt-8">
            Investor Grievance Resolution
          </h2>
          <p className="text-justify mb-4">
            ATS has a Customer Support Department to help customers resolve
            their doubts and grievances if any. Clients can raise their
            grievances through mail or phone or through our grievance section in
            website. Also the contact detail of Compliance Officer of ATS is
            published in KYCs and Contract Notes to get a clarification on their
            grievances and statements.
            <br />
            <br /> Additionally the contact details of Investor Service Cell of
            Exchanges are published in our KYC. Investors who are not satisfied
            with the response to their grievances received from ATS, can lodge
            their grievances with the Stock Exchanges or Depositories. Also
            clients can raise a complaint through SEBI Complaints Redress System
            (SCORES) platform. On registering the complaint, exchange calls both
            parties to discuss and resolve the grievances. All complaints which
            do not get resolved within fifteen days from the date of lodging the
            complaints with Exchange or cases where parties are aggrieved by the
            resolution worked out would be referred to Investor Grievance
            Resolution Panel (IGRP).
            <br />
            <br /> When one of the parties feels that the complaint has not been
            resolved satisfactorily either by the other party or through the
            complaint resolution process of the Exchange, the parties may choose
            the route of arbitration. Arbitration is a quasi-judicial process of
            settlement of disputes between Trading Member, investor, clearing
            member, sub-brokers etc. Arbitration aims at quicker legal
            resolution for the disputes.
            <br />
            <br /> If either of the parties is aggrieved by the award, the
            aggrieved party may approach the Exchange with an application in the
            prescribed format for appeal before the appellate arbitrators, along
            with applicable deposit, within a period of 30 days from the date of
            receipt of the arbitral award or the aggrieved party can challenge
            the award u/s 34 of Arbitration and Conciliation Act, 1996 in the
            court nearest to the address provided by constituent in the KYC form
            or as per the change in address communicated thereafter by the
            constituent to the trading member.
          </p>

          <h2 className="text-base font-bold mb-4 mt-8">
            Investor Grievance Resolution
          </h2>
          <p className="text-justify mb-4">
            ATS offers internet and mobile trading platform to its clients
            wherein with the help of unique User id and password issued to them,
            clients can login and trade. ATS has a dedicated team at its
            Bangalore office, to support clients in their trading and provide
            clarifications on their queries. Our Support team can be reached on
            mail support@adityatrading.com or through phone and WhatsApp.<br/><br/> We
            also provide a call desk facility in the regional offices across the
            county, where in clients can call and ask our executives to place
            the orders which will be done after verification of the identity of
            the client. Also all calls made to our trade desk are recorded and
            saved for future records. Our Customer Support also helps you in
            Online Fund Transfers, authorization of entry for the funds
            transferred, to avoid any delay in getting the Margin Limit. Those
            clients who are not able to raise Fund withdrawal Request through
            their online Login can contact our customer support to initiate the
            withdrawal request.
          </p>
        </div>
      </div>
    </>
  );
};

export default OtherPolicy;
