import React from 'react';
import Button from '@mui/material/Button';

const GreenButton = ({
  children, // The text or elements to display inside the button
  sx = {}, // Additional styles to merge with the default styles
  ...props // Any other props to pass to the Button component
}) => {
  return (
    <Button
      sx={{
        color: '#34a853!important',
        backgroundColor: '#218838!important',
        fontSize: '13px',
        transition: 'all 0.5s ease-out 0s',
        marginBottom: 'auto',
        marginTop: 'auto',
        paddingLeft: '22px',
        paddingRight: '22px',
        width: 'auto',
        borderRadius: '50rem',
        color: '#fff',

        border: '1px solid #e5f2ff!important',

        textTransform: 'none',

        ...sx, // Merge additional styles
      }}
      {...props} // Pass any other props
    >
      {children}
    </Button>
  );
};

export default GreenButton;
