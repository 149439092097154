import React, { useEffect, useRef } from 'react';
import {
    Card,
    CardContent,
    Typography,
    IconButton,
    Fade,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const NotificationCard = ({ message, onClose }) => {
    const cardRef = useRef(null);

    // Close the notification when clicking outside the card
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (cardRef.current && !cardRef.current.contains(event.target)) {
                onClose();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [onClose]);

    return (
        <Fade in>
            <Card
                ref={cardRef}
                sx={{
                    position: 'fixed',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    zIndex: 999,
                    width: 700, // Set a width for the card
                    boxShadow: 3, // Increase elevation
                    borderRadius: 5, // Optional: Add border radius for smoother edges
                    minHeight: 100,
                }}
            >
                <CardContent sx={{ position: 'relative' }}>
                    <Typography variant="h5" align="center">
                        {message}
                    </Typography>
                    <IconButton
                        onClick={onClose}
                        sx={{
                            position: 'absolute',
                            top: 8,
                            right: 8,
                            color: 'red', // Set close button color to red
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </CardContent>
            </Card>
        </Fade>
    );
};

export default NotificationCard;
