import React from 'react';

const ChartButton = ({ children, onClick }) => {
  return (
    <button
      onClick={onClick}
      style={{
        width: '51px',
        height: '31px',
        backgroundColor: '#fff',
        outline: 'none',
        border: '1px solid #d1ffdd',
        borderRadius: '12px',
        marginRight: '10px',
        cursor: 'pointer',
      }}
    >
      {children}
    </button>
  );
};

export default ChartButton;
