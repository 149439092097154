import React, { useEffect } from 'react';
import LandingPage from '../../LandingPage';
import FooterContent from '../../Footer/FooterContent';
import { useLocation, useNavigate } from 'react-router-dom';
import GreenButton from '../../Buttons/GreenButton';
import { Box, Grid, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useResearchReportQuery } from '../../redux/sharemarketnews';
import './Reports.css';
import BorderButton from '../../Buttons/BorderButton';
const MorningReports = () => {
  const { data: reportData } = useResearchReportQuery();
  const reports = reportData ? reportData.posts : [];

  console.log('this is the  report data', reportData);


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    });
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const handleNavigation = (index) => {
    console.log("page no im on-->", index);
    navigate('/share-market-news/details', { state: { reportIndex: index } });
  };

  return (
    <div>
      <LandingPage />
      <div
        style={{
          position: 'relative',
          padding: '0px',
          margin: '0px',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            width: '80%',
            top: '100px',
            position: 'relative',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        >
          <div style={{ display: 'flex' }}>
            {/* <BorderButton sx={{ position: 'relative', left: '10px' }}>
              Daily Research Report
            </BorderButton> */}
          </div>
          <h6 style={{ color: 'black' }}>Daily Research Report</h6>
          <p>
            <small>
              Research reports are prepared by our research analysts who have
              deep industry knowledge and experience
            </small>
          </p>
          <hr />
          <div style={{ display: 'flex', justifyContent: 'end' }}>
            <select className="custom-select">
              <option>Sort By: Recent</option>
            </select>
          </div>

          <Grid container direction="column" spacing={2}>
            <Grid item xs={12}>
              {reports.map((report, index) => (
                <Box
                  key={index}
                  sx={{
                    display: 'flex',
                    flexDirection: isMobile ? 'column' : 'row',
                    padding: '15px',
                    marginBottom: '7px',
                    justifyContent: 'space-between',
                    alignItems: isMobile ? 'flex-start' : 'center',
                    borderBottom: '1px solid #c6c5ca',
                  }}
                >
                  <div
                    style={{
                      width: isMobile ? '30%' : '10%',
                      marginBottom: isMobile ? '10px' : 0,
                    }}
                  >
                    <img
                      style={{
                        display: 'block',
                        width: '100%',
                        height: 'auto',
                      }}
                      src={report.morningNote.ogimageUrl}
                      alt="img"
                    />
                  </div>
                  <div
                    style={{ flex: 1, textAlign: isMobile ? 'center' : 'left' }}
                  >
                    <h6 style={{ marginTop: '5px', marginBottom: '12px' }}>
                      {`Morning Note ${formatDate(report.morningNote.date)}`}
                    </h6>
                    <p style={{ textAlign: isMobile ? 'center' : 'justify' }}>
                      {report.morningNote.seo_title}
                    </p>
                  </div>
                  <div
                    style={{
                      textAlign: isMobile ? 'center' : 'right',
                      marginTop: isMobile ? '10px' : '40px',
                    }}
                  >
                    <h6 style={{ color: '#34a853' }}>
                      <a
                        style={{ color: '#34a853' }}
                        onClick={() => handleNavigation(index)}
                      >
                        <GreenButton sx={{ position: 'relative', left: '10px' }}>
                          View
                        </GreenButton>

                      </a>
                    </h6>
                  </div>
                </Box>
              ))}
            </Grid>
          </Grid>
        </Box>
        <Grid item sx={{ top: '70px', position: 'relative' }} xs={12} md={6}>
          <FooterContent />
        </Grid>
      </div>
    </div>
  );
};

export default MorningReports;
