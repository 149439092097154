import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ONGOING_API, UPCOMING_API } from '.././Api';
import { BASE_URL, SERVER_URL } from './baseurl';

export const ActivefundsAPI = createApi({
  reducerPath: 'activefundsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: SERVER_URL,//'https://adityatrading.in/api/get-ipo/',
  }),
  tagTypes: ['Active'],
  endpoints: (builder) => ({
    onGoing: builder.query({
      query: () => ({
        method: 'GET',
        url: ONGOING_API,
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
        },
      }),
      providesTags: ['Active'],
    }),
    upComing: builder.query({
      query: () => ({
        method: 'GET',
        url: UPCOMING_API,
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
        },
      }),
      providesTags: ['Active'],
    }),
  }),
});

export const { useOnGoingQuery, useUpComingQuery } = ActivefundsAPI;

export default ActivefundsAPI;
