import { Button, Modal, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react';
import { useOtpmobileNumberNewMutation } from '../../redux/mobileUpdate';
import { useNavigate } from 'react-router';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: '300px', // Minimum width for smaller devices
  maxWidth: '90%', // Maximum width to ensure responsiveness
  padding: '20px', // Add padding for better spacing inside the modal
  bgcolor: '#fff', // White background color
  borderRadius: '8px', // Rounded corners for a smoother look
  boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.2)', // Subtle shadow effect
  outline: 'none', // Remove the default outline on focus
};
const NewMobileModel = ({ open, setOpenNew, sentOtp }) => {
  const [otp, setOtp] = useState();
  const navigate = useNavigate();
  console.log(sentOtp);
  const handleClose = () => {
    setOpenNew(false);
  };
  const [verifyNewNumber] = useOtpmobileNumberNewMutation();

  const verifyOtp = async () => {
    try {
      const response = await verifyNewNumber({
        otp, // Current OTP entered by the user
        sentOtp, // OTP sent to the user's mobile
        clientCode: 'BJ634', // Client code of the user
        mobileNumber: '9542965902', // New mobile number to be updated
        event: 'VERIFY_OTP_MOBILE_NEW',
      });
      console.log(response);
      if (response.data.status === 'success') {
        navigate(response.data.redirectUrl);
        handleClose();
      } else {
        alert(response.data.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          Enter Otp
          <hr />
          <Box sx={{ padding: '10px' }}>
            <input
              type="text"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              placeholder="Enter the OTP"
            />
          </Box>
          <Button variant="contained" color="primary" onClick={verifyOtp}>
            Verify the OTP
          </Button>
        </Box>
      </Modal>
    </div>
  );
};

export default NewMobileModel;
