import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { CALENDER_API } from '.././Api';
import { SERVER_URL } from './baseurl';

export const earningsAPI = createApi({
  reducerPath: 'earningsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: SERVER_URL,
  }),
  tagTypes: ['Calender'],
  endpoints: (builder) => ({
    earning: builder.query({
      query: () => ({
        method: 'GET',
        url: CALENDER_API,
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
        },
      }),
      providesTags: ['Calender'],
    }),
  }),
});

export const { useEarningQuery } = earningsAPI;

export default earningsAPI;
