import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { SERVER_URL } from './baseurl';
import { PORTFOLIO_API } from '../Api';
import Cookies from 'js-cookie';

const getCsrfToken = () => {
  const metaTag = document.querySelector('meta[name="csrf-token"]');
  return metaTag ? metaTag.getAttribute('content') : '';
};

export const portfolioApi = createApi({
  reducerPath: 'portfolioApi',
  baseQuery: fetchBaseQuery({
    baseUrl: SERVER_URL,
    prepareHeaders: (headers) => {
      
      // Get the CSRF token and include it in the headers
      // const csrfToken = getCsrfToken();
      // if (csrfToken) {
      //   headers.set('X-CSRFToken', csrfToken);
      // }
      headers.set('Content-Type', 'application/json');
      return headers;
    },
  }),
  tagTypes: ['Portfolio'],
  endpoints: (builder) => ({
    portfolio: builder.mutation({
      query: ({
        exchange,
        securityName,
        token,
        segment,
        type,
        date,
        price,
        quantity,
        action,
        // clientCode
      }) => ({
        method: 'POST',
        url: PORTFOLIO_API, // '/portfolioOperation/'
        headers:{
          'Content-Type': 'application/json',
        },
        body: {
          // clientCode,
          securityName,
          segment,
          token,
          exchange,
          type,
          date,
          price,
          quantity,
          action,
        },
      }),
    }),
    getportfolio: builder.query({
      query: () => ({
        method: 'GET',
        url: PORTFOLIO_API,
        // headers: {
        //   'Content-Type': 'application/json; charset=utf-8',
        // },
      }),
    }),
  }),
});

export const { usePortfolioMutation, useGetportfolioQuery } = portfolioApi;

export default portfolioApi;