import React, { useEffect, useState } from 'react';
import './Component.css';
import classNames from 'classnames';
import { ArrowDownward } from '@mui/icons-material';
import { useNavigate } from 'react-router';
import { useGetAnalystQuery } from '../redux/Analyst';

const Commodity = () => {
  const navigate = useNavigate();
  const handleNavigation = (path) => {
    console.log('navigation path --> ', path);
    navigate(path);
  };

  const [socket, setSocket] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null); // Track the selected index
  const [wsData, setWsData] = useState([]);
  const handleDetails = (index) => {
    setSelectedIndex((prevIndex) => (prevIndex === index ? null : index)); // Toggle the selected index
  };

  const { data: analystdata } = useGetAnalystQuery();
  const intraday = (analystdata && analystdata['commodityIntraday']) || []; // Handle case where intraday might be undefined

  useEffect(() => {
    const ws = new WebSocket('wss://neuronsoft.in/ws/watchlist/companyInfo/');

    ws.onopen = () => {
      console.log('WebSocket connection opened.');
      // Send subscription messages for all tokenIDs
      if (intraday.length > 0) {
        intraday.forEach((stock) => {
          const message = JSON.stringify({
            event: 'addTicker',
            token: stock.tokenID,
          });
          ws.send(message);
          console.log(`Sent tokenID ${stock.tokenID} to WebSocket.`);
        });
      }
    };

    ws.onmessage = (event) => {
      const data = JSON.parse(event.data);

      setWsData((prevData) => {
        const updatedData = prevData.filter(
          (item) => item.tokenID !== data.tokenID
        );
        updatedData.push(data);
        return updatedData;
      });
    };

    ws.onclose = () => console.log('WebSocket connection closed.');
    ws.onerror = (error) => console.log('WebSocket error:', error);

    setSocket(ws);

    return () => {
      ws.close();
    };
  }, [intraday]);

  const combinedStocks = intraday.map((stock) => {
    const updatedStock = wsData.find((data) => data.tokenID === stock.tokenID);
    return updatedStock ? { ...stock, ...updatedStock } : stock;
  });

  return (
    <div>
      <div
        className={classNames('politicalnewscontainer')}
      >
        {combinedStocks.map((intra, index) => (
          <div
            key={index}
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              margin: '-12px',
            }}
          >
            <div
              style={{
                padding: '0.7rem',
                marginTop: '0.5rem',
                boxShadow: '0 0.125rem 0.25rem rgba(0, 0, 0, 0.075)',
                borderRadius: '0.25rem',
                width: '100%', // Ensures the div takes the full width
                boxSizing: 'border-box',
              }}
            >
              <div className="content">
                <div className="content-img">
                  <div className="imge">
                    <img
                      src={intra.logoUrl}
                      alt="Nifty"
                      style={{ maxWidth: '100%', maxHeight: '100%' }}
                    />
                  </div>
                  <div>
                    <p className="nifty">{intra.ticker_code}</p>
                    <p className="opencls">{intra.status}</p>
                  </div>
                </div>

                <div
                  className="butn"
                  onClick={() => handleNavigation(`/companypage/${intra.ticker_code}/`)}
                >
                  <p
                    style={{
                      padding: '0 12px',
                      backgroundColor: '#d1ffdd',
                      color: '#34a853',
                      fontSize: '12px',
                      cursor: 'pointer',
                      borderRadius: '8px',
                      height: '30px',
                      width: 'auto',
                      display: 'flex',
                      alignItems: 'center',
                      marginTop: '5px',
                      marginBottom: '5px',
                    }}
                  >
                    {intra.orderside}
                  </p>
                </div>



                <p
                  onClick={() => handleDetails(index)}
                  className="icn"
                  style={{
                    padding: '0.2rem',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <ArrowDownward sx={{ height: '15px' }} />
                </p>
              </div>
              {selectedIndex === index && (
                <div style={{ display: 'flex' }}>
                  <table
                    style={{
                      width: '100%',
                      marginBottom: '14px',
                      color: '#212529',
                    }}
                  >
                    <thead>
                      <tr>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody className="tdsint">
                      <tr>
                        <td>
                          <p style={{ marginBottom: '0px' }}>
                            Date:{' '}
                            <span>
                              {' '}
                              {new Date(intra.created).toLocaleDateString()}
                            </span>
                          </p>
                          <p style={{ marginBottom: '0px' }}>
                            TGT Date:{' '}
                            <span>
                              {new Date(intra.targetDate).toLocaleDateString()}
                            </span>
                          </p>
                        </td>
                        <td>
                          <p style={{ marginBottom: '0px' }}>Entry Price</p>
                          <p style={{ marginBottom: '0px' }}>
                            {intra.entryPrice}
                          </p>
                        </td>
                        <td>
                          <p style={{ marginBottom: '0px' }}>Target</p>
                          <p style={{ marginBottom: '0px' }}>
                            {intra.targetPrice}
                          </p>
                        </td>
                        <td>
                          <p style={{ marginBottom: '0px' }}>Stop Loss</p>
                          <p style={{ marginBottom: '0px' }}>
                            {intra.stopLossPrice}
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div> // Show details only for the selected index
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Commodity;
