import React, { useEffect } from 'react';
import FooterContent from '../../Footer/FooterContent';
import { useLocation, useNavigate } from 'react-router-dom';

import { dark } from '@mui/material/styles/createPalette';
import GreenButton from '../../Buttons/GreenButton';
import {
  Box,
  Button,
  CardContent,
  Grid,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import DangerButton from '../../Buttons/Danger';
import SuccessButton from '../../Buttons/Success';
import CareerNavbar from './CareerNavbar';
import BackImg from '../../Assets/D2ADB4F0-AED9-43A9-82B9-6B4DD3ABF51C.png';
import ArrowPng from '../../Assets/Polygon 1.png';
import Elpise from '../../Assets/Ellipse 67.png';
import Arw from '../../Assets/arrow.jpg';
import Bbl from '../../Assets/bubble-chart-fill.jpg';
import mny from '../../Assets/money-dollar-circle-fill.jpg';
import prsn from '../../Assets/user-fill.jpg';
import erth from '../../Assets/earth-fill.jpg';
import usr2 from '../../Assets/user-5-fill.jpg';
import tmr1 from '../../Assets/timer-fill.jpg';
const Careers = () => {
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const handlenavigation = () => {
    navigate('/careers/JobDetails');
  };
  return (
    <div>
      <CareerNavbar />
      {/* <div
        style={{
          position: 'relative',
          padding: '0px',
          margin: '0px',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            width: '80%',
            top: '100px',
            position: 'relative',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        >
          <div style={{ display: 'flex' }}>
            <GreenButton sx={{ position: 'relative', left: '-20px' }}>
              Careers
            </GreenButton>
          </div>
          <p style={{ fontWeight: 'bold', color: 'black' }}>
            Find your Next Job with ATS
          </p>
          <p>
            <small>
              Make your career grow with us and have a beatiful work Experience
            </small>
          </p>
          <hr />

          <Grid container direction="column" spacing={2}>
            <Grid item xs={12}>
              <Box
                sx={{
                  border: '1px solid #dee2e6!important',
                  display: 'flex',
                  padding: '15px',
                  marginBottom: '7px',
                  justifyContent: 'space-between',
                }}
              >
                <div>
                  <h5>Relationship Manager</h5>
                  <p>
                    Hello
                    <br />
                    Greetings of the day!
                    <br />
                    We have an urgent opening!!
                  </p>
                  <p>Designation - Re…</p>
                </div>
                <div>
                  <SuccessButton name="Apply Now" />
                </div>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box
                sx={{
                  border: '1px solid #dee2e6!important',
                  display: 'flex',
                  padding: '15px',
                  marginBottom: '7px',
                  justifyContent: 'space-between',
                }}
              >
                <div>
                  <h5>Equity Advisor</h5>
                  <p>
                    <strong>Equity Advisor</strong>
                    for<strong> Equity Dealer: </strong>
                  </p>
                </div>
                <div>
                  <SuccessButton name="Apply Now" />
                </div>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Grid item sx={{ top: '70px', position: 'relative' }} xs={12} md={6}>
          <FooterContent />
        </Grid>
      </div> */}
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'relative',
          top: '163px',
          flexDirection: 'column',
          lineHeight: isMobile ? '22' : '60',
        }}
      >
        <Typography
          sx={{
            fontWeight: '600',
            fontSize: '48px',
            textAlign: 'center',
            color: '#2a2525!important',
          }}
        >
          Join Our {' '}
          <span
            style={{
              lineHeight: '65px',
              color: '#61BC6D',
              fontWeight: '600',
              fontSize: '48px',
              textAlign: 'center',
            }}
          >
            {' '}
            Team
          </span>
          <Typography
            sx={{
              fontWeight: '400',
              fontSize: '19px',
              lineHeight: '32px',
              maxWidth: '606px',
            }}
          >
            By accessing and placing an order with UXTheme, you confirm that you
            are in agreement with and bound by the terms and conditions
          </Typography>
        </Typography>
        <div
          style={{
            position: 'relative',
            display: 'inline-block',
            maxHeight: '699px',
          }}
        >
          <img
            src={BackImg}
            alt="back"
            style={{ width: '100%', height: 'auto' }}
          />

          <img
            src={ArrowPng}
            alt="arrow"
            style={{
              position: 'absolute',
              top: isMobile ? '22%' : '33%',
              left: isMobile ? '41%' : '49%',
              zIndex: 2,

              height: 'auto',
            }}
          />

          <img
            src={Elpise}
            alt="ellipse"
            style={{
              position: 'absolute',
              top: isMobile ? '26%' : '34%',
              left: isMobile ? '43%' : '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: 1,

              height: 'auto',
            }}
          />
        </div>
      </div>
      <Grid container sx={{ justifyContent: 'center', top: '200px' }}>
        <Typography
          sx={{
            fontWeight: '700',
            fontSize: '48px',
            alignItems: 'center',
            color: '#161C2D!important',
          }}
        >
          Current job openings
        </Typography>
        <Grid item xs={12}>
          <Box
            sx={{
              width: { xs: '90%', sm: '50%', md: '30%' }, // Adjust width for responsiveness (more tile-like)
              boxShadow:
                '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
              height: 'auto', // Let the content define the height
              backgroundColor: 'white',
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'row', // Horizontal layout for a tile-like structure
              padding: '16px', // Reduce padding for a compact tile
              gap: '10px', // Reduce gap for compact design
              justifyContent: 'space-between',
              borderRadius: '10px', // Reduced border-radius for a sharper look
              margin: 'auto',
              overflow: 'hidden', // Hide overflow for better appearance
            }}
          >
            <Box
              sx={{
                width: '70%', // Adjusted width to give more space for the content
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                textAlign: 'left',
              }}
            >
              <Typography
                sx={{
                  color: '#161C2D!important',
                  fontWeight: '600',
                  fontSize: '20px', // Reduced font size
                  whiteSpace: 'nowrap', // Prevent text wrapping
                  overflow: 'hidden',
                  textOverflow: 'ellipsis', // Add ellipsis for long text
                }}
              >
                Account Manager
              </Typography>
              <Typography
                sx={{
                  color: '#161C2D!important',
                  fontWeight: '400',
                  fontSize: '14px', // Reduced font size
                }}
              >
                Full Time, Bengaluru
              </Typography>
            </Box>

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Button
                onClick={handlenavigation}
                sx={{
                  textTransform: 'none',
                  padding: '8px 16px', // Compact button size
                  fontSize: { xs: '12px', md: '14px' }, // Smaller button font
                  color: 'black',
                  borderRadius: '20px',
                }}
              >
                <img src={Arw} alt="arrow" style={{ width: '16px', height: '16px' }} />
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Grid sx={{ marginTop: '70px' }} container xs={12}>
        <Grid item xs={12} md={12} lg={12}>
          {' '}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <Typography
              sx={{
                fontWeight: '700',
                fontSize: '48px',
                alignItems: 'center',
                color: '#161C2D!important',
                textAlign: 'center',
              }}
            >
              Why you should join us?
            </Typography>
            <Typography
              sx={{
                fontWeight: '400',
                fontSize: '19px',
                lineHeight: '32px',

                textAlign: 'center',
              }}
            >
              By accessing and placing an order with UXTheme, you confirm that
              you are in agreement with and bound by the terms and conditions
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={4} sx={{ height: '200px', width: '100px' }}>
          <CardContent sx={{ display: 'flex' }}>
            <div
              style={{
                display: 'flex',
                position: 'relative',

                height: '55px',
                justifyContent: 'center',
                marginBottom: '10px',
                marginRight: '20px',
                alignItems: 'center',
              }}
            >
              <img
                src={Bbl}
                alt="oval"
                style={{
                  position: 'relative',

                  height: 'auto',
                }}
              />
            </div>
            <div
              style={{
                display: 'flex',
                position: 'relative',
                flexDirection: 'column',
                justifyContent: 'center',
                marginBottom: '10px',
                alignItems: 'center',
              }}
            >
              <Typography
                sx={{
                  fontSize: '24px',
                  fontWeight: '700',
                  width: { md: 'auto' },
                  height: '33px',
                  textAlign: 'center',

                  marginTop: '10px',
                  color: '#161C2D!important',
                }}
              >
                Personal growth
              </Typography>
              <Typography
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '250px',
                  height: '112px',
                  fontSize: '17px',
                  fontWeight: '400',
                  textAlign: 'center',
                  color: '#161C2D!important',
                }}
                variant="body2"
              >
                Leverage agile frameworks to provide a robust synopsis for high
                level overviews.
              </Typography>
            </div>
          </CardContent>
        </Grid>
        <Grid item xs={12} md={4} sx={{ height: '200px', width: '100px' }}>
          <CardContent sx={{ display: 'flex' }}>
            <div
              style={{
                display: 'flex',
                position: 'relative',

                height: '55px',
                justifyContent: 'center',
                marginBottom: '10px',
                marginRight: '20px',
                alignItems: 'center',
              }}
            >
              <img
                src={mny}
                alt="oval"
                style={{
                  position: 'relative',

                  height: 'auto',
                }}
              />
            </div>
            <div
              style={{
                display: 'flex',
                position: 'relative',
                flexDirection: 'column',
                justifyContent: 'center',
                marginBottom: '10px',
                alignItems: 'center',
              }}
            >
              <Typography
                sx={{
                  fontSize: '24px',
                  fontWeight: '700',
                  width: { md: 'auto' },
                  height: '33px',
                  textAlign: 'center',

                  marginTop: '10px',
                  color: '#161C2D!important',
                }}
              >
                Competitive salary
              </Typography>
              <Typography
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '250px',
                  height: '112px',
                  fontSize: '17px',
                  fontWeight: '400',
                  textAlign: 'center',
                  color: '#161C2D!important',
                }}
                variant="body2"
              >
                Leverage agile frameworks to provide a robust synopsis for high
                level overviews.
              </Typography>
            </div>
          </CardContent>
        </Grid>
        <Grid item xs={12} md={4} sx={{ height: '200px', width: '100px' }}>
          <CardContent sx={{ display: 'flex' }}>
            <div
              style={{
                display: 'flex',
                position: 'relative',

                height: '55px',
                justifyContent: 'center',
                marginBottom: '10px',
                marginRight: '20px',
                alignItems: 'center',
              }}
            >
              <img
                src={prsn}
                alt="oval"
                style={{
                  position: 'relative',

                  height: 'auto',
                }}
              />
            </div>
            <div
              style={{
                display: 'flex',
                position: 'relative',
                flexDirection: 'column',
                justifyContent: 'center',
                marginBottom: '10px',
                alignItems: 'center',
              }}
            >
              <Typography
                sx={{
                  fontSize: '24px',
                  fontWeight: '700',
                  width: { md: 'auto' },
                  height: '33px',
                  textAlign: 'center',

                  marginTop: '10px',
                  color: '#161C2D!important',
                }}
              >
                Casual dress code
              </Typography>
              <Typography
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '250px',
                  height: '112px',
                  fontSize: '17px',
                  fontWeight: '400',
                  textAlign: 'center',
                  color: '#161C2D!important',
                }}
                variant="body2"
              >
                Leverage agile frameworks to provide a robust synopsis for high
                level overviews.
              </Typography>
            </div>
          </CardContent>
        </Grid>
        <Grid item xs={12} md={4} sx={{ height: '200px', width: '100px' }}>
          <CardContent sx={{ display: 'flex' }}>
            <div
              style={{
                display: 'flex',
                position: 'relative',

                height: '55px',
                justifyContent: 'center',
                marginBottom: '10px',
                marginRight: '20px',
                alignItems: 'center',
              }}
            >
              <img
                src={tmr1}
                alt="oval"
                style={{
                  position: 'relative',

                  height: 'auto',
                }}
              />
            </div>
            <div
              style={{
                display: 'flex',
                position: 'relative',
                flexDirection: 'column',
                justifyContent: 'center',
                marginBottom: '10px',
                alignItems: 'center',
              }}
            >
              <Typography
                sx={{
                  fontSize: '24px',
                  fontWeight: '700',
                  width: { md: 'auto' },
                  height: '33px',
                  textAlign: 'center',

                  marginTop: '10px',
                  color: '#161C2D!important',
                }}
              >
                Unlimited time off
              </Typography>
              <Typography
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '250px',
                  height: '112px',
                  fontSize: '17px',
                  fontWeight: '400',
                  textAlign: 'center',
                  color: '#161C2D!important',
                }}
                variant="body2"
              >
                Leverage agile frameworks to provide a robust synopsis for high
                level overviews.
              </Typography>
            </div>
          </CardContent>
        </Grid>
        <Grid item xs={12} md={4} sx={{ height: '200px', width: '100px' }}>
          <CardContent sx={{ display: 'flex' }}>
            <div
              style={{
                display: 'flex',
                position: 'relative',

                height: '55px',
                justifyContent: 'center',
                marginBottom: '10px',
                marginRight: '20px',
                alignItems: 'center',
              }}
            >
              <img
                src={usr2}
                alt="oval"
                style={{
                  position: 'relative',

                  height: 'auto',
                }}
              />
            </div>
            <div
              style={{
                display: 'flex',
                position: 'relative',
                flexDirection: 'column',
                justifyContent: 'center',
                marginBottom: '10px',
                alignItems: 'center',
              }}
            >
              <Typography
                sx={{
                  fontSize: '24px',
                  fontWeight: '700',
                  width: { md: 'auto' },
                  height: '33px',
                  textAlign: 'center',

                  marginTop: '10px',
                  color: '#161C2D!important',
                }}
              >
                Yearly bonuses
              </Typography>
              <Typography
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '250px',
                  height: '112px',
                  fontSize: '17px',
                  fontWeight: '400',
                  textAlign: 'center',
                  color: '#161C2D!important',
                }}
                variant="body2"
              >
                Leverage agile frameworks to provide a robust synopsis for high
                level overviews.
              </Typography>
            </div>
          </CardContent>
        </Grid>
        <Grid item xs={12} md={4} sx={{ height: '200px', width: '100px' }}>
          <CardContent sx={{ display: 'flex' }}>
            <div
              style={{
                display: 'flex',
                position: 'relative',

                height: '55px',
                justifyContent: 'center',
                marginBottom: '10px',
                marginRight: '20px',
                alignItems: 'center',
              }}
            >
              <img
                src={erth}
                alt="oval"
                style={{
                  position: 'relative',

                  height: 'auto',
                }}
              />
            </div>
            <div
              style={{
                display: 'flex',
                position: 'relative',
                flexDirection: 'column',
                justifyContent: 'center',
                marginBottom: '10px',
                alignItems: 'center',
              }}
            >
              <Typography
                sx={{
                  fontSize: '24px',
                  fontWeight: '700',
                  width: { md: 'auto' },
                  height: '33px',
                  textAlign: 'center',

                  marginTop: '10px',
                  color: '#161C2D!important',
                }}
              >
                Global community
              </Typography>
              <Typography
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '250px',
                  height: '112px',
                  fontSize: '17px',
                  fontWeight: '400',
                  textAlign: 'center',
                  color: '#161C2D!important',
                }}
                variant="body2"
              >
                Leverage agile frameworks to provide a robust synopsis for high
                level overviews.
              </Typography>
            </div>
          </CardContent>
        </Grid>
      </Grid>
    </div>
  );
};

export default Careers;
