import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { PLEDGE_API } from '../Api';
import { BASE_URL } from './baseurl';

export const pledgeApi = createApi({ 
  reducerPath: 'pledgeApi',
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      // Retrieve the 'verifieddata' from localStorage
      const verifiedData = localStorage.getItem('verifiedData');
      console.log(verifiedData);
      if (verifiedData) {
        try {
          // Parse the stored verified data (assuming it's a JSON string)
          const parsedData = JSON.parse(verifiedData);
          const encryptedClientId = parsedData?.encrypted_client_id;
          console.log(encryptedClientId);
          // Set the encrypted client ID in headers if it exists
          if (encryptedClientId) {
            headers.set('encrypted-client-id', encryptedClientId);
          }
        } catch (error) {
          console.error('Error parsing verified data from localStorage', error);
        }
      }

      // Add other headers if necessary
      headers.set('Content-Type', 'application/json; charset=utf-8');

      return headers;
    },
    credentials: 'include', // Ensure cookies are sent with the request
  }),
  tagTypes: ['Pledge'],
  endpoints: (builder) => ({
    getholdings: builder.query({
      query: () => ({
        method: 'GET',
        url: PLEDGE_API,
      }),
      providesTags: ['Pledge'],
    }),
  }),
});

export const { useGetholdingsQuery } = pledgeApi;

export default pledgeApi;
