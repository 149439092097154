import React from 'react';
import classNames from 'classnames';
import './Politicalnews.css';
import { Link } from '@mui/material';
import '../App.css';
import { useMarketNewsQuery } from '../redux/FinnancialNews';

const CompanyNews = () => {
  const { data: politicalNews } = useMarketNewsQuery();
  const political = politicalNews?.news || [];
  return (
    <div className={classNames('politicalnewscontainer', 'scrollable-container')}>
      {political.length > 0 ? (
        political.map((poltic, index) => (
          <Link
            key={index}
            href={poltic.link || '#'} 
            target="_blank" 
            rel="noopener noreferrer" 
            style={{
              textDecoration: 'none',
              color: 'inherit',
              display: 'block',
              cursor: 'pointer',
              marginBottom: '1rem',
            }} 
          >
            <div className="shadowbox" style={{ padding: '1rem', borderRadius: '0.5rem', backgroundColor: '#fff' }}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <p className="companyName" style={{ fontWeight: 'bold', marginBottom: '0.5rem' }}>
                  {poltic.datetime || 'Date not available'}
                </p>
                <p className="newsHeading" style={{ margin: '0' }}>
                  {poltic.desc || 'Description not available'}
                </p>
              </div>
            </div>
          </Link>
        ))
      ) : (
        <p>No political news available.</p>
      )}
    </div>
  );
  
};

export default CompanyNews;
