import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { KYC_API } from "./baseurl";
import { SEGMENT_ADDITION } from "../Api";
export const SegmentApi = createApi({
  reducerPath: 'SegmentApi',
  baseQuery: fetchBaseQuery({ baseUrl: KYC_API }),
  endpoints: (builder) => ({
    postSegment: builder.mutation({
      query: ({
        // payload,
        client_code,
        client_name,
        prepaid_brokerage,
        prepaid_brokerage_amt,
        nse_segment,
        bse_segment,
        cash_segment,
        fno_segment,
        currency_segment,
        mobile_no
      }) => ({
        url: SEGMENT_ADDITION,
        method: 'POST',
        body: {
          // payload,
          client_code,
          client_name,
          prepaid_brokerage,
          prepaid_brokerage_amt,
          nse_segment,
          bse_segment,
          cash_segment,
          fno_segment,
          currency_segment,
          mobile_no
        }
      })
    }),
  }),
});

export const { usePostSegmentMutation } = SegmentApi;
