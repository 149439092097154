import React, { useState } from 'react';
import Intraday from './Intraday';
import Shortterm from './Shortterm';
import Longterm from './Longterm';
import { Button } from '@mui/material';
import { useGetAnalystQuery } from '../redux/Analyst';

const Equity = () => {
  const [selected, setSelected] = useState('Intraday'); // Default selected component
  const { data: analystdata } = useGetAnalystQuery();
  // console.log(analystdata);

  const getSubButtonStyles = (component) => ({
    color: selected === component ? '#ffffff!important' : '#34a853!important', // White when selected, green when unselected
    backgroundColor: selected === component ? '#34a853!important' : 'transparent!important', // Green when selected, transparent when unselected
    borderRadius: '50px', // Rounded button
    border: selected === component ? '1px solid #34a853!important' : '1px solid #e5f2ff!important', // Green border when selected, lighter border when unselected
    padding: '4px 10px', // Smaller padding for sub-button
    fontSize: '10px', // Smaller font for sub-button
    fontWeight: 600,
    marginBottom: '8px', // Slightly smaller margin
    marginLeft: '10px', // Adjusted left margin
    textTransform: 'none',
    cursor: 'pointer',
    transition: 'transform 0.2s ease, background-color 0.3s ease, color 0.3s ease, font-size 0.3s ease', // Smooth transitions
    transform: selected === component ? 'scale(1.05)' : 'scale(1)', // Slight scale effect for selected button
    '&:hover': {
      backgroundColor: selected === component ? '#34a853!important' : '#d1ffdd!important', // Light green background on hover for unselected
      transform: 'scale(1.07)', // Slightly bigger on hover
    },
    '&:active': {
      transform: 'scale(0.95)', // Shrinks slightly on click
    },
  });


  const rendercomponent = () => {
    switch (selected) {
      case 'Intraday':
        return <Intraday />;
      case 'Shortterm':
        return <Shortterm />;
      case 'Longterm':
        return <Longterm />;
      default:
        return <Intraday />;
    }
  };

  return (
    <div>
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          alignItems: 'center',
          marginTop: '20px',
        }}
      >
        <Button
          onClick={() => setSelected('Intraday')} // Corrected button value
          sx={getSubButtonStyles('Intraday')} // Corrected button value
        >
          Intraday
        </Button>
        <Button
          onClick={() => setSelected('Shortterm')}
          sx={getSubButtonStyles('Shortterm')}
        >
          Short Term
        </Button>
        <Button
          onClick={() => setSelected('Longterm')}
          sx={getSubButtonStyles('Longterm')}
        >
          Long Term
        </Button>
      </div>
      {rendercomponent()}
    </div>
  );
};

export default Equity;
