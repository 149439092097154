import React from 'react';
import classNames from 'classnames';
import './Politicalnews.css';
import { Link } from '@mui/material';
import '../App.css';
import { useMarketNewsQuery } from '../redux/FinnancialNews';

const MarketNews = () => {
  const { data: politicalNews } = useMarketNewsQuery();
  const political = politicalNews?.news || [];
  return (
    <div
      className={classNames('politicalnewscontainer', 'scrollable-container')}
    >
      {political.length > 0 ? (
        political.map((poltic, index) => (
          <Link
            key={index}
            href={poltic.link || '#'} // Use a fallback link if not available
            target="_blank" // Open link in a new tab
            rel="noopener noreferrer" // Security best practice
            style={{
              textDecoration: 'none',
              color: 'inherit',
              display: 'block',
              cursor: 'pointer',
            }} // Remove default link styling and block-level display
          >
            <div className="shadowbox">
              <div>
                <p className="companyName">
                  {poltic.datetime || 'Date not available'}
                </p>
                <p className="newsHeading">
                  {poltic.desc || 'Description not available'}
                </p>
              </div>
              {poltic.img ? (
                <div>
                  <img
                    className="rounded"
                    style={{
                      maxHeight: '150px',
                      verticalAlign: 'middle',
                      borderStyle: 'none',
                    }}
                    src={poltic.img}
                    alt={poltic.desc || 'Image Not Found'}
                  />
                </div>
              ) : (
                <div style={{ textAlign: 'center', marginTop: '10px' }}>
                  <p></p>
                </div>
              )}
            </div>
          </Link>
        ))
      ) : (
        <p>No political news available.</p>
      )}
    </div>
  );
};

export default MarketNews;
