import React from "react";
import TermsAndConditions from "./tnc/combinedButton";
import LandingPage from "../LandingPage";

function FooterMainPage() {
    return (
        <div className="footer-main-page" style={{ position: 'relative', zIndex: 1 }}>
            <LandingPage />
            <div style={{ position: 'relative', zIndex: 2, padding: '30px' }}>
                <b>Terms And Conditions</b>
                <TermsAndConditions />
            </div>
        </div>
    );
}

export default FooterMainPage;