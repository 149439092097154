import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { KYC_API, SERVER_URL } from './baseurl';
import { ADD_NOMINEE } from '../Api';

export const AddNomineeApi = createApi({
    reducerPath: 'AddNomineeApi',
    baseQuery: fetchBaseQuery({ baseUrl: KYC_API }), //"https://kyc.adityatrading.in/api/getNominee?phoneNumber={}"
    endpoints: (builder) => ({
        getNominee: builder.query({
            query: ({phoneNumber}) => ({
                url: `getNominee?phoneNumber=${phoneNumber}`,
                method: 'GET',
            }),
        }),
    }),
});

export const { useGetNomineeQuery } = AddNomineeApi;
