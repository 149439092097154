import React, { useEffect, useState } from 'react';
import './Company.css';
import { useSelector } from 'react-redux';
import { useTechnicalNewsMutation } from '../redux/technicaltable';
const Techtable = () => {
  const [daily, setDaily] = useState({});
  const companyName = useSelector((state) => state.token.companyName);
  const [techdata, { isLoading }] = useTechnicalNewsMutation();
  const updateData = async () => {
    const response = await techdata({
      exchange: 'NSE',
      segment: 'CM',
      tickerCode: companyName,
    });
    setDaily(response.data.data || {});
  };
  useEffect(() => {
    updateData();
  }, [companyName, techdata]);
  const TechData = Object.keys(daily)
    .filter((key) => key.includes('dailytechnical'))
    .map((key) => daily[key]);
  console.log(TechData);

  return (
    <div>
      {TechData.map((tec, index) => (
        <table key={index} className="smalltable">
          <tbody>
            <tr>
              <td>Open</td>
              <td style={{ textAlign: 'right' }}>{tec.Open}</td>
            </tr>
            <tr>
              <td>High</td>
              <td style={{ textAlign: 'right' }}>{tec.High}</td>
            </tr>
            <tr>
              <td>Low</td>
              <td style={{ textAlign: 'right' }}>{tec.Low}</td>
            </tr>
            <tr>
              <td>Volume</td>
              <td style={{ textAlign: 'right' }}>{tec.Volume}</td>
            </tr>
          </tbody>
        </table>
      ))}
    </div>
  );
};

export default Techtable;
