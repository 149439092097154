import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { KYC_API } from "./baseurl";
import { UPDATE_CLIENT_INCOME } from "../Api";

export const clientIncomeApi = createApi({
  reducerPath: 'clientIncomeApi',
  baseQuery: fetchBaseQuery({ baseUrl: KYC_API }),
  endpoints: (builder) => ({
    postClientIncome: builder.mutation({
      query: ({ rangeVal, clientCode, emailid }) => ({
        url: UPDATE_CLIENT_INCOME,
        method: 'POST',
        body: {
          event: 'UPDATE_INCOME_RANGE',
          rangeVal,
          clientCode,
          emailid,
        },
      }),
    }),
  }),
});
export const { usePostClientIncomeMutation } = clientIncomeApi;
